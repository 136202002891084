import { CloseOutlined } from '@ant-design/icons';
import { Alert, App, Button, Card, Form, Input, Radio, Space, Tag } from "antd";
import _, { cloneDeep } from 'lodash';
import { useEffect, useState } from "react";
import { Col, Row } from 'react-bootstrap';
import { useGetDatasetSelectListQuery } from "../../../redux/rtkquery/DatasetApi";
import { useSaveRecommendationMutation } from '../../../redux/rtkquery/LibraryApi';
import { ActionType, AddToLibraryStatus } from '../../common/Enums';
import FullScreenModal from '../../common/components/FullScreenModal';
import HTMLContent from "../../common/components/HtmlContent";
import { AttachmentModel, LibraryQuestionModel, LibraryQuestionRecommendationModel, LibrarySearchQuestionModel, QuestionModel } from '../../models';
import { AddEditForm } from './AddEditForm';
import { SearchQuestionMatch } from "./SearchQuestionMatch";

type ComponentProps = {
    question: QuestionModel,
    answers: { answer: string[], files: AttachmentModel[] },
    onClose: (status?: AddToLibraryStatus | null) => void
}

const RecommendToLibrary: React.FC<ComponentProps> = (props) => {
    const [form] = Form.useForm<LibraryQuestionModel>();
    const { notification } = App.useApp();
    const [actionType, setActionType] = useState<ActionType | null>(null);
    const [currentStep, setCurrentStep] = useState<number>(1);
    const [comment, setComment] = useState<string>('');
    const [addedQuestion, setAddedQuestion] = useState<LibraryQuestionModel | null>(null);
    const [selectedQuestion, setSelectedQuestion] = useState<LibrarySearchQuestionModel | null>(null);
    const [triggerSaveRecommendation, saveRecommendationResult] = useSaveRecommendationMutation();
    const allDatasetListState = useGetDatasetSelectListQuery();

    const onSubmit = (question: LibraryQuestionModel) => {
        let _question: LibraryQuestionRecommendationModel = cloneDeep(question);
        _question.comment = comment;
        _question.referenceQuestionId = props.question.questionId;
        _question.referenceLibraryId = actionType === ActionType.Update ? selectedQuestion?.library_question_id ?? null : null;

        triggerSaveRecommendation(_question);
    }

    const onSelectQuestion = (question: LibrarySearchQuestionModel) => {
        setSelectedQuestion(question);

        if (addedQuestion) {
            addedQuestion.datasetId = question.dataset_id;
            addedQuestion.question = question.question;

            setAddedQuestion(addedQuestion);
        }
    }

    useEffect(() => {
        if (saveRecommendationResult.requestId && !saveRecommendationResult.isLoading) {

            if (saveRecommendationResult.data?.success) {
                props.onClose(AddToLibraryStatus.Pending);

                notification.success({
                    message: "Successfull",
                    description: "Add to Library request saved.",
                    placement: "topRight"
                });

                return;
            }
        }

    }, [saveRecommendationResult]);

    const onStepChange = (step: number) => {

        if (step === 1) {
            setAddedQuestion(null);
            setSelectedQuestion(null);
            setComment('');
        }
        else if (step === 2) {
            setAddedQuestion({
                libraryQuestionId: 0,
                question: props.question.question,
                answer: props.answers.answer.join(''),
                datasetId: props.question.datasetId,
                alternativeQuestion: [],
                libraryDocument: props.answers.files.map((x, i) => ({
                    attachmentId: (9999 - i) * -1,
                    libraryQuestionId: 0,
                    fileName: x.fileName,
                    fileDisplayName: x.fileDisplayName,
                    includeForAttachment: true,
                    includeForTraining: false,
                    action: ActionType.Insert
                }))
            });
        }

        setCurrentStep(step);
    }

    return (
        <FullScreenModal
            title={<div>
                <h2>{actionType === ActionType.Update ? "Update" : "Add"} Response To Library</h2>
                <span className='text-muted fw-normal'>
                    Recommend this response to be
                    {actionType === ActionType.Update ? " updated " : (actionType === ActionType.Insert ? " added " : " added or updated ")}
                    in the library.
                </span>
            </div>}
            open={true}
            maskClosable={false}
            keyboard={false}
            closable={false}
            width={800}
            destroyOnClose={true}
            onCancel={(e) => props.onClose()}
            footer={null}
        >
            {
                currentStep === 1 &&
                <div id="step_1">

                    {
                        !_.isNil(props.question.libraryStatusId) &&
                        <Alert
                            type="info"
                            className='p-3'
                            message="Existing request"
                            description="You've already requested to add this response to the library. A new request will overwrite the existing one and will be marked for review."
                        />
                    }

                    <Card onClick={() => setActionType(ActionType.Update)}
                        className={'border my-7 cursor-pointer ' + (actionType === ActionType.Update ? 'border-primary bg-gray-100' : '')}>
                        <div className='d-flex align-items-center'>
                            <div className='pr-2 flex-grow-1'>
                                <h4>Update Existing Library Question</h4>
                                <div className='text-muted'>Recommend an update to an existing library question.</div>
                            </div>
                            <div className='text-center'>
                                <Radio checked={actionType === ActionType.Update} />
                            </div>
                        </div>
                    </Card>

                    <Card onClick={() => setActionType(ActionType.Insert)}
                        className={'border mb-10 cursor-pointer ' + (actionType === ActionType.Insert ? 'border-primary bg-gray-100' : '')}>
                        <div className='d-flex align-items-center'>
                            <div className='pr-2 flex-grow-1'>
                                <h4>Add New Question</h4>
                                <div className='text-muted'>Recommend that a new question is added to the library.</div>
                            </div>
                            <div className='text-center'>
                                <Radio checked={actionType === ActionType.Insert} />
                            </div>
                        </div>
                    </Card>

                    <div className='text-end ant-modal-footer'>
                        <Space>
                            <Button onClick={() => props.onClose()}>
                                Cancel
                            </Button>
                            <Button type='primary' onClick={() => onStepChange(2)}
                                disabled={!actionType}>
                                Continue
                            </Button>
                        </Space>
                    </div>
                </div>
            }

            {
                actionType !== null && currentStep === 2 &&
                <div id="step_2">
                    {
                        actionType === ActionType.Update &&
                        <div className="mt-3">
                            {
                                selectedQuestion === null ?
                                    <SearchQuestionMatch totalRecords={15} onSelect={onSelectQuestion} searchLabel='Search Library Question to Update' /> :
                                    <Card className='my-7 border-start border-5 border-warning rounded-0'
                                        styles={{ header: { padding: '0' } }}
                                        size='small'
                                        bordered={true}
                                        title={<div className='py-3 px-4 d-linebreak d-flex'>
                                            <div className='flex-grow-1'>{selectedQuestion.question}</div>
                                            <CloseOutlined className='cursor-pointer' onClick={() => setSelectedQuestion(null)} />
                                        </div>
                                        }
                                    >
                                        {
                                            !_.isNil(selectedQuestion.dataset_id) &&
                                            <Tag color="cyan">
                                                {allDatasetListState.data?.find(x => x.datasetId === selectedQuestion.dataset_id)?.datasetName}
                                            </Tag>
                                        }
                                        <HTMLContent className='mt-3' content={selectedQuestion.answer} lines={5} />
                                    </Card>
                            }
                        </div>
                    }
                    {
                        addedQuestion !== null &&
                        (actionType === ActionType.Insert || (actionType === ActionType.Update && selectedQuestion)) &&
                        <>
                            <AddEditForm question={addedQuestion} form={form} onSubmit={onSubmit} hideAdditionalQuestion={true} />
                            <Row className='mt-3 mt-5'>
                                <Col sm={12}>
                                    <label className='pb-2'>Why should this response be {actionType === ActionType.Insert ? "added" : "updated"}?</label><br />
                                    <Input.TextArea autoSize
                                        value={comment}
                                        placeholder="Comments (optional)"
                                        onChange={(e) => setComment(e.target.value)}
                                        style={{ minHeight: '55px' }}
                                    />
                                </Col>
                            </Row>
                        </>
                    }

                    <div className='text-end ant-modal-footer'>
                        <Space>
                            <Button disabled={saveRecommendationResult.isLoading}
                                onClick={() => onStepChange(1)}>
                                Back
                            </Button>

                            <Button disabled={saveRecommendationResult.isLoading}
                                onClick={() => props.onClose()}>
                                Cancel
                            </Button>

                            <Button type='primary'
                                disabled={saveRecommendationResult.isLoading || (actionType === ActionType.Update && selectedQuestion === null)}
                                loading={saveRecommendationResult.isLoading}
                                onClick={() => form.submit()}>
                                Submit
                            </Button>
                        </Space>
                    </div>
                </div>
            }
        </FullScreenModal>
    )
}

export { RecommendToLibrary };

