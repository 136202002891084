import { Card, Steps } from 'antd';
import _ from "lodash";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { PageTitle } from "../../../_metronic/layout/core";
import { CompanySetupSteps, DataSetType } from "../../common/Enums";
import { useAuth } from "../auth";
import { CompanyInfo } from "./CompanyInfo";
import { CompanyProfile } from "./CompanyProfile";
import { CompleteSetup } from "./CompleteSetup";
import { DataSets } from "./DataSets";

const CompanySetup: React.FC = () => {
    const { company_config } = useAuth();
    const [current, setCurrent] = useState(0)
    const setupSteps = _.filter(CompanySetupSteps, v=> v.initialSetup)
    const stepItems = _.map(setupSteps, ({key, title})=> ({key, title}));

    const nextStep = () => {
        setCurrent(current + 1);
    }

    const prevStep = () => {
        setCurrent(current - 1);
    }

    return (
        <>
            <PageTitle breadcrumbs={[]}>Company Setup</PageTitle>
                <Row className="justify-content-md-center">
                    <Col md={12} lg={10} xl={9}>
                        <Steps current={current} items={stepItems} />
                        <Card className="mt-4 mb-4"
                            title={<>
                                <h3 className="fw-bolder text-dark">{setupSteps[current].title}</h3>
                                <div className="text-gray-700 fs-6 fw-normal text-wrap">
                                    {setupSteps[current].description}
                                </div>
                            </>}
                            styles={{header: { paddingTop: '15px', paddingBottom: '15px' }}}>
                            {
                                (current === 0 && <CompanyProfile nextStep={nextStep} prevStep={prevStep} source="InitialSetup" />) ||
                                (current === 1 && <CompanyInfo nextStep={nextStep} prevStep={prevStep} source="InitialSetup" isProjectEnabled={company_config?.isProjectEnabled} />) ||
                                (current === 2 && <DataSets nextStep={nextStep} prevStep={prevStep} source="InitialSetup" isProjectEnabled={company_config?.isProjectEnabled} dataSetType={DataSetType.Products} />) ||
                                (current === 3 && <CompleteSetup />)
                            }
                        </Card>
                    </Col>
                </Row>
        </>
    );
}

export { CompanySetup };
