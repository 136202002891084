import { Button, Card, DatePicker, Space, Tabs, TabsProps } from "antd";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { KTSVG } from "../../../_metronic/helpers";
import { selectFilters, setFilters } from "../../../redux/DashboardSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { HasAnyRole, IsPricingAdmin, IsPricingMember } from "../../common/CommonFunctions";
import { AntDRangePresets } from "../../common/Constants";
import { DashboardStatsType, Roles } from "../../common/Enums";
import { useAuth } from "../auth";
import { AutomationEfficiencyStats } from "./AutomationEfficiencyStats";
import { AutomationTrendStats } from "./AutomationTrendStats";
import { MyTaskStats } from "./MyTaskStats";
import { OpenQuotesStats } from "./OpenQuotesStats";
import { ProjectCompletedStats } from "./ProjectCompletedStats";
import { ProjectStatusOvertimeStats } from "./ProjectStatusOvertimeStats";
import { ProjectStatusStats } from "./ProjectStatusStats";
import { QuotesApproverStats } from "./QuotesApproverStats";
import { QuotesOwnerStats } from "./QuotesOwnerStats";
import { QuotesProcessedStats } from "./QuotesProcessedStats";
import { RecentProjects } from "./RecentProjects";
import { RecentQuotes } from "./RecentQuotes";
import { ProjectsProcessedStats } from "./ProjectsProcessedStats";

const DashboardTabs = {
    Projects: { name: "Projects", key: 'projects' },
    MyQuotes: { name: "My Quotes", key: 'my-quotes' },
    TeamQuotes: { name: "Team Quotes", key: 'team-quotes' }
}

const Dashboard: React.FC = () => {
    const { user_metadata } = useAuth();
    const hasProjectAccess = HasAnyRole(user_metadata?.roles ?? [], [Roles.ProjectAdmin, Roles.ProjectOwner, Roles.Collaborator]),
        isProjectAdmin = HasAnyRole(user_metadata?.roles ?? [], [Roles.ProjectAdmin, Roles.ProjectOwner]);
    const isPricingAdmin = IsPricingAdmin(user_metadata?.roles),
        isPricingMember = IsPricingMember(user_metadata?.roles);
    const hasPricingAccess = isPricingAdmin || isPricingMember;

    const [selectedTab, setSelectedTab] = useState<string | undefined>();

    const onTabChange = (activeKey: string) => {
        setSelectedTab(activeKey);
    }

    const visibleDashboardTabs = useMemo(() => {
        const tabs: TabsProps['items'] = [];

        if (hasProjectAccess) {
            tabs.push({
                key: DashboardTabs.Projects.key,
                label: <Space>
                    <KTSVG path="media/icons/duotune/general/gen005.svg" className="svg-icon-2x" />
                    {DashboardTabs.Projects.name}
                </Space>,
                children: <ProjectTab />,
            });
        }

        if (hasPricingAccess) {
            tabs.push({
                key: DashboardTabs.MyQuotes.key,
                label: <Space>
                    <KTSVG path="media/icons/duotune/finance/fin010.svg" className="svg-icon-2x" />
                    {DashboardTabs.MyQuotes.name}
                </Space>,
                children: <PricingTab key={DashboardTabs.MyQuotes.key} teamStats={false} />,
            });
        }

        if (isPricingAdmin) {
            tabs.push({
                key: DashboardTabs.TeamQuotes.key,
                label: <Space>
                    <KTSVG path="media/icons/duotune/graphs/gra005.svg" className="svg-icon-2hx" />
                    {DashboardTabs.TeamQuotes.name}
                </Space>,
                children: <PricingTab key={DashboardTabs.TeamQuotes.key} teamStats={true} />,
            });
        }

        return tabs;
    }, [])

    const tabBarActions = useMemo(() => {
        if (selectedTab === DashboardTabs.Projects.key) {
            return <Space>
                {
                    isProjectAdmin &&
                    <Link to='/projects/add-project'>
                        <Button size="large" className="btn-gradient btn-gradient-blue">Create Project</Button>
                    </Link>
                }
                <Link to={'/projects/list'}>
                    <Button size="large" type="primary" ghost>View Projects</Button>
                </Link>
            </Space>
        }
        else if (selectedTab === DashboardTabs.MyQuotes.key) {
            return <Space>
                <Link to='/quotes/new-quote'>
                    <Button size="large" className="btn-gradient btn-gradient-blue">Create Quote</Button>
                </Link>
                <Link to='/quotes/list'>
                    <Button size="large" type="primary" ghost>View Quotes</Button>
                </Link>
            </Space>
        }
        else if (selectedTab === DashboardTabs.TeamQuotes.key) {
            return <Space>
                <Link to='/quotes/new-quote'>
                    <Button size="large" className="btn-gradient btn-gradient-blue">Create Quote</Button>
                </Link>
                <Link to='/quotes/list'>
                    <Button size="large" type="primary" ghost>View Quotes</Button>
                </Link>
            </Space>
        }
    }, [selectedTab])

    useEffect(() => {
        visibleDashboardTabs.length && setSelectedTab(visibleDashboardTabs[0].key)
    }, [])

    return (
        <Card size="small">
            <Tabs size="large"
                activeKey={selectedTab}
                items={visibleDashboardTabs}
                onChange={onTabChange}
                tabBarExtraContent={tabBarActions}
            />
        </Card>
    )
}

export { Dashboard };

const ProjectTab: React.FC = () => {

    const [projectDateRange, setProjectDateRange] = useState<any>(null);
    const dispatch = useAppDispatch();

    const projectFilters = useAppSelector(state => selectFilters(state, DashboardStatsType.Projects));

    const onProjectDateRangeChange = (range: any) => {
        if (range) {
            let fromDate: dayjs.Dayjs = range[0];
            range[0] = fromDate.startOf('M');

            let toDate: dayjs.Dayjs = range[1];
            range[1] = toDate.endOf('M');

            dispatch(setFilters({
                type: DashboardStatsType.Projects,
                filters: {
                    range: [range[0].format("MM/DD/YYYY"), range[1].format("MM/DD/YYYY")]
                }
            }));
        }

        setProjectDateRange(range);
    }

    useEffect(() => {
        let _projectDateRange = AntDRangePresets?.[0].value;

        if (projectFilters?.range)
            _projectDateRange = [dayjs(projectFilters.range[0]), dayjs(projectFilters.range[1])];

        setProjectDateRange(_projectDateRange)
    }, [])

    return (
        <>
            <Row>
                <Col sm={12} md={6} className="mb-5">
                    <MyTaskStats />
                </Col>

                <Col sm={12} md={6} className="mb-5">
                    <RecentProjects />
                </Col>
            </Row>

            <div className="bg-gray-200 p-3 mb-5 text-end">
                <DatePicker.RangePicker
                    style={{ width: "250px" }}
                    presets={AntDRangePresets}
                    picker="month"
                    size="large"
                    value={projectDateRange}
                    onChange={onProjectDateRangeChange}
                />
            </div>

            <Row>
                <Col sm={12} md={6} className="mb-5">
                    <ProjectStatusStats />
                </Col>
                <Col sm={12} md={6} className="mb-5">
                    <AutomationEfficiencyStats dateRange={projectDateRange} />
                </Col>
                <Col sm={12} md={6} className="mb-5">
                    <ProjectStatusOvertimeStats dateRange={projectDateRange} />
                </Col>
                <Col sm={12} md={6} className="mb-5">
                    <AutomationTrendStats dateRange={projectDateRange} />
                </Col>
                <Col sm={12} md={6} className="mb-5">
                    <ProjectCompletedStats dateRange={projectDateRange} />
                </Col>
            </Row>
            <ProjectsProcessedStats dateRange={projectDateRange} />
        </>
    )
}

const PricingTab: React.FC<{ teamStats: boolean }> = (props) => {

    const [dateRange, setDateRange] = useState<any>(null);
    const dispatch = useAppDispatch();

    const myQuoteFilters = useAppSelector(state => selectFilters(state, DashboardStatsType.MyQuotes));
    const teamQuoteFilters = useAppSelector(state => selectFilters(state, DashboardStatsType.TeamQuotes));

    const onDateRangeChange = (range: any) => {
        if (range) {
            let fromDate: dayjs.Dayjs = range[0];
            range[0] = fromDate.startOf('M');

            let toDate: dayjs.Dayjs = range[1];
            range[1] = toDate.endOf('M');

            dispatch(setFilters({
                type: props.teamStats ? DashboardStatsType.TeamQuotes : DashboardStatsType.MyQuotes,
                filters: {
                    range: [range[0].format("MM/DD/YYYY"), range[1].format("MM/DD/YYYY")]
                }
            }));
        }

        setDateRange(range);
    }

    useEffect(() => {

        let _dateRange = AntDRangePresets?.[0].value;

        if (!props.teamStats && myQuoteFilters?.range)
            _dateRange = [dayjs(myQuoteFilters.range[0]), dayjs(myQuoteFilters.range[1])];
        else if (props.teamStats && teamQuoteFilters?.range)
            _dateRange = [dayjs(teamQuoteFilters.range[0]), dayjs(teamQuoteFilters.range[1])];

        setDateRange(_dateRange)
    }, [])

    return (
        <>
            <Row>
                <Col sm={12} md={props.teamStats ? 12 : 6} className="mb-5">
                    <OpenQuotesStats teamStats={props.teamStats} />
                </Col>
                {
                    !props.teamStats &&
                    <Col sm={12} md={6} className="mb-5">
                        <RecentQuotes />
                    </Col>
                }
            </Row>

            <div className="bg-gray-200 p-3 mb-5 text-end">
                <DatePicker.RangePicker
                    style={{ width: "250px" }}
                    presets={AntDRangePresets}
                    picker="month"
                    size="large"
                    value={dateRange}
                    onChange={onDateRangeChange}
                />
            </div>

            <QuotesOwnerStats teamStats={props.teamStats} dateRange={dateRange} />
            <QuotesApproverStats teamStats={props.teamStats} dateRange={dateRange} />
            <QuotesProcessedStats teamStats={props.teamStats} dateRange={dateRange} />
        </>
    )
}