import { Button, Result } from 'antd';
import React, { useEffect } from 'react';
import { KTSVG } from '../../../_metronic/helpers';
import { useCompleteSetupMutation } from '../../../redux/rtkquery/CompanyApi';

const CompleteSetup: React.FC<{ isProjectEnabled?: boolean }> = ({ isProjectEnabled }) => {
    const [triggerCompleteSetup, setupResult] = useCompleteSetupMutation();

    const onCompleteSetup = () => {
        triggerCompleteSetup({ start_index: isProjectEnabled ?? false });
    }

    useEffect(() => {
        if (setupResult.requestId && !setupResult.isLoading) {
            if (setupResult.data?.success) {
                window.location.href = '/dashboard';
                return;
            }
        }
    }, [setupResult])

    return <Result
        icon={<KTSVG path="/media/icons/duotune/general/gen043.svg" className="svg-icon-5hx svg-icon-primary" />}
        title="Great, you have completed setup..!!"
        extra={
            <Button type="primary" onClick={onCompleteSetup} loading={setupResult.isLoading}>
                {isProjectEnabled ? "Generate Indexes" : "Save Changes"}
            </Button>
        }
    />
};

export { CompleteSetup };

