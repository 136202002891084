import { APIResponseModel, CompanyProfileModel, CompanyRoleLimitModal, DataSetModel } from '../../app/models';
import { RTKBaseApi } from './BaseApi';

const CompanyApi = RTKBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getProfile: builder.query<CompanyProfileModel | null, void>({
      query: () => ({
        url: '/company/profile',
        method: "GET"
      }),
      transformResponse: (response: APIResponseModel<CompanyProfileModel>) => response.data,
      keepUnusedDataFor: 0,
    }),
    saveProfile: builder.mutation<APIResponseModel<CompanyProfileModel>, CompanyProfileModel>({
      query: (params) => ({
        url: '/company/profile',
        method: "POST",
        body: params
      })
    }),
    getDatasets: builder.query<DataSetModel[] | null, number[]>({
      query: (params) => ({
        url: `company/datasets?datasetTypeIds=${params.join(',')}`,
        method: "GET"
      }),
      transformResponse: (response: APIResponseModel<DataSetModel[]>) => response.data || [],
      keepUnusedDataFor: 0,
    }),
    saveDataset: builder.mutation<APIResponseModel<DataSetModel>, DataSetModel>({
      query: (params) => ({
        url: '/company/datasets',
        method: "POST",
        body: params
      })
    }),
    deleteDataset: builder.mutation<APIResponseModel<void>, number>({
      query: (params) => ({
        url: `/company/datasets/${params}`,
        method: "DELETE"
      })
    }),
    getSourceInfo: builder.query<APIResponseModel<DataSetModel> | null, { datasetTypeId: number, referenceId: string | number | null }>({
      query: (params) => ({
        url: `company/get_source_info?datasetTypeId=${params.datasetTypeId}&referenceId=${params.referenceId}`,
        method: "GET"
      })
    }),
    completeSetup: builder.mutation<APIResponseModel<void>, { start_index: boolean }>({
      query: (params) => ({
        url: `/company/completesetup/${params.start_index ? 1 : 0}`,
        method: "POST"
      })
    }),
    rebuildIndex: builder.mutation<APIResponseModel<void>, void>({
      query: () => ({
        url: '/company/rebuildindex',
        method: "POST"
      })
    }),
    userRoleLimit: builder.query<APIResponseModel<CompanyRoleLimitModal[]>, { companyId: number }>({
      query: (params) => ({
        url: `/company/user_role_limit/${params.companyId}`,
        method: "GET"
      })
    }),
    saveUserRoleLimit: builder.mutation<APIResponseModel<void>, { companyId: number, roles: CompanyRoleLimitModal[] }>({
      query: (params) => ({
        url: `/company/save_user_role_limit/${params.companyId}`,
        method: "POST",
        body: params.roles
      })
    }),
  })
})

export const {
  useLazyGetProfileQuery,
  useSaveProfileMutation,
  useLazyGetDatasetsQuery,
  useSaveDatasetMutation,
  useDeleteDatasetMutation,
  useGetSourceInfoQuery,
  useCompleteSetupMutation,
  useRebuildIndexMutation,
  useLazyUserRoleLimitQuery,
  useSaveUserRoleLimitMutation
} = CompanyApi