import { Card, Spin } from "antd";
import dayjs from 'dayjs';
import _, { cloneDeep } from "lodash";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Col, Row } from "react-bootstrap";
import { useApproverQuotesStatsMutation } from "../../../redux/rtkquery/DashboardAPI";
import { ChartColors, localeCurrency, localeNumber } from "../../common/CommonFunctions";
import { DashboardQuoteStats } from "../../models";

type SeriesType = {
  name: string,
  data: number[]
}

type Props = {
  teamStats: boolean,
  dateRange: dayjs.Dayjs[]
}

const QuotesApproverStats: React.FC<Props> = (props) => {
  const [options, setOptions] = useState<any>(null);
  const [priceChartOptions, setPriceChartOptions] = useState<any>(null);
  const [series, setSeries] = useState<any[]>([]);
  const [priceChartSeries, setPriceChartSeries] = useState<any[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [triggerQuoteStats, quoteStatsResult] = useApproverQuotesStatsMutation();

  const setMonthCategories = (dateRange: dayjs.Dayjs[]) => {
    let _categories = [], dateRanges = _.cloneDeep(dateRange);

    while (dateRanges[0].endOf('M') <= dateRanges[1].endOf('M')) {
      _categories.push(dateRanges[0].format('MMM YY'));
      dateRanges[0] = dateRanges[0].add(1, "M");
    };

    setCategories(_categories);
    return _categories;
  }

  const loadStats = (dateRange: dayjs.Dayjs[]) => {
    triggerQuoteStats({
      fromDate: dateRange?.[0].format("MM/DD/YYYY") || null,
      toDate: dateRange?.[1].format("MM/DD/YYYY") || null,
      teamStats: props.teamStats
    });
  }

  const initTeamChart = (chartSeries: SeriesType[], chartCategories: string[], isPriceChart: boolean) => {
    let _options = {
      colors: ChartColors,
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        }
      },
      plotOptions: {
        bar: {
          barHeight: chartCategories.length <= 3 ? (20 * chartCategories.length) + '%' : '90%',
          horizontal: true,
          dataLabels: {
            total: {
              enabled: true,
              offsetX: 0,
              style: {
                fontSize: '13px',
                fontWeight: 900
              }
            }
          }
        },
      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },
      dataLabels: {
        enabled: true,
        formatter: (val: number) => {
          return isPriceChart ? localeCurrency(val) : localeNumber(val);
        },
        style: {
          fontSize: '12px',
          colors: ["#000"]
        }
      },
      xaxis: {
        categories: chartCategories,
        labels: {
          show: true,
          formatter: (val: number) => {
            return isPriceChart ? localeCurrency(val) : localeNumber(val);
          }
        }
      },
      tooltip: {
        y: {
          formatter: (val: number) => {
            return isPriceChart ? localeCurrency(val) : localeNumber(val);
          }
        }
      },
      fill: {
        opacity: 1
      },
      legend: {
        horizontalAlign: 'center',
        showForSingleSeries: true,
        position: 'bottom'
      }
    }

    if (!isPriceChart) {
      setOptions(_options);
      setSeries(chartSeries)
    }
    else {
      setPriceChartSeries(chartSeries)
      setPriceChartOptions(_options)
    }

  }

  const initUserChart = (chartSeries: number[], chartCategories: string[], isPriceChart: boolean) => {

    let _options = {
      colors: ChartColors,
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        }
      },
      plotOptions: {
        bar: {
          columnWidth: categories.length <= 3 ? (15 * categories.length) + '%' : '90%',
          dataLabels: {
            position: 'top'
          },
        }
      },
      dataLabels: {
        enabled: true,
        formatter: (val: number) => {
          return isPriceChart ? localeCurrency(val) : localeNumber(val);
        },
        style: {
          fontSize: '12px',
          colors: ["#000"]
        }
      },
      xaxis: {
        categories: chartCategories,
        position: 'bottom',
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        tooltip: {
          enabled: true,
        }
      },
      yaxis: {
        min: !isPriceChart ? 0 : undefined,
        max: (_.max(chartSeries) || 0) <= 3 ? 5 : undefined,
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: true,
          formatter: (val: number) => {
            return isPriceChart ? localeCurrency(val) : localeNumber(parseInt(val.toFixed(0)));
          }
        }
      },
      fill: {
        opacity: 1
      },
      legend: {
        show: false
      }
    }

    if (!isPriceChart) {
      setOptions(_options);
      setSeries([
        {
          name: 'Quotes Approved',
          data: chartSeries
        }
      ]);
    }
    else {
      setPriceChartSeries([
        {
          name: 'Quotes Approved',
          data: chartSeries
        }
      ]);
      setPriceChartOptions(_options)
    }
  }

  const setUserChartData = (statsData: DashboardQuoteStats[], isPriceChart: boolean) => {
    const chartSeries: number[] = [],
      categoriesDict: Record<string, number> = {};

    _.forEach(statsData, x => {
      // Set Completion Month display label
      let completionOn = dayjs(`${x.year}-${x.month}-01`, 'YYYY-M-D').format("MMM YY");

      // Set data grouping based on submission month
      categoriesDict[completionOn] = isPriceChart ? x.totalPrice : x.requestCount;
    });

    categories.forEach(x => chartSeries.push(categoriesDict[x] ?? 0));

    initUserChart(chartSeries, categories, isPriceChart);
  }

  const setTeamChartData = (statsData: DashboardQuoteStats[], isPriceChart: boolean) => {
    const chartSeries: SeriesType[] = [],
      _statsData = _.orderBy(statsData, ["month", "year"]),
      allUserNames = new Set<string>(),
      allDates = new Set<string>();

    if (isPriceChart)
      _.remove(_statsData, x => x.totalPrice <= 0);

    // Set Month display label
    _.forEach(_statsData, x => {
      x.date = dayjs(`${x.year}-${x.month}-01`, 'YYYY-M-D').format("MMM YY");
      allUserNames.add(x.userName);
      allDates.add(x.date);
    });

    // Order all user names
    let _allUserNames = _.orderBy(Array.from(allUserNames));

    // Group data based on Date
    let dateStats = _.groupBy(_statsData, x => x.date);

    _.forEach(Array.from(allDates), (date) => {
      let data = dateStats[date];

      chartSeries.push({
        name: date,
        data: _allUserNames.map(u => {
          let stat = data.find(x => x.userName === u);
          return (isPriceChart ? stat?.totalPrice : stat?.requestCount) || 0
        })
      })
    });

    initTeamChart(chartSeries, _allUserNames, isPriceChart);
  }

  useEffect(() => {
    if (props.dateRange) {
      setMonthCategories(props.dateRange);
    }
  }, [props.dateRange])

  useEffect(() => {
    if (categories.length)
      loadStats(props.dateRange);
  }, [categories])

  useEffect(() => {
    if (quoteStatsResult.requestId && !quoteStatsResult.isLoading &&
      quoteStatsResult.data && quoteStatsResult.data.success) {
      if (props.teamStats) {
        setTeamChartData(cloneDeep(quoteStatsResult.data.data || []), false)
        setTeamChartData(cloneDeep(quoteStatsResult.data.data || []), true)
      }
      else {
        setUserChartData(cloneDeep(quoteStatsResult.data.data || []), false)
        setUserChartData(cloneDeep(quoteStatsResult.data.data || []), true)
      }
    }
  }, [quoteStatsResult]);

  return (
    <Row>
      <Col sm={12} md={6} className="mb-5">
        <Card className="h-100">
          <Spin spinning={quoteStatsResult.isLoading}>

            <div className='d-flex align-items-center justify-content-between mb-5'>
              <span className='fs-3 fw-bold text-dark me-2 lh-1'>Quotes Approved</span>
            </div>

            {
              options !== null &&
              <ReactApexChart options={options}
                series={series}
                type="bar" height={'300'} width={'100%'} />
            }
          </Spin>
        </Card>
      </Col>
      <Col sm={12} md={6} className="mb-5">
        <Card className="h-100">
          <Spin spinning={quoteStatsResult.isLoading}>

            <div className='d-flex align-items-center justify-content-between mb-5'>
              <span className='fs-3 fw-bold text-dark me-2 lh-1'>Quotes Approved ($)</span>
            </div>

            {
              options !== null &&
              <ReactApexChart options={priceChartOptions}
                series={priceChartSeries}
                type="bar" height={'300'} width={'100%'} />
            }
          </Spin>
        </Card>
      </Col>
    </Row>
  )
}

export { QuotesApproverStats };

