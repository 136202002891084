import { PricingRequestStatus, RTKQueryTags } from '../../app/common/Enums';
import {
    AdditionalMeta,
    APIResponseModel, FinalPricingResultModel, PricingInstructionListItemModel, PricingInstructionModel, PricingInstructionSelectItemModel, PricingModel,
    PricingRequestDetail, PricingRequestFilterModel, PricingRequestListingModel, PricingRequestModel, PricingResponseModel, PricingStreamHandler, PricingSurchargeModel, ProductPricingModel
} from '../../app/models';
import { RTKBaseApi } from './BaseApi';

type StatusChangesParams = {
    pricingRequestId: number,
    statusId: PricingRequestStatus,
    comment: string | null,
    finalizedResponse: string | null
}

const PricingApi = RTKBaseApi.injectEndpoints({
    endpoints: (builder) => ({
        getPricingInstructionSelectList: builder.query<PricingInstructionSelectItemModel[], void>({
            query: () => ({
                url: '/pricing/active_list_select',
            }),
            transformResponse: (response: APIResponseModel<PricingInstructionSelectItemModel[]>) => response.data || [],
            providesTags: (_) => [RTKQueryTags.PricingInstructionSelectList]
        }),
        getPricingInstructionList: builder.query<PricingInstructionListItemModel[], { companyId: number | string }>({
            query: (params) => ({
                url: `/pricing/list/${params.companyId}`,
            }),
            transformResponse: (response: APIResponseModel<PricingInstructionListItemModel[]>) => response.data || []
        }),
        getPricingInstruction: builder.query<PricingModel | null, { companyId: number | string, id: number | string }>({
            query: (params) => ({
                url: `/pricing/details/${params.id}/${params.companyId}`,
            }),
            transformResponse: (response: APIResponseModel<PricingModel>) => response.data
        }),
        savePricingInstruction: builder.mutation<APIResponseModel<PricingInstructionModel>, { companyId: number, data: PricingInstructionModel }>({
            query: (params) => ({
                url: `/pricing/${params.data.id > 0 ? 'update' : 'create'}/${params.companyId}`,
                method: "POST",
                body: params.data
            })
        }),
        deletePricingInstruction: builder.mutation<APIResponseModel<void>, { companyId: number, id: number }>({
            query: (params) => ({
                url: `/pricing/delete/${params.id}/${params.companyId}`,
                method: "DELETE"
            })
        }),
        saveProductPricing: builder.mutation<APIResponseModel<ProductPricingModel>, { companyId: number, data: ProductPricingModel }>({
            query: (params) => ({
                url: `/pricing/${params.data.id > 0 ? 'update_product_pricing' : 'create_product_pricing'}/${params.companyId}`,
                method: "POST",
                body: params.data
            })
        }),
        updateProductPricingStatus: builder.mutation<APIResponseModel<void>, { companyId: number, id: number, status: boolean }>({
            query: (params) => ({
                url: `/pricing/update_product_pricing_status/${params.companyId}`,
                method: "POST",
                body: params
            })
        }),
        copyProductPricing: builder.mutation<APIResponseModel<PricingModel>, { companyId: number, id: number }>({
            query: (params) => ({
                url: `/pricing/copy_product_pricing/${params.companyId}`,
                method: "POST",
                body: params
            })
        }),
        deleteProductPricing: builder.mutation<APIResponseModel<void>, { companyId: number, id: number }>({
            query: (params) => ({
                url: `/pricing/delete_product_pricing/${params.id}/${params.companyId}`,
                method: "DELETE"
            })
        }),
        saveProductScript: builder.mutation<APIResponseModel<void>, { companyId: number, id: number, productScript: string }>({
            query: (params) => ({
                url: `/pricing/save_product_script/${params.companyId}`,
                method: "POST",
                body: params
            })
        }),
        savePricingSurcharge: builder.mutation<APIResponseModel<PricingSurchargeModel>, { companyId: number, data: PricingSurchargeModel }>({
            query: (params) => ({
                url: `/pricing/${params.data.id > 0 ? 'update_product_surcharge' : 'create_product_surcharge'}/${params.companyId}`,
                method: "POST",
                body: params.data
            })
        }),
        updatePricingSurchargeStatus: builder.mutation<APIResponseModel<void>, { companyId: number, id: number, status: boolean, productPricingId: number }>({
            query: (params) => ({
                url: `/pricing/update_surcharge_status/${params.companyId}`,
                method: "POST",
                body: params
            })
        }),
        deletePricingSurcharge: builder.mutation<APIResponseModel<void>, { companyId: number, id: number, productPricingId: number }>({
            query: (params) => ({
                url: `/pricing/delete_product_surcharge/${params.companyId}`,
                method: "POST",
                body: params
            })
        }),
        generateSurchargeScript: builder.query<APIResponseModel<string | null>, { companyId: number, id: number }>({
            query: (params) => ({
                url: `/pricing/generate_surcharge_script/${params.id}/${params.companyId}`,
            })
        }),
        saveSurchargeScript: builder.mutation<APIResponseModel<void>, { companyId: number, id: number, productPricingId: number, surchargeScript: string }>({
            query: (params) => ({
                url: `/pricing/save_surcharge_script/${params.companyId}`,
                method: "POST",
                body: params
            })
        }),

        getPricingRequestList: builder.mutation<APIResponseModel<PricingRequestListingModel>, PricingRequestFilterModel>({
            query: (params) => ({
                url: '/pricing/request_list',
                method: "POST",
                body: params
            })
        }),
        getPricingRequest: builder.query<APIResponseModel<PricingRequestDetail | null>, { id: number, expand: number }>({
            query: (params) => ({
                url: `/pricing/pricing_request/${params.id}/${params.expand}`,
            })
        }),
        savePricingRequest: builder.mutation<APIResponseModel<PricingRequestModel>, PricingRequestModel>({
            query: (params) => ({
                url: '/pricing/save_request',
                method: "POST",
                body: params
            })
        }),
        pricingGenerator: builder.mutation<APIResponseModel<PricingResponseModel>, PricingResponseModel>({
            query: (params) => ({
                url: '/pricing/pricing_generator',
                method: "POST",
                body: params
            })
        }),
        pricingStream: builder.mutation<void, PricingStreamHandler & AdditionalMeta>({
            query: (params) => ({
                url: '/pricing/pricing_generator',
                method: "POST",
                body: params.data,
                responseHandler: async (response) => {

                    if (response.ok && response.body) {
                        const reader = response.body.getReader();
                        let done, value;
                        const decoder = new TextDecoder();

                        let responseId = Number(response.headers.get("response-Data"));

                        while (!done) {
                            ({ value, done } = await reader.read());

                            const decodedChunk = decoder.decode(value, { stream: true });
                            params.onChunk(decodedChunk, responseId);
                        }

                        params.onEnd?.(responseId)
                    }
                }
            })
        }),
        getSuggestions: builder.mutation<APIResponseModel<string[]>, PricingResponseModel[]>({
            query: (params) => ({
                url: '/pricing/get_suggestions',
                method: "POST",
                body: params
            })
        }),
        finalPricingGenerator: builder.mutation<APIResponseModel<FinalPricingResultModel>, { pricingRequestId: number, pricingInstructionId: number } & AdditionalMeta>({
            query: (params) => ({
                url: '/pricing/final_pricing_tags',
                method: "POST",
                body: params
            })
        }),
        deletePricingResponse: builder.mutation<APIResponseModel<void>, { pricingRequestId: number, pricingReponseId: number }>({
            query: (params) => ({
                url: `/pricing/delete_pricing_response/${params.pricingRequestId}/${params.pricingReponseId}`,
                method: "DELETE"
            })
        }),
        deletePricingAttachment: builder.mutation<APIResponseModel<void>, { pricingRequestId: number, attachmentId: number }>({
            query: (params) => ({
                url: `/pricing/delete_pricing_attachment/${params.pricingRequestId}/${params.attachmentId}`,
                method: "DELETE"
            })
        }),
        setRequestStatus: builder.mutation<APIResponseModel<PricingRequestModel>, StatusChangesParams>({
            query: (params) => ({
                url: '/pricing/update_request_status',
                method: "POST",
                body: params
            })
        }),
        deletePricingRequest: builder.mutation<APIResponseModel<void>, { id: number }>({
            query: (params) => ({
                url: `/pricing/delete_request/${params.id}`,
                method: "DELETE"
            })
        }),
        CopyPricingInstruction: builder.mutation<APIResponseModel<PricingInstructionListItemModel>, { companyId: number, id: number }>({
            query: (params) => ({
                url: `/pricing/copy_pricing_instruction/${params.companyId}`,
                method: "POST",
                body: params
            })
        }),
    }),
})

export const {
    useGetPricingInstructionSelectListQuery,
    useLazyGetPricingInstructionListQuery,
    useGetPricingInstructionListQuery,
    useLazyGetPricingInstructionQuery,
    useSavePricingInstructionMutation,
    useDeletePricingInstructionMutation,

    useSaveProductPricingMutation,
    useUpdateProductPricingStatusMutation,
    useCopyProductPricingMutation,
    useDeleteProductPricingMutation,
    useSaveProductScriptMutation,

    useSavePricingSurchargeMutation,
    useUpdatePricingSurchargeStatusMutation,
    useDeletePricingSurchargeMutation,
    useLazyGenerateSurchargeScriptQuery,
    useSaveSurchargeScriptMutation,

    useGetPricingRequestListMutation,
    useLazyGetPricingRequestQuery,
    useSavePricingRequestMutation,
    useDeletePricingRequestMutation,
    usePricingGeneratorMutation,
    usePricingStreamMutation,
    useGetSuggestionsMutation,
    useDeletePricingResponseMutation,
    useDeletePricingAttachmentMutation,

    useFinalPricingGeneratorMutation,
    useSetRequestStatusMutation,
    useCopyPricingInstructionMutation
} = PricingApi