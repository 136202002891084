import { DeleteOutlined, DownloadOutlined, UploadOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Tooltip, Upload, UploadFile, UploadProps, message } from "antd";
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { FormInstance, Rule } from "antd/es/form";
import Dragger from 'antd/es/upload/Dragger';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { getAPIUrl, getAccessToken } from '../../../redux/requests';
import { APIResponseModel } from '../../models';
import AttachmentTag from './AttachmentTag';

interface FileUploadProps {
    label?: string,
    allowedFileTypes?: string[],
    maxCount: number,
    multiple?: boolean,
    showDownloadIcon?: boolean,
    compact?: boolean,
    size?: 'large' | 'small',
    disabled?: boolean,
    data?: Record<string, any>,
    uploadUrl?: string,
    keepUploadedFiles?: boolean,
    extraActions?: boolean,
    form?: FormInstance,
    rules?: Rule[],
    onUploadComplete?: (file: UploadFile<APIResponseModel<any>>, validFileForActions?: boolean) => void
}

const FileUpload: React.FC<FileUploadProps> = (props) => {
    const [headers, setHeaders] = useState({ Authorization: "" }); // Set explicitly as facing issue

    let newAttachmentId = 0,
        multiple = props.multiple !== false,
        disabled = props.disabled === true,
        keepUploadedFiles = props.keepUploadedFiles !== false,
        showDownloadIcon = props.showDownloadIcon !== false,
        allowedFileTypes = props.allowedFileTypes || [".docx", ".pdf", ".txt"];

    const fileUploadProps: UploadProps<APIResponseModel<any>> = {
        multiple,
        disabled,
        action: getAPIUrl(props.uploadUrl || 'files/upload'),
        maxCount: props.maxCount,
        accept: allowedFileTypes?.join(','),
        data: props.data,
        beforeUpload: (file) => {
            if (allowedFileTypes.indexOf('.' + file.name?.split('.').pop() || "") < 0) {
                message.error(`${file.name} is not allowed to upload. Only ${allowedFileTypes.join(',')} files allowed.`);
                return false;
            }
        },
        onChange(info) {
            const { file, file: { status }, file: { response } } = info;

            if (status === "done") {
                if (response?.success) {
                    file.uid = (--newAttachmentId).toString();
                    file.fileName = response.data.file_guid || '';

                    props.onUploadComplete?.(file, props.extraActions ? validFileForActions(file.name) : false);

                    if (!keepUploadedFiles)
                        _.remove(info.fileList, x => x.uid === file.uid);
                }
                else
                    message.error(`${info.file.name} file upload failed. Error: ${response?.message}`);
            } else if (status === "error")
                message.error(`${info.file.name} file upload failed.`);
        }
    }

    const showUploadList = {
        showRemoveIcon: true,
        showDownloadIcon,
        downloadIcon: "Download"
    }

    const normFile = (e: any) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    }

    const validFileForActions = useCallback((fileName: string) => {

        let ext = fileName.split(".").pop();
        let valid = false;

        switch (ext) {
            case "pdf":
            case "pptx":
            case "docx":
            case "xlsx":
            case "txt":
                valid = true;
                break;
        }

        return valid;
    }, [])

    const onActionChange = (e: CheckboxChangeEvent, file: UploadFile, fileList: UploadFile[]) => {
        let _file = fileList.find(x => x.uid === file.uid);
        let field = e.target.id || '';

        if (_file) {
            _file.linkProps = {
                ...(_file.linkProps || {}),
                [field]: e.target.checked
            }

            props.form?.setFieldValue("fileList", fileList.map(x => x));
        }
    }

    const renderFileItem = (originNode: any, file: UploadFile, fileList: UploadFile[], actions: any) => (
        <div className={`d-flex ant-upload-list-item m-0 py-5 ant-upload-list-item-${file.status ?? "error"}`}>
            <div className="flex-grow-1">{file.name}</div>

            {
                props.extraActions && file.status === "done" && validFileForActions(file.name) && <div>
                    <Checkbox id="includeForAttachment"
                        className='fs-7'
                        checked={file.linkProps?.includeForAttachment}
                        onChange={(e) => onActionChange(e, file, fileList)}>
                        Use for Attachments
                    </Checkbox>
                    <Checkbox id="includeForTraining"
                        className='fs-7'
                        checked={file.linkProps?.includeForTraining}
                        onChange={(e) => onActionChange(e, file, fileList)}>
                        Use for Training
                    </Checkbox>
                </div>
            }

            {
                showDownloadIcon && Number(file.uid) > 0 &&
                <div>
                    <AttachmentTag className='ant-btn-default ant-btn-sm ant-btn-icon-only d-block'
                        fileName={file.name} fileGuid={file.fileName || ''}>
                        <DownloadOutlined  />
                    </AttachmentTag>
                </div>
            }

            <div>
                <Button size="small" icon={<DeleteOutlined  />}
                    onClick={() => actions.remove(file)}
                />
            </div>
        </div>
    )

    useEffect(() => {
        getAccessToken().then(token => {
            setHeaders({
                Authorization: token ? `Bearer ${token}` : ""
            })
        });
    }, [])

    return <>
        {
            /* Keep the File Upload elements code repeated due to issue with Form */
            props.form ?
                <Form.Item name="fileList" valuePropName="fileList"
                    rules={props.rules}
                    label={props.label || "Attachments"}
                    getValueFromEvent={normFile}>
                    {
                        props.compact ?
                            <Upload {...fileUploadProps} headers={headers}
                                showUploadList={showUploadList}
                                itemRender={renderFileItem}
                            >
                                <Button disabled={props.disabled} size={props.size || 'middle'} icon={<UploadOutlined  />}>{props.label || "Click to upload files"}</Button>
                            </Upload> :
                            <Dragger {...fileUploadProps} headers={headers} showUploadList={showUploadList}
                                itemRender={renderFileItem}>
                                <p className="ant-upload-text">Drop your files or click to upload</p>
                                <p className="ant-upload-hint text-primary">
                                    {props.maxCount > 1 && props.maxCount < Number.MAX_SAFE_INTEGER ? `Upload up to ${props.maxCount} files. ` : ''}Allowed file types {allowedFileTypes.map(x => _.trim(x.toUpperCase(), '.')).join(", ")} only.
                                </p>
                            </Dragger>
                    }
                </Form.Item>
                : <>
                    {
                        props.compact ?
                            <Upload {...fileUploadProps} headers={headers}
                                showUploadList={showUploadList}
                                itemRender={renderFileItem}
                            >
                                <Tooltip title={`${allowedFileTypes.map(x => _.trim(x.toUpperCase(), '.')).join(", ")} files only.`}>
                                    <Button disabled={props.disabled} size={props.size || 'middle'} icon={<PaperClipOutlined  />}>{props.label || "Click to upload files"}</Button>
                                </Tooltip>
                            </Upload> :
                            <Dragger {...fileUploadProps} headers={headers} showUploadList={showUploadList}
                                itemRender={renderFileItem}>
                                <p className="ant-upload-text">Drop your files or click to upload</p>
                                <p className="ant-upload-hint text-primary">
                                    {props.maxCount > 1 && props.maxCount < Number.MAX_SAFE_INTEGER ? `Upload up to ${props.maxCount} files. ` : ''}Allowed file types {allowedFileTypes.map(x => _.trim(x.toUpperCase(), '.')).join(", ")} only.
                                </p>
                            </Dragger>
                    }
                </>
        }
    </>
}

export default FileUpload;