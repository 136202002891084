import { BorderOutlined, CheckOutlined, CopyOutlined, HistoryOutlined } from '@ant-design/icons';
import { App, Button, Card, Drawer, Form, Input, Select, Space, Tooltip } from "antd";
import _ from "lodash";
import Markdown from 'markdown-to-jsx';
import { useCallback, useMemo, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { selectMasterDataByType } from "../../../../redux/MasterDataSlice";
import { useAppSelector } from "../../../../redux/hooks";
import { useGetDatasetSelectListQuery } from "../../../../redux/rtkquery/DatasetApi";
import { useResponseGeneratorMutation } from "../../../../redux/rtkquery/QuestionApi";
import { filterOptions, getDatasetGroups } from "../../../common/CommonFunctions";
import { ResponseFormat, StaticDataSets } from "../../../common/Enums";
import useCopyToClipboard from '../../../common/components/useCopyToClipboard';
import { GenerateResponseParams } from "../../../models";
import { SourceTags } from './SourceTags';

type requestParams = {
    datasetId?: number | null,
    question?: string | null,
    responseFormatId?: number | null,
    response?: string | null,
    sources?: string | null,
    open?: boolean | null,
    fromHistory?: boolean,
    onClose?: () => void
}

const NinjaGenerator: React.FC<requestParams> = (props) => {
    const navigate = useNavigate();
    const [form] = Form.useForm<GenerateResponseParams>();
    const [response, setResponse] = useState(props?.response ?? "");
    const [sources, setSources] = useState(props.sources ?? "");
    const [searchText, setSearchText] = useState("");
    const lastRequestRef = useRef<any>(null);
    const [ninjaModalOpen, setNinjaModalOpen] = useState<boolean>(props.open ?? false);
    const { notification } = App.useApp();
    const { isCopied, copyToClipboard, resetCopied } = useCopyToClipboard();
    const textRef = useRef<HTMLDivElement>(null);

    const allDatasetListState = useGetDatasetSelectListQuery();
    const [triggerPromptStream, generateResponseResult] = useResponseGeneratorMutation();
    const responseFormats = _.filter(useAppSelector(state => selectMasterDataByType(state, "ResponseFormat")), x => x.value !== ResponseFormat.Custom);

    const onFinish = async (values: GenerateResponseParams) => {
        try {
            setResponse("");
            setSources("");

            lastRequestRef.current = triggerPromptStream({
                data: values,
                onChunk: (chunk) => {
                    if (chunk && chunk.includes("sources:[{")) {
                        
                        let chunks = chunk.split("sources:[{")
                        setResponse((answer) => answer + chunks[0])

                        let sources = `[{${chunks[1]}`
                        setSources(sources);
                        return;
                    }

                    setResponse((answer) => answer + chunk)
                }
            })

        } catch (err) {
            console.error(err, "err");
        }
    }

    const onCancelResponse = () => {
        lastRequestRef.current?.abort();
    }

    const onGenerate = () => {

        if (!_.trim(searchText)) {
            notification.info({
                message: "Validation!",
                description: "Enter your query to generate response"
            })
            return;
        }

        const params = {
            question: _.trim(searchText),
            datasetId: StaticDataSets.Default,
            responseFormatId: ResponseFormat.Short
        };

        form.setFieldsValue(params);
        onFinish(params);
        setNinjaModalOpen(true);
        setSearchText('');
    }

    const onSearchTextChange = (e: any) => {
        setSearchText(e.target.value);
    }

    const onReset = () => {
        const params = {
            question: '',
            datasetId: StaticDataSets.Default,
            responseFormatId: ResponseFormat.Short
        };

        form.setFieldsValue(params);
        setResponse("");
        resetCopied();
        setSources("");
    }

    const onCopy = () => {
        copyToClipboard({ copiedText: textRef.current?.innerText || "", copiedHTML: textRef.current?.innerHTML || "" });
    }

    const onModalClose = () => {
        onCancelResponse();
        setNinjaModalOpen(false);
        props.onClose?.();
    }

    const onHistoryModalOpen = () => {
        onModalClose();
        navigate('/ninja-history');
    }

    const datasetGroups = useMemo(() => {
        return getDatasetGroups(allDatasetListState.data || [])
    }, [allDatasetListState]);

    const filterOption = useCallback(filterOptions, [allDatasetListState]);

    const handleKeyDown = (event: any) => {
        if ((event.ctrlKey || event.metaKey) && event.key === 'Enter') {
            form.submit();
        }
    }

    const onHistoryClick = () => navigate('/ninja-history')

    return (
        <>
            {
                !props.fromHistory &&
                <Space.Compact className='w-100'>
                    <Input size='large'
                        value={searchText}
                        onChange={onSearchTextChange}
                        onPressEnter={onGenerate}
                        placeholder="Have a quick query? Need some ninja-like guidance?"
                    />
                    <Space.Compact block style={{ width: '175px' }}>
                        <Button type="default" size="large"
                            onClick={onGenerate}
                            loading={generateResponseResult.isLoading}
                            disabled={generateResponseResult.isLoading}>
                            Ask Away!
                        </Button>
                        <Tooltip title="History">
                            <Button type="default" size="large"
                                icon={<HistoryOutlined />}
                                onClick={onHistoryClick}
                            />
                        </Tooltip>
                    </Space.Compact>
                </Space.Compact>
            }

            <Drawer
                width={600}
                forceRender={true}
                open={ninjaModalOpen}
                title="Ninja Generator"
                onClose={onModalClose}
                styles={{
                    body: { padding: '12px 24px' }
                }}
                extra={
                    !props.fromHistory &&
                    <Button
                        icon={<HistoryOutlined />}
                        onClick={onHistoryModalOpen}>
                        History
                    </Button>
                }
            >
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                    autoComplete="off"
                    initialValues={props ?? undefined}
                >
                    <Form.Item name="question" label="Enter Question"
                        rules={[{ required: true, message: "Questoin is required", whitespace: true }]}>
                        <Input.TextArea autoSize
                            placeholder={`For example: "Write an email introducing my company to a prospective client", or
"Write a short description of my company's products and services."
Press Ctrl/Command + Enter to submit the request.`}
                            style={{ minHeight: '55px' }}
                            onKeyDown={handleKeyDown}
                        />
                    </Form.Item>

                    <Row>
                        <Col sm={12} md={6}>
                            <Form.Item name="datasetId" label="Dataset">
                                <Select
                                    showSearch
                                    options={datasetGroups}
                                    loading={allDatasetListState.isFetching}
                                    filterOption={filterOption}
                                    listItemHeight={10}
                                />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item name="responseFormatId" label="Response Format">
                                <Select
                                    options={responseFormats}
                                    fieldNames={{ label: "name" }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item className="text-end mb-0">
                        <div className="d-flex justify-content-between">
                            <div>
                                <Button type="default"
                                    className='btn-stop-response'
                                    size="large"
                                    icon={<BorderOutlined />}
                                    onClick={onCancelResponse}
                                    hidden={!generateResponseResult.isLoading}>
                                    Stop Ninja
                                </Button>
                            </div>

                            <Space>
                                <Button size="large"
                                    disabled={generateResponseResult.isLoading}
                                    onClick={onReset}>
                                    Clear
                                </Button>
                                <Button type="primary" size="large" htmlType="submit"
                                    loading={generateResponseResult.isLoading}>
                                    {
                                        generateResponseResult.isLoading ? "Generating" : "Generate"
                                    }
                                </Button>
                            </Space>
                        </div>
                    </Form.Item>
                </Form>

                <Card className="mt-5"
                    loading={generateResponseResult.isLoading}
                    hidden={!generateResponseResult.isLoading || response !== ""}
                />

                {
                    response !== "" &&
                    <>
                        <div className="border-start border-4 p-3 bg-gray-100 html-box mt-5">
                            <div className='text-end mb-2'>
                                <Button size='small'
                                    disabled={isCopied}
                                    icon={isCopied ? <CheckOutlined /> : <CopyOutlined />}
                                    onClick={onCopy}>
                                    {isCopied ? "Copied" : "Copy to Clipboard"}
                                </Button>
                            </div>
                            <div id="ninja_generator_response"
                                className='d-inline-block'
                                ref={textRef}
                            >
                                <Markdown>{response}</Markdown>
                            </div>
                            <div className='response-cursor' hidden={!generateResponseResult.isLoading}>&nbsp;</div>
                        </div>
                        {
                            sources &&
                            <SourceTags sources_json={sources} className='mt-2' />
                        }
                    </>
                }

            </Drawer>
        </>
    )
}

export { NinjaGenerator };

