import { InfoCircleOutlined } from '@ant-design/icons';
import { Card, Spin } from "antd";
import _ from 'lodash';
import { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useQuotesForApprovalStatsMutation, useQuotesStatsMutation } from "../../../redux/rtkquery/DashboardAPI";
import { localeCurrency, localeNumber } from "../../common/CommonFunctions";
import { DashboardOpenQuoteStats } from "../../models";

type Props = {
  teamStats: boolean
}

const OpenQuotesStats: React.FC<Props> = (props) => {
  const [quotesStats, setQuotesStats] = useState<DashboardOpenQuoteStats[]>([]);
  const [approvalQuotesStats, setApprovalQuotesStats] = useState<DashboardOpenQuoteStats | null>(null);
  const [triggerQuotesStats, quotesStatsResult] = useQuotesStatsMutation();
  const [triggerApprovalQuotesStats, approvalQuotesStatsResult] = useQuotesForApprovalStatsMutation();

  useEffect(() => {
    triggerQuotesStats({ teamStats: props.teamStats })
      .then(response => {
        if ('data' in response && response.data.success) {
          let _quotesStats:DashboardOpenQuoteStats[] = [];

          _.forEach([1, 2], status => {
            let stats = _.find(response.data.data, data => data.status === status);

            if (!stats)
              stats = { status, requestCount: 0, totalPrice: 0, totalQuantity: 0 };
            _quotesStats.push(stats);
          })

          setQuotesStats(_quotesStats);
        }
      });
    triggerApprovalQuotesStats({ teamStats: props.teamStats })
      .then(response => {
        if ('data' in response && response.data.success)
          setApprovalQuotesStats(response.data.data);
      });
  }, []);

  const totalQuotes = useMemo(() => {
    const data: DashboardOpenQuoteStats = {
      status: 0,
      requestCount: _.sumBy(quotesStats, x => x.requestCount),
      totalPrice: _.sumBy(quotesStats, x => x.totalPrice),
      totalQuantity: _.sumBy(quotesStats, x => x.totalQuantity)
    }
    return data;
  }, [quotesStats])

  return (
    <Row>
      <Col sm={12} md={props.teamStats ? 6 : 12}>
        <Card>
          <span className="dashboard-card-info"><InfoCircleOutlined  /> Past 90 days</span>
          <Spin spinning={quotesStatsResult.isLoading}>
            <div className="d-flex justify-content-center d-grid">
              <div className="px-5 text-center flex-fill">
                <div className="fs-2hx fw-bold lh-1 ls-n2 mb-2 text-primary">{localeNumber(totalQuotes.requestCount)}</div>
                <div className="fs-6 fw-semibold text-gray-500">Total Quotes</div>
              </div>

              <div className="border-start-dashed border-start border-gray-300 px-5 text-center flex-fill">
                <div className="fs-2 lh-1 ls-n2 mb-2  text-primary">{localeCurrency(totalQuotes.totalPrice)}</div>
                <div className="fs-6 fw-semibold text-gray-500">Total Value</div>
              </div>

              <div className="border-start-dashed border-start border-gray-300 px-5 text-center flex-fill">
                <div className="fs-2 lh-1 ls-n2 mb-2 text-primary">{localeNumber(totalQuotes.totalQuantity)}</div>
                <div className="fs-6 fw-semibold text-gray-500">Total Quantities</div>
              </div>
            </div>

            {
              _.map(quotesStats, (x, index) => (
                <div key={index}>
                  <div className="separator separator-dashed my-3"></div>

                  <div className="d-flex flex-stack">
                    <div className="text-gray-700 fw-semibold fs-6 me-2 w-25">{x.status === 1 ? 'Active' : 'Complete'}</div>

                    <div className="d-flex align-items-center flex-column flex-grow-1">
                      <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                        <span className="fw-bold fs-6 text-gray-900">{localeNumber(x.requestCount)} Quotes</span>
                        <span className="fs-6 text-gray-500">{localeNumber(x.totalQuantity)} Quantities</span>
                      </div>

                      <div className={`h-8px mx-3 w-100 rounded bg-light-${x.status === 1 ? 'warning' : 'success'}`}>
                        <div className={`bg-${x.status === 1 ? 'warning' : 'success'} rounded h-8px`} role="progressbar" style={{ width: `${ totalQuotes.requestCount > 0 ? ((x.requestCount / totalQuotes.requestCount) * 100) : 0}%` }}></div>
                      </div>
                    </div>

                    <div className="w-25 text-end">
                      <span className="text-gray-900 fw-bold fs-6">{localeCurrency(x.totalPrice)}</span>
                    </div>
                  </div>
                </div>
              ))
            }
          </Spin>
        </Card>
      </Col>
      <Col sm={12} md={props.teamStats ? 6 : 12}>
        <Card className="h-100">
          <span className="dashboard-card-info"><InfoCircleOutlined /> Past 90 days</span>
          <Spin spinning={approvalQuotesStatsResult.isLoading}>
            <div className={`d-flex justify-content-center d-grid ${props.teamStats ? 'mt-16' : 'mt-0'}`}>
              <div className="px-5 text-center flex-fill">
                <div className="fs-2hx fw-bold lh-1 ls-n2 mb-2">{localeNumber(approvalQuotesStats?.requestCount ?? 0)}</div>
                <div className="fs-6 fw-semibold text-gray-500">Quotes for Approval</div>
              </div>

              <div className="border-start-dashed border-start border-gray-300 px-5 text-center flex-fill">
                <div className="fs-2 text-gray-700 lh-1 ls-n2 mb-2">{localeCurrency(approvalQuotesStats?.totalPrice ?? 0)}</div>
                <div className="fs-6 fw-semibold text-gray-500">Total Value</div>
              </div>

              <div className="border-start-dashed border-start border-gray-300 px-5 text-center flex-fill">
                <div className="fs-2 text-gray-700 lh-1 ls-n2 mb-2">{localeNumber(approvalQuotesStats?.totalQuantity ?? 0)}</div>
                <div className="fs-6 fw-semibold text-gray-500">Total Quantities</div>
              </div>
            </div>
          </Spin>
        </Card>
      </Col>
    </Row>
  )
}

export { OpenQuotesStats };

