import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';
import { Modal, ModalProps } from "antd";
import { useStateWithCallback } from "../CommonFunctions";
import clsx from 'clsx';

interface FullScreenModalProps extends ModalProps {
    fullScreen?: boolean,
    hideFullScreenBtn?: boolean
}

const FullScreenModal: React.FC<FullScreenModalProps> = (props) => {
    const [fullScreen, setFullScreen] = useStateWithCallback(props.fullScreen ?? false);

    const toggleFullScreen = () => {
        setFullScreen(!fullScreen);
    }

    return (
        <Modal {...props}
            title={
                <div className={clsx('d-flex', 'align-items-start', props.closable === false ? '' : 'me-6')}>
                    <div className='flex-grow-1'><h2 className='mb-0'>{props.title}</h2></div>
                    {
                        props.hideFullScreenBtn !== true ?
                            (fullScreen ? <FullscreenExitOutlined  onClick={toggleFullScreen} /> :
                                <FullscreenOutlined  onClick={toggleFullScreen} />) : null
                    }
                </div>
            }
            style={{ top: 20 }}
            wrapClassName={fullScreen ? "modal-full-screen" : ''}
        >
            {props.children}
        </Modal>
    )
}

export default FullScreenModal;