import { LoadingOutlined } from '@ant-design/icons';
import { App, Card, Divider, Drawer, Popover, Tag } from "antd";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useGetFileDownloadUrlMutation } from "../../../../redux/rtkquery/CommonApi";
import { useGetSourceInfoQuery } from "../../../../redux/rtkquery/CompanyApi";
import { downloadFileSilently, safeJsonParse } from "../../../common/CommonFunctions";
import HTMLContent from "../../../common/components/HtmlContent";
import { DataSetType } from "../../../common/Enums";
import { NinjaResponseSourceModel } from "../../../models";

const ContentType: Record<string, string> = {
    RichText: "RichText",
    File: "File",
    WebUrl: "WebUrl"
}

const DatasetTitle: Record<string, string> = {
    "CompanyWebsite": "Website",
    "CompanyProfile": "Company Profile",
    "CompanyInfo": "Company Information",
    "Custom": "Additional Datasets",
    "Products": "Products and Services",
    "Library": "Library"
}

const SourceTags: React.FC<{ sources_json?: string | null, className?: string }> = (props) => {
    const [triggerFileDownloadUrl, fileDownloadUrlResult] = useGetFileDownloadUrlMutation();
    const [selectedSource, setSelectedSource] = useState<NinjaResponseSourceModel | null>(null)

    const Sources = useMemo(() => {
        let sources = props.sources_json ? (safeJsonParse<NinjaResponseSourceModel[]>(props.sources_json) ?? []) : [];
        return sources;
    }, [props.sources_json])

    const getSourceDatasetId = (dataset: string) => {
        let datasetId: number | null = null;

        switch (dataset) {
            case "CompanyWebsite":
                datasetId = DataSetType.CompanyWebsite
                break;
            case "CompanyProfile":
                datasetId = DataSetType.CompanyProfile
                break;
            case "CompanyInfo":
                datasetId = DataSetType.CompanyInfo
                break;
            case "Custom":
                datasetId = DataSetType.Custom
                break;
            case "Products":
                datasetId = DataSetType.Products
                break;
            case "Library":
                datasetId = DataSetType.Library
                break;
        }

        return datasetId;
    }

    const sourceInfo = <div className='fs-7' style={{ maxWidth: '300px' }}>
        References used to generate the response include company indexes from Profile, Products, Datasets, Attachments, and Web Urls.
    </div>

    const onTagClick = (source: NinjaResponseSourceModel) => {
        if (source.datasetContentType === ContentType.WebUrl)
            return

        if (source.datasetContentType === ContentType.RichText) {
            setSelectedSource(source)
            return
        }

        if (source.datasetContentType === ContentType.File && source.source) {
            triggerFileDownloadUrl({ fileGuid: source.source, fileName: source.title })
        }
    }

    useEffect(() => {
        if (!fileDownloadUrlResult.isLoading && fileDownloadUrlResult.data?.data)
            downloadFileSilently(fileDownloadUrlResult.data.data, fileDownloadUrlResult.originalArgs?.fileName ?? "");
    }, [fileDownloadUrlResult])

    return (
        <>
            {
                Sources.length > 0 &&
                <>
                    <div className={`mb-1 fs-7 ${props.className ?? ""}`}>
                        <Popover content={sourceInfo}>
                            Sources <i className="bi bi-info-circle fs-8" />
                        </Popover>
                    </div>
                    {
                        _.map(Sources, (s, i) => (
                            <SourceTag key={i} source={s}
                                onTagClick={() => onTagClick(s)}
                            />
                        ))
                    }
                </>
            }

            {
                selectedSource !== null &&
                <SourceInfo
                    dataset={selectedSource.dataset}
                    referenceId={selectedSource.reference}
                    datasetTypeId={getSourceDatasetId(selectedSource.dataset) ?? 0}
                    onClose={() => setSelectedSource(null)}
                />
            }
        </>
    )
}

export { SourceTags };

const SourceTag: React.FC<{ source: NinjaResponseSourceModel, onTagClick: () => void }> = (props) => {

    const [loading, setLoading] = useState<boolean>(false)

    const SourceTitle = useMemo(() => {
        let text = decodeURI(decodeURI(props.source.title))
        return text
    }, [props.source.title])

    const SourceIcon = useMemo(() => {
        let icon = null;

        if (loading)
            return <LoadingOutlined />

        switch (props.source.datasetContentType) {
            case ContentType.RichText:
                icon = <i className="bi bi-body-text" />
                break;
            case ContentType.WebUrl:
                icon = <i className="bi bi-globe" />
                break;
            case ContentType.File:
                icon = <i className="bi bi-file-earmark-text" />
                break;
        }

        return icon;

    }, [props.source.datasetContentType, loading])

    const SourceContent = useMemo(() => {
        let text: any = _.truncate(SourceTitle, { length: 80 }),
            contentType = props.source.datasetContentType;

        switch (props.source.datasetContentType) {
            case ContentType.RichText:
                contentType = props.source.dataset === "Library" ? "Question/Answer" : "Text";
                text = <span className='text-primary cursor-pointer'>{text}</span>
                break;
            case ContentType.WebUrl:
                contentType = "Web Url";
                text = <a target='_blank' className='text-primary' href={props.source.source || ''}>{text}</a>
                break;
            case ContentType.File:
                contentType = "Attachment";
                text = <span className='text-primary cursor-pointer'>{text}</span>
                break;
        }
        return <div style={{ maxWidth: '300px' }}>
            <div className='d-flex'>
                <div className='me-2'>{SourceIcon}</div>
                <div>{text}</div>
            </div>

            <Divider className='my-2' />
            <div className="fs-8 text-gray-700">
                <div className="fs-9">Source - {contentType}</div>
                <div><span className="fw-bold">Dataset - </span> {DatasetTitle[props.source.dataset]}</div>
            </div>
        </div>
    }, [props.source])

    const onTagClick = (e: any) => {
        if (props.source.datasetContentType === ContentType.RichText || props.source.datasetContentType === ContentType.File) {
            e.preventDefault();
            setLoading(true)

            props.onTagClick();

            setTimeout(() => setLoading(false), 2000)
        }
    }

    return (
        <Tag className="source-tag" onClick={onTagClick}>
            <Popover content={SourceContent}>
                {
                    props.source.datasetContentType === ContentType.WebUrl ?
                        <a target='_blank' href={props.source.source || ''}>
                            {SourceIcon} {SourceTitle}
                        </a> :
                        <span>{SourceIcon} {SourceTitle}</span>
                }
            </Popover>
        </Tag>
    )
}

const SourceInfo: React.FC<{
    datasetTypeId: number,
    referenceId: string | number | null,
    dataset: string,
    onClose: () => void
}> =
    ({ datasetTypeId, referenceId, dataset, onClose }) => {
        const { notification } = App.useApp();
        const sourceInfo = useGetSourceInfoQuery({ datasetTypeId, referenceId });

        useEffect(() => {
            if (!sourceInfo.isLoading && (sourceInfo.error || sourceInfo.data?.statusCode !== 200)) {
                notification.error({
                    message: "Error",
                    description: "Source details not found",
                    placement: "topRight"
                })

                onClose();
            }
        }, [sourceInfo])

        return <Drawer
            width={600}
            open={true}
            title={sourceInfo.data?.data?.datasetName ?? "Source Information"}
            onClose={onClose}
            styles={{
                body: { padding: '12px 24px' }
            }}>
            {
                sourceInfo.isLoading ? <Card loading /> :
                    <>
                        <Tag>{DatasetTitle[dataset]}</Tag>

                        <HTMLContent
                            className="mt-2"
                            lines={100}
                            defaultExpanded={true}
                            hideExpanded={true}
                            content={sourceInfo.data?.data?.description ?? ""}
                        />
                    </>
            }
        </Drawer>
    }