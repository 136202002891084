import { HolderOutlined } from '@ant-design/icons';
import { Collapse, TabPaneProps, Tabs, TabsProps } from 'antd';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

const { Panel } = Collapse;

export const SortableTabDragHandle = SortableHandle(() => <HolderOutlined  />);

export const SortableTabPane = SortableElement<TabPaneProps>((props: TabPaneProps) =>{
    return <Tabs.TabPane {...props}>{props.children}</Tabs.TabPane>
});

interface CustomSortableTabsProps extends TabsProps {
    tabPanes: TabPaneProps[]
}

export const SortableTabs = SortableContainer<CustomSortableTabsProps>((props: CustomSortableTabsProps) => {
    return <Tabs {...props}>
        {props.tabPanes.map((item, index) => (
            <SortableTabPane {...item} index={index}>
                {item.children}
            </SortableTabPane>
        ))}
    </Tabs>
});