import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { App, Button, Form, FormInstance, FormListOperation, Input, Select, UploadFile } from "antd";
import _ from "lodash";
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useGetDatasetSelectListQuery } from "../../../redux/rtkquery/DatasetApi";
import { filterOptions, getDatasetGroups } from '../../common/CommonFunctions';
import { ActionType } from '../../common/Enums';
import FileUpload from '../../common/components/FileUpload';
import HtmlEditor from '../../common/components/HtmlEditor';
import { LibraryAlternativeQuestionModel, LibraryQuestionModel } from "../../models";

type ComponentProps = {
    question: LibraryQuestionModel,
    form: FormInstance<LibraryQuestionModel>,
    onSubmit: (values: LibraryQuestionModel) => void,
    hideAdditionalQuestion?: boolean
}

export const AddEditForm: React.FC<ComponentProps> = (props) => {
    const { form } = props;
    const question = _.cloneDeep(props.question);
    const { notification } = App.useApp();
    const allDatasetListState = useGetDatasetSelectListQuery();
    const editorRef = useRef<any>(null);
    let libraryQuestionId = 0;

    const onAddAltQuestion = (params: FormListOperation) => {
        let dataContent: LibraryAlternativeQuestionModel = {
            libraryQuestionId: --libraryQuestionId,
            question: '',
            action: ActionType.Insert
        };
        params.add(dataContent);
    }

    const onFinishFailed = (errorInfo: any) => {
        notification.error({
            message: "Validation Error",
            description: "Please fix highlighted errors",
            placement: "topRight"
        })
    }

    const onFinish = (values: LibraryQuestionModel) => {

        if (!_.trim(_.trim(editorRef.current.getContent({ format: "text" })))) {
            form.setFieldValue("answer", '');
            form.validateFields();
            return;
        }

        values.answer = _.replace(values.answer, /<p><br><\/p>+/g, "");
        values.answer = _.replace(values.answer, /[\n\r]/g, "");

        question.question = values.question;
        question.answer = values.answer;
        question.datasetId = values.datasetId;

        _.forEach(question.alternativeQuestion, x => x.action = ActionType.Delete);

        if (values.alternativeQuestion?.length) {

            question.alternativeQuestion ??= [];

            values.alternativeQuestion.forEach(x => {
                let existing = x.libraryQuestionId > 0 ?
                    question.alternativeQuestion.find(d => d.libraryQuestionId === x.libraryQuestionId) : null;
                x.question = _.trim(x.question);

                if (existing) {
                    existing.action = _.toLower(_.trim(existing.question)) !== _.toLower(x.question) ? ActionType.Update : null;
                    existing.question = x.question;
                    return;
                }

                question.alternativeQuestion.push(x);
            });
        }

        question.libraryDocument ??= [];

        _.forEach(question.libraryDocument, x => x.action = ActionType.Delete);

        let fileList: UploadFile[] = form.getFieldValue("fileList");

        _.forEach(fileList, x => {
            if (x.status === "done") {
                question.libraryDocument ??= [];

                let existingFile = _.find(question.libraryDocument, d => d.attachmentId === Number(x.uid));

                if (existingFile) {
                    let includeForAttachment = x.linkProps?.includeForAttachment ?? false;
                    let includeForTraining = x.linkProps?.includeForTraining ?? false;

                    // Set no change
                    existingFile.action = null;

                    // Library Recommendation case insertion
                    if (existingFile.attachmentId <= 0)
                        existingFile.action = ActionType.Insert;
                    // Any change case on existing file
                    else if ((existingFile.includeForAttachment ?? false) !== includeForAttachment ||
                        (existingFile.includeForTraining ?? false) !== includeForTraining)
                        existingFile.action = ActionType.Update;

                    existingFile.includeForAttachment = includeForAttachment;
                    existingFile.includeForTraining = includeForTraining;
                    return;
                }

                question.libraryDocument.push({
                    attachmentId: 0,
                    libraryQuestionId: question.libraryQuestionId,
                    action: ActionType.Insert,
                    fileDisplayName: x.name,
                    fileName: x.fileName || '',
                    includeForAttachment: x.linkProps?.includeForAttachment ?? false,
                    includeForTraining: x.linkProps?.includeForTraining ?? false
                })
            }
        });

        props.onSubmit(question);
    }

    const onFileUploadComplete = (file: UploadFile, validFileForActions?: boolean) => {
        if (validFileForActions)
            file.linkProps = { includeForAttachment: true };
    }

    const datasetGroups = useMemo(() => {
        return getDatasetGroups(allDatasetListState.data || [])
    }, [allDatasetListState]);

    const filterOption = useCallback(filterOptions, [allDatasetListState]);

    useEffect(() => {
        let fileList: UploadFile[] = [];

        _.forEach(question.libraryDocument, x => {
            fileList.push({
                uid: x.attachmentId.toString(),
                name: x.fileDisplayName,
                status: 'done',
                fileName: x.fileName,
                linkProps: {
                    includeForAttachment: x.includeForAttachment,
                    includeForTraining: x.includeForTraining
                }
            })
        });
        form.setFieldValue('fileList', fileList);
    }, [form])

    return <Form
        layout="vertical"
        form={form}
        name="LibraryQuestion"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        initialValues={question}
    >
        <Form.Item name="datasetId" label="Dataset"
            rules={[{ required: true, message: "'${label}' is required" }]}>
            <Select
                showSearch
                options={datasetGroups}
                placeholder="Select Dataset"
                loading={allDatasetListState.isFetching}
                filterOption={filterOption}
            />
        </Form.Item>

        <Form.Item name="question" label="Question"
            rules={[{ required: true, message: "'${label}' is required" }]}>
            <Input.TextArea autoSize
                placeholder="Enter question"
                style={{ minHeight: '55px' }}
            />
        </Form.Item>

        {
            props.hideAdditionalQuestion === true ? null :
                <Form.List name="alternativeQuestion">
                    {(fields, params) => (
                        <>
                            {fields.map((field, index) => (
                                <div className="d-flex" key={field.key}>
                                    <Form.Item name={[field.name, 'question']}
                                        key={field.key}
                                        className="flex-grow-1"
                                        rules={[{ required: true, message: "${label} is required" }]}>
                                        <Input placeholder={"Question Variation " + (index + 1)} />
                                    </Form.Item>
                                    {
                                        <Button onClick={() => params.remove(field.name)}
                                            icon={<MinusCircleOutlined />}
                                        />
                                    }
                                </div>
                            ))}
                            <Form.Item>
                                <Button
                                    block
                                    type="dashed"
                                    onClick={() => onAddAltQuestion(params)}
                                    icon={<PlusOutlined />}
                                >
                                    Add question variant
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
        }

        <Form.Item name="answer" label="Response"
            rules={[{ required: true, message: "'${label}' is required" }]}>
            <HtmlEditor setEditorRef={(editor: any) => editorRef.current = editor} />
        </Form.Item>

        <FileUpload form={form} maxCount={10} extraActions={true}
            onUploadComplete={onFileUploadComplete}
            allowedFileTypes={[".docx", ".pdf", ".txt", ".xlsx", ".pptx", ".jpg", ".jpeg", ".png"]}
        />
    </Form>
}