import { AppstoreAddOutlined, DeleteOutlined, DownOutlined, DownloadOutlined, EditOutlined, LoadingOutlined } from '@ant-design/icons';
import { App, Button, Card, Form, Input, Popconfirm, Popover, Space, Spin, Tag, Tooltip } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import _, { cloneDeep } from "lodash";
import { useEffect, useRef, useState } from "react";
import { Col, Row } from 'react-bootstrap';
import { selectMasterDataDictByType } from "../../../redux/MasterDataSlice";
import { addSectionQuestions, selectProjectFiles, updateQuestionFiles } from '../../../redux/ProjectsSlice';
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { useGetFileDownloadUrlMutation } from '../../../redux/rtkquery/CommonApi';
import { useGetDatasetSelectListQuery } from '../../../redux/rtkquery/DatasetApi';
import { useDeleteQuestionsUnreviewedMutation, useLazyGetQuestionFilesQuery, useLazyGetQuestionsToReviewQuery, useSaveQuestionsToReviewMutation } from "../../../redux/rtkquery/QuestionApi";
import { downloadFileSilently, useStateWithCallback } from '../../common/CommonFunctions';
import { QuestionFileStatus, QuestionFileStatusDetail, QuestionStatus, ResponseFormat } from '../../common/Enums';
import { QuestionFileModel, QuestionModel } from "../../models";
import { AddEditQuestionCommonControls } from './AddQuestions';

const { Column } = Table;

type ComponentProps = {
    projectId: number
}

const ReviewQuestions: React.FC<ComponentProps> = (props) => {
    const { notification } = App.useApp();
    const dispatch = useAppDispatch();
    const questionFilesSelector = useAppSelector(state => selectProjectFiles(state, props.projectId));
    const responseFormatsDict = useAppSelector(state => selectMasterDataDictByType(state, "ResponseFormat"));
    const [triggerGetQuestions, getQuestionsResult] = useLazyGetQuestionsToReviewQuery();
    const [triggerGetQuestionFiles, getQuestionFilesResult] = useLazyGetQuestionFilesQuery();
    const [triggerDeleteQuestionsUnreviewed, deleteQuestionsUnreviewedResult] = useDeleteQuestionsUnreviewedMutation();
    const [triggerSaveQuestionsToReview, saveQuestionsToReviewResult] = useSaveQuestionsToReviewMutation();
    const [triggerFileDownloadUrl, fileDownloadUrlResult] = useGetFileDownloadUrlMutation();
    const { data: datasets } = useGetDatasetSelectListQuery();

    const [loading, setLoading] = useState(false);
    const [fileFilterOpen, setFileFilterOpen] = useState(false);
    const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [questions, setQuestions] = useState<QuestionModel[]>([]);
    const [selectedFile, setSelectedFile] = useState<QuestionFileModel | null>(null);
    const [totalFiles, setTotalFiles] = useState(0);
    const fileStatusCheckCount = useRef(0);

    const columns: ColumnsType<QuestionModel> = [
        {
            title: "#",
            dataIndex: 'questionNumber',
            width: 60
        },
        {
            title: "Question",
            dataIndex: 'question',
            sorter: true,
            ellipsis: true
        },
        {
            title: "Dataset",
            dataIndex: 'datasetId',
            sorter: true,
            ellipsis: true,
            width: 150,
            render: (_, record) => datasets && datasets.find(v => v.datasetId === record.datasetId)?.datasetName
        },
        {
            title: "Response Formats",
            dataIndex: 'responseFormat',
            ellipsis: true,
            width: 150,
            render: (_, record) => record.responseFormat?.map(v => responseFormatsDict[v.responseFormatId].name).join(", ")
        },
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            width: 100,
            render: (_, record) => (
                <Space>
                    <Button size='small' icon={<EditOutlined />} onClick={() => editQuestion(record.questionId)} />
                    <Popconfirm
                        title="Delete"
                        description="Are you sure to delete this item?"
                        onConfirm={() => onDeleteQuestions([record.questionId])}
                        okText="Yes"
                        cancelText="No">
                        <Button size='small' icon={<DeleteOutlined />} />
                    </Popconfirm>
                </Space>
            ),
        },
    ]

    const editQuestion = (key: number) => {
        setExpandedRowKeys([key]);
    }

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    }

    const updateFile = (file: QuestionFileModel) => {
        let updatedQuestionFile = cloneDeep(file);

        if (selectedFile) {
            let _selectedFile = cloneDeep(selectedFile);
            _selectedFile.statusId = updatedQuestionFile.statusId;
            _selectedFile.totalQuestion = updatedQuestionFile.totalQuestion;
            _selectedFile.totalUnreviewedQuestion = updatedQuestionFile.totalUnreviewedQuestion;

            setSelectedFile(_selectedFile);
        }

        dispatch(
            updateQuestionFiles({
                files: [updatedQuestionFile],
                replace: true
            })
        );
    }

    const onQuestionEditClose = (updatedQuestion?: QuestionModel | null, file?: QuestionFileModel | null) => {
        if (updatedQuestion) {
            if (updatedQuestion.statusId === QuestionStatus.Unreviewed) {
                let indexId = questions.findIndex(x => x.questionId === updatedQuestion?.questionId);
                questions[indexId] = updatedQuestion;

                // Keep following syntax to notify ANTD Table
                setQuestions(questions.map(x => x));
            }
            else {
                if (file)
                    updateFile(file);

                _.remove(questions, x => x.questionId === updatedQuestion.questionId);

                dispatch(addSectionQuestions({ projectId: props.projectId, questions: [updatedQuestion] }));
                setQuestions([...questions]);
            }
        }
        setExpandedRowKeys([]);
    }

    const onDeleteQuestions = async (_questionIds?: number[]) => {
        let questionIds = new Set(_questionIds && _questionIds.length ? _questionIds : selectedRowKeys.map(x => Number(x)))

        if (questionIds.size) {

            triggerDeleteQuestionsUnreviewed({
                projectId: questions[0].projectId,
                importId: questions[0].importId || 0,
                questionIds: Array.from(questionIds)
            }).then(response => {
                if ("error" in response) {
                    return;
                }

                if (response.data.data)
                    updateFile(response.data.data);

                let filtered = questions.filter(x => !questionIds.has(x.questionId));
                setQuestions([...filtered]);

                notification.info({
                    message: "Question Removed",
                    description: `${questionIds.size} Question(s) removed from file`,
                    placement: "topRight"
                });
            });
        }
    }

    const onAddMultiple = () => {
        let selectedQuestions: QuestionModel[] = [];

        questions.forEach(q => {
            if (selectedRowKeys.indexOf(q.questionId) >= 0) {
                q.statusId = QuestionStatus.Unanswered;
                selectedQuestions.push(q);
            }
        });

        if (selectedQuestions.length) {
            triggerSaveQuestionsToReview(selectedQuestions);
        }
    }

    const handleFileFilterOpen = (open: boolean) => {
        setFileFilterOpen(open);
    }

    const handleFileChange = (file: QuestionFileModel) => {
        setLoading(true);
        setSelectedFile(file);
        triggerGetQuestions({
            projectId: props.projectId,
            importId: file.importId
        });
    }

    const onDownloadClick = () => {
        if (!fileDownloadUrlResult.isLoading && selectedFile && selectedFile.fileName && selectedFile.fileDisplayName) {
            triggerFileDownloadUrl({
                fileGuid: selectedFile.fileName,
                fileName: selectedFile.fileDisplayName
            });
        }
    }

    const renderFileStatus = (file: QuestionFileModel) => {
        let status = QuestionFileStatusDetail[file.statusId];
        if (file.statusId === QuestionFileStatus.Pending || file.statusId === QuestionFileStatus.InProgress) {
            status = QuestionFileStatusDetail[QuestionFileStatus.InProgress];
            return <div className="progress" role="progressbar"
                aria-label={status.name}
                aria-valuenow={100}
                aria-valuemin={0}
                aria-valuemax={100}
                style={{ height: "20px", minWidth: '70px' }}>
                <div className="progress-bar progress-bar-striped progress-bar-animated bg-warning w-100">
                    {status.name}
                </div>
            </div>
        }
        return <Tag className='w-100 text-center' color={status.color}>{status.name}</Tag>
    }

    useEffect(() => {
        if (!fileDownloadUrlResult.isLoading && fileDownloadUrlResult.data?.data)
            downloadFileSilently(fileDownloadUrlResult.data.data, selectedFile?.fileDisplayName || "");
    }, [fileDownloadUrlResult])

    useEffect(() => {
        if (questionFilesSelector.length && (!selectedFile || totalFiles < questionFilesSelector.length)) {
            handleFileChange(questionFilesSelector[0]);
            setTotalFiles(questionFilesSelector.length);
            fileStatusCheckCount.current = 0;
        }
    }, [questionFilesSelector])

    // Polling to get status of pending files.
    useEffect(() => {

        let intervalId = setInterval(() => {
            
            if (questionFilesSelector.length && !getQuestionsResult.isLoading && 
                fileStatusCheckCount.current < 15 && !getQuestionFilesResult.isLoading &&
                questionFilesSelector.some(x => x.statusId === QuestionFileStatus.InProgress || x.statusId === QuestionFileStatus.Pending)) {
                    
                triggerGetQuestionFiles({ projectId: props.projectId }, false)
                    .then(response => {

                        let files = response.data?.data || [];

                        if (files.length) {
                            // Update only pending files
                            let pendingFiles = questionFilesSelector.filter(x => x.statusId === QuestionFileStatus.InProgress || x.statusId === QuestionFileStatus.Pending);
                            let updateFiles: QuestionFileModel[] = [];
                            
                            _.forEach(files, file => {
                                if (pendingFiles.some(x => x.importId === file.importId && x.statusId !== file.statusId)) {
                                    updateFiles.push(file);
                                    
                                    // Update selected file and pull questions
                                    if(selectedFile && selectedFile.importId === file.importId){
                                        handleFileChange(file);
                                    }
                                }
                            })
                            
                            if (updateFiles.length) {
                                dispatch(updateQuestionFiles(
                                    {
                                        files: cloneDeep(updateFiles),
                                        replace: false
                                    }
                                ));
                            }
                        }

                        fileStatusCheckCount.current += 1;
                    })
            }
        }, Number(process.env.REACT_APP_ANSWER_JOB_RETRY_MILISECONDS || 5000));

        return () => clearInterval(intervalId); // Remove subscription on unmount
    }, [questionFilesSelector, selectedFile, getQuestionsResult, getQuestionsResult]);

    useEffect(() => {
        if (getQuestionsResult.requestId && !getQuestionsResult.isFetching) {
            let _questions = cloneDeep(getQuestionsResult.data || []);
            setQuestions(_questions);
            setSelectedRowKeys([]);
            setExpandedRowKeys([]);
            setLoading(false);

            // Update Status of File if Pending or In-Progress
            if (_questions.length > 0 &&
                (selectedFile?.statusId === QuestionFileStatus.InProgress || selectedFile?.statusId === QuestionFileStatus.Pending)) {

                let _selectedFile = cloneDeep(selectedFile);
                _selectedFile.statusId = QuestionFileStatus.UnderReview;
                _selectedFile.totalUnreviewedQuestion = _selectedFile.totalQuestion = _questions.length;

                setSelectedFile(_selectedFile);
                dispatch(
                    updateQuestionFiles({
                        files: [_selectedFile],
                        replace: true
                    })
                );
            }
        }
    }, [getQuestionsResult])

    useEffect(() => {
        setLoading(saveQuestionsToReviewResult.isLoading);

        if (saveQuestionsToReviewResult.requestId && !saveQuestionsToReviewResult.isLoading) {

            if (saveQuestionsToReviewResult.data?.data) {

                updateFile(saveQuestionsToReviewResult.data.data);

                let questionIds = new Set(selectedRowKeys.map(x => Number(x)));

                let selected = _.remove(questions, x => questionIds.has(x.questionId));
                dispatch(addSectionQuestions({ projectId: props.projectId, questions: selected }));

                notification.success({
                    message: "Question Added",
                    description: `${selected.length} Question(s) added to Project`,
                    placement: "topRight"
                });

                setQuestions([...questions]);
                setSelectedRowKeys([]);
            }
        }

    }, [saveQuestionsToReviewResult])

    return (
        <Spin spinning={loading || deleteQuestionsUnreviewedResult.isLoading}>

            <Card className='mb-3'>
                <Row>
                    <Col sm={12} md={12} lg={7}>

                        <Space.Compact block>
                            <Popover
                                trigger="click"
                                open={fileFilterOpen}
                                onOpenChange={handleFileFilterOpen}
                                placement='bottomLeft'
                                content={
                                    <Table
                                        size='small'
                                        pagination={false}
                                        dataSource={questionFilesSelector}
                                        rowClassName='fs-7 cursor-pointer'
                                        rowKey={(record: QuestionFileModel) => record.importId.toString()}
                                        rowSelection={{
                                            type: 'radio',
                                            selectedRowKeys: selectedFile ? [selectedFile.importId.toString()] : [],
                                            onChange: (selectedRowKeys: React.Key[], selectedRows: QuestionFileModel[]) => {
                                                if (selectedRows.length)
                                                    handleFileChange(selectedRows[0])
                                                setFileFilterOpen(false);
                                            }
                                        }}
                                        onRow={(record) => ({
                                            onClick: () => {
                                                handleFileChange(record);
                                                setFileFilterOpen(false);
                                            }
                                        })}
                                    >
                                        <Column title="File"
                                            dataIndex="fileDisplayName"
                                            key="fileDisplayName"
                                            render={(fileDisplayName: string) => fileDisplayName.split('.').slice(0, -1).join('.')}
                                        />
                                        <Column title="Section" dataIndex="sectionName" key="sectionName" />
                                        <Column
                                            title="Status"
                                            dataIndex="statusId"
                                            key="statusId"
                                            render={(statusId: number, record: QuestionFileModel) => renderFileStatus(record)}
                                        />
                                    </Table>
                                }
                            >
                                <Button type='primary' ghost className='d-flex w-100 align-items-center gap-2'>
                                    <div className='text-start text-truncate flex-grow-1'>
                                        {selectedFile ? selectedFile.fileDisplayName?.split('.').slice(0, -1).join('.') : "Select a File to review"}
                                    </div>
                                    {
                                        selectedFile && <div>
                                            {renderFileStatus(selectedFile)}
                                        </div>
                                    }
                                    <div><DownOutlined /></div>
                                </Button>
                            </Popover>
                            <Tooltip title="Download">
                                <Button type='primary' ghost
                                    icon={<DownloadOutlined />}
                                    onClick={onDownloadClick}
                                    loading={fileDownloadUrlResult.isLoading}
                                    disabled={selectedFile === null}
                                />
                            </Tooltip>
                        </Space.Compact>
                    </Col>
                    <Col sm={12} md={12} lg={5} className='text-end'>
                        <Space size='small'>
                            <Button type='primary'
                                ghost
                                icon={<AppstoreAddOutlined />}
                                onClick={onAddMultiple}
                                disabled={selectedRowKeys.length === 0}
                            >
                                Add to Project
                            </Button>
                            <Popconfirm
                                title="Delete"
                                description="Are you sure to delete selected questions?"
                                onConfirm={() => onDeleteQuestions()}
                                okText="Yes"
                                cancelText="No"
                                disabled={selectedRowKeys.length === 0}>
                                <Button danger icon={<DeleteOutlined />}
                                    disabled={selectedRowKeys.length === 0}>
                                    Delete
                                </Button>
                            </Popconfirm>
                        </Space>
                    </Col>
                </Row>
            </Card>

            {
                selectedFile !== null &&
                <Card
                    title={
                        <h2 className='mt-5 text-wrap'>
                            <div className="fw-bold mb-1">Review Questions</div>
                            <span className="text-gray-700 mt-1 fw-normal fs-6">
                                The following questions were identified in the submitted data. Please review them. You can edit or delete the questions. Once complete, add the questions to the project.
                            </span>
                        </h2>
                    }
                >
                    <Table rowKey={record => record.questionId}
                        rowSelection={{
                            selectedRowKeys: selectedRowKeys,
                            onChange: onSelectChange,
                        }}
                        size="small"
                        columns={columns}
                        dataSource={questions}
                        expandable={{
                            expandedRowKeys: expandedRowKeys,
                            expandedRowRender: (record, index, indent, expanded) => expanded ?
                                <QuestionEditRenderer data={record} onClose={onQuestionEditClose} /> : null,
                            showExpandColumn: false,
                            expandedRowClassName: () => "bg-gray-200"
                        }}
                        pagination={false}
                        locale={{
                            emptyText: <>
                                {
                                    questions.length === 0 &&
                                    (selectedFile?.statusId === QuestionFileStatus.Pending || selectedFile?.statusId === QuestionFileStatus.InProgress) &&
                                    <div className='py-15 bg-gray-200'>
                                        <h4 className='text-primary'><LoadingOutlined /> We are currently processing the request for extracting questions.</h4>
                                        <p className='fs-6 text-body'>Please wait a moment or click the 'Refresh' button to get the latest status update.</p>
                                        <Button type='primary' ghost onClick={() => handleFileChange(selectedFile)}>Refresh</Button>
                                    </div>
                                }
                                {
                                    !getQuestionsResult.isFetching && questions.length === 0 &&
                                    (selectedFile?.statusId === QuestionFileStatus.UnderReview || selectedFile?.statusId === QuestionFileStatus.Completed) &&
                                    <div className='py-15 bg-gray-200'>
                                        <h4 className='text-primary'>All questions already reviewed.</h4>
                                    </div>
                                }
                            </>
                        }}
                    />
                </Card>
            }
        </Spin>
    )
}

export { ReviewQuestions };

const QuestionEditRenderer: React.FC<{
    data: QuestionModel,
    onClose: (data?: QuestionModel | null, file?: QuestionFileModel | null) => void
}> = (props) => {
    const [form] = Form.useForm<QuestionModel>();
    const [loading, setLoading] = useState(false);
    const { notification } = App.useApp();
    const [selectedQuestion, setSelectedQuestion] = useStateWithCallback(_.cloneDeep(props.data));
    const [triggerSaveQuestionsToReview, saveQuestionsToReviewResult] = useSaveQuestionsToReviewMutation();

    const onFinishFailed = (errorInfo: any) => {
        notification.error({
            message: "Validation Error",
            description: "Please fix highlighted errors",
            placement: "topRight"
        })
    }

    const onFinish = (values: QuestionModel) => {
        const _model = {
            ...selectedQuestion,
            ...values,
        }

        _model.responseFormat.forEach(x => {
            if (x.responseFormatId !== ResponseFormat.Custom) {
                x.customOptions = null;
            }
        });

        setSelectedQuestion(_model, () => triggerSaveQuestionsToReview([_model]));
    }

    const onSave = () => {
        selectedQuestion.statusId = QuestionStatus.Unreviewed;
        setSelectedQuestion(selectedQuestion, form.submit);
    }

    const onSaveAdd = () => {
        selectedQuestion.statusId = QuestionStatus.Unanswered;
        setSelectedQuestion(selectedQuestion, form.submit);
    }

    useEffect(() => {
        if (!selectedQuestion.responseFormat) {
            selectedQuestion.responseFormat = [{ responseFormatId: 1, customOptions: [''] }];
            setSelectedQuestion(selectedQuestion);
            form.setFieldValue("responseFormat", selectedQuestion.responseFormat);
        }
    }, [])

    useEffect(() => {
        setLoading(saveQuestionsToReviewResult.isLoading);

        if (saveQuestionsToReviewResult.requestId && !saveQuestionsToReviewResult.isLoading) {

            if (saveQuestionsToReviewResult.data?.success) {
                props.onClose(selectedQuestion, saveQuestionsToReviewResult.data?.data);
            }
        }

    }, [saveQuestionsToReviewResult]);

    return (
        <Spin spinning={loading}>
            <Form className='p-3'
                layout="vertical"
                form={form}
                name="SingleQuestion"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                initialValues={selectedQuestion}
            >
                <Form.Item name="question" label="Question"
                    rules={[{ required: true, message: "'${label}' is required" }]}>
                    <Input.TextArea autoSize
                        placeholder="Enter a single question."
                        style={{ minHeight: '55px' }}
                    />
                </Form.Item>
                <Row>
                    <Col sm={6}>
                        <Form.Item name="questionNumber" label="Question No."
                            rules={[{ required: true, message: "'${label}' is required" }]}>
                            <Input placeholder="Enter question number" />
                        </Form.Item>
                    </Col>
                    <AddEditQuestionCommonControls form={form} hideSections={true} />
                </Row>
                <Form.Item className="text-end mb-0">
                    <Space>
                        <Button onClick={() => props.onClose()}>
                            Cancel
                        </Button>
                        <Button type="primary" onClick={onSave}>
                            Save
                        </Button>
                        <Button type="primary" onClick={onSaveAdd}>
                            Save & Add to Project
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </Spin>
    )
}