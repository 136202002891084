import { CopyOutlined, DeleteOutlined, EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { GridOptions, ICellRendererParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { App, Button, Card, Space, Spin, Tooltip } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useCopyPricingInstructionMutation, useDeletePricingInstructionMutation, useLazyGetPricingInstructionListQuery } from '../../../redux/rtkquery/PricingApi';
import { IsSuperAdmin } from '../../common/CommonFunctions';
import { PricingInstructionListItemModel } from '../../models';
import { useAuth } from '../auth';

const Pricings: React.FC = () => {
    const navigate = useNavigate();
    const { modal } = App.useApp();
    const { user_metadata } = useAuth();
    const [triggerGetPricingInstructionList] = useLazyGetPricingInstructionListQuery();
    const [triggerDeletePricingInstruction] = useDeletePricingInstructionMutation();
    const [triggerCopyPricingInstruction, copyPricingInstructionResult] = useCopyPricingInstructionMutation();
    const gridRef = useRef<AgGridReact<PricingInstructionListItemModel>>(null);
    const { companyId: _companyId } = useParams();
    const companyId = _companyId && IsSuperAdmin(user_metadata?.roles) ? Number(_companyId) : user_metadata?.companyId ?? 0;

    const fetchInstructionList = async () => {
        const response = await triggerGetPricingInstructionList({ companyId }, false);
        gridRef.current?.api.updateGridOptions({ rowData: response.data || [] });
    }

    const onDeleteClick = (params: any) => {

        modal.confirm({
            title: "Confirm deletion",
            content: <>
                <p>Are you sure you would like to delete Price Book "{params.data?.instructionName}"?</p>
                <p className='text-danger fw-bold'>This action cannot be undone.</p>
            </>,
            okText: "Delete",
            okButtonProps: { danger: true },
            cancelText: "No",
            onOk: () => onDeletePricingInstruction(params)
        });
    }

    const onCopyClick = (params: any) => {

        modal.confirm({
            title: "Confirm Copy",
            content: <>
                <p>Are you sure you would like to copy Price Book "{params.data?.instructionName}"?</p>
            </>,
            okText: "Copy",
            cancelText: "No",
            onOk: () => onCopyPricingInstruction(params)
        });

    }
    
    const onDeletePricingInstruction = async (params: any) => {
        if (params?.data) {
            let response = await triggerDeletePricingInstruction({ companyId, id: params.data.id });

            if ('data' in response && response.data.success) {
                params.api.applyTransaction({ remove: [params.data] });
            }
        }
    }

    const onCopyPricingInstruction = async (params: any) => {
        if (params?.data) {
            let response = await triggerCopyPricingInstruction({ companyId, id: params.data.id });

            if ('data' in response && response.data.success && response.data.data) {
                params.api.applyTransaction({ addIndex: (params.node.rowIndex ?? 0) + 1, add: [response.data.data] });
            }
        }
    }

    const gridOptions: GridOptions<PricingInstructionListItemModel> = {
        defaultColDef: {
            sortable: true,
            filter: true,
            resizable: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            autoHeight: true,
            suppressMovable: true,
            suppressAutoSize: true,
            suppressHeaderFilterButton: true,
            onCellClicked: (params) => {
                navigate(`/settings/price-book/${companyId}/${params.data?.id}`)
            },
        },
        columnDefs:
            [
                {
                    field: 'instructionName',
                    headerName: 'Name',
                    flex: 1,
                    //wrapText: true,
                    cellClass: 'text-primary'
                },
                {
                    field: 'isActive',
                    headerName: 'Active',
                    width: 105,
                    cellRenderer: (params: ICellRendererParams<PricingInstructionListItemModel>) => params.data?.isActive ? "Yes" : "No"
                },
                {
                    field: 'createdBy',
                    headerName: 'Created By',
                    width: 140,
                },
                {
                    field: 'createdDate',
                    width: 125,
                    headerName: 'Created Date',
                    valueFormatter: (params) => dayjs(params.value).format("MM/DD/YYYY")
                },
                {
                    field: 'modifiedBy',
                    headerName: 'Modified By',
                    width: 140,
                },
                {
                    field: 'modifiedDate',
                    width: 125,
                    headerName: 'Modified Date',
                    valueFormatter: (params) => dayjs(params.value).format("MM/DD/YYYY")
                },
                {
                    headerName: 'Actions',
                    width: 110,
                    suppressHeaderMenuButton: true,
                    resizable: false,
                    pinned: 'right',
                    onCellClicked: () => { }, // do nothing
                    cellRenderer: (params: ICellRendererParams<PricingInstructionListItemModel>) => (
                        <Space>
                            <Tooltip title="Edit Price Book">
                                <Button size='small'
                                    onClick={() => navigate(`/settings/price-book/${companyId}/${params.data?.id}`)}
                                    icon={<EditOutlined  />}
                                />
                            </Tooltip>
                            <Tooltip title="Delete Price Book">
                                <Button size='small'
                                    onClick={() => onDeleteClick(params)}
                                    icon={<DeleteOutlined  />}
                                />
                            </Tooltip>
                            <Tooltip title="Copy Price Book">
                                <Button size='small'
                                    onClick={() => onCopyClick(params)}
                                    icon={<CopyOutlined  />}
                                />
                            </Tooltip>
                        </Space>
                    )
                }
            ],
        rowData: null,
        domLayout: 'autoHeight',
        onGridReady: (params) => {
            params.api.sizeColumnsToFit()
            fetchInstructionList();
        },
        onFirstDataRendered: () => {
            gridRef.current?.api.sizeColumnsToFit();
        }
    }

    useEffect(() => {

        const handleResize = () => {
            gridRef.current?.api?.sizeColumnsToFit();
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])

    return (
        <Spin spinning={copyPricingInstructionResult.isLoading}>
            <Card title={<h2 className='m-0'>Price Books</h2>}
            styles={{
                body: { minHeight: 'calc(100vh - 200px)' }
            }}
                extra={
                    <Link to={`/settings/price-book/${companyId}/0`}>
                        <Button type="primary"
                            icon={<PlusCircleOutlined  />}>
                            Add New
                        </Button>
                    </Link>
                }
            >
                <div className="ag-theme-alpine ag-theme-alpine-custom h-90 w-100">
                    <AgGridReact
                        ref={gridRef}
                        gridOptions={gridOptions}
                    />
                </div>
            </Card>
        </Spin>
    )
}

export { Pricings };

