import { ReloadOutlined } from '@ant-design/icons';
import { Button, Form, Space, Spin } from "antd";
import dayjs from 'dayjs';
import Handlebars from "handlebars";
import _, { cloneDeep } from "lodash";
import { useEffect, useRef } from "react";
import { useFinalPricingGeneratorMutation, useSetRequestStatusMutation } from "../../../redux/rtkquery/PricingApi";
import FullScreenModal from "../../common/components/FullScreenModal";
import HtmlEditor from "../../common/components/HtmlEditor";
import { PricingRequestStatus } from "../../common/Enums";
import { PricingRequestModel, PricingResponseModel } from "../../models";

export const FinalizePricing: React.FC<{
    response: PricingResponseModel,
    onClose: (model?: PricingRequestModel | null) => void,
    isFinalSubmit: boolean
}> = (props) => {

    const [form] = Form.useForm<PricingResponseModel>();
    const editorRef = useRef<any>(null);
    const [triggerPricingGenerator, pricingGeneratorResult] = useFinalPricingGeneratorMutation();
    const [triggerSetRequestStatus, setRequestStatusResult] = useSetRequestStatusMutation();

    const onFinish = async (values: PricingResponseModel) => {
        if (!_.trim(editorRef.current.getContent({ format: "text" }))) {
            form.setFieldValue("response", null);
            form.validateFields();
            return;
        }

        let response = await triggerSetRequestStatus({
            pricingRequestId: props.response.pricingRequestId,
            statusId: props.isFinalSubmit ? PricingRequestStatus.Final : PricingRequestStatus.Submitted,
            finalizedResponse: values.response,
            comment: null
        });

        if ('data' in response && response.data.success && response.data.data) {
            props.onClose(response.data.data);
        }
    }

    const generateResponse = (maxRetry = 1) => {

        if (maxRetry <= 0) return
        else --maxRetry

        form.setFieldValue("response", "");

        triggerPricingGenerator({
            pricingRequestId: props.response.pricingRequestId,
            pricingInstructionId: props.response.pricingInstructionId,
            _additionalMeta: { "ignoreErrorLog": maxRetry > 0 ? "1" : "0" }   // ignore rtk error logger
        }).then(response => {
            if ('data' in response && response.data.success && response.data.data) {
                let pricingData = cloneDeep(response.data.data.pricingData ?? {});

                pricingData.quoteID = props.response.pricingRequestId;
                pricingData.customerName = props.response.customerName;
                pricingData.currentDate =  dayjs().format("MM/DD/YYYY");

                const template = Handlebars.compile(response.data.data.format);
                let html = template(pricingData);

                form.setFieldValue("response", html);
            }
            else if ('error' in response) {
                generateResponse(maxRetry)
            }
        });
    }

    useEffect(() => generateResponse(2), []);

    return (
        <FullScreenModal title="Final Quote"
            open={true}
            maskClosable={false}
            keyboard={false}
            closable={false}
            width={800}
            destroyOnClose={true}
            footer={
                <div className="d-flex justify-content-between">
                    <div>
                        <Button type="link"
                            size='small'
                            icon={<ReloadOutlined />}
                            onClick={() => generateResponse(2)}
                            hidden={pricingGeneratorResult.isLoading}
                            disabled={setRequestStatusResult.isLoading}
                        >
                            Didn't work? Regenerate it.
                        </Button>
                    </div>
                    <Space>
                        <Button
                            disabled={setRequestStatusResult.isLoading}
                            onClick={(e) => props.onClose()}>
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            onClick={form.submit}
                            loading={setRequestStatusResult.isLoading}
                            disabled={pricingGeneratorResult.isLoading}>
                            Save Changes
                        </Button>
                    </Space>
                </div>
            }
        >
            <Spin spinning={pricingGeneratorResult.isLoading || setRequestStatusResult.isLoading}>

                <div className="text-gray-600 mb-8 fs-4">
                    Finalize the quote for this request.
                </div>

                <Form
                    layout="vertical"
                    form={form}
                    name="EditResponse"
                    onFinish={onFinish}
                    autoComplete="off"
                    initialValues={props}
                >
                    <Form.Item name="response" label=''
                        rules={[{ required: true, message: "'Final Quote' is required" }]}>
                        <HtmlEditor setEditorRef={(editor: any) => editorRef.current = editor} />
                    </Form.Item>
                </Form>
            </Spin>
        </FullScreenModal>
    )
}