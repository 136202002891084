import {
    CheckOutlined, CopyOutlined, DeleteOutlined, EditOutlined, ExceptionOutlined, MoreOutlined, BulbTwoTone,
    FileTextOutlined, FileWordOutlined, HistoryOutlined, InfoCircleOutlined, SendOutlined,
    PrinterOutlined
} from '@ant-design/icons';
import { App, Button, Card, Dropdown, Input, List, MenuProps, Modal, notification, Popconfirm, Popover, Skeleton, Space, Tag, Tooltip, UploadFile } from 'antd';
import _, { cloneDeep } from 'lodash';
import Markdown from 'markdown-to-jsx';
import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../redux/hooks';
import { RTKBaseApi } from '../../../redux/rtkquery/BaseApi';
import {
    useDeletePricingAttachmentMutation,
    useDeletePricingRequestMutation,
    useDeletePricingResponseMutation,
    useGetPricingInstructionSelectListQuery,
    useGetSuggestionsMutation,
    useLazyGetPricingRequestQuery,
    usePricingStreamMutation, useSavePricingRequestMutation, useSetRequestStatusMutation
} from '../../../redux/rtkquery/PricingApi';
import { useGetUserSelectListQuery } from '../../../redux/rtkquery/UserApi';
import { getFormattedDate, getShortDisplayName, HasAnyRole, IsPricingAdmin, processLaTex } from '../../common/CommonFunctions';
import AttachmentTag from '../../common/components/AttachmentTag';
import FileUpload from '../../common/components/FileUpload';
import useCopyToClipboard from '../../common/components/useCopyToClipboard';
import useWindowResize from '../../common/components/useWindowResize';
import useWordExport from '../../common/components/useWordExport';
import { CONST_DATETIME_UI_FORMAT } from '../../common/Constants';
import { ActionType, ExportTemplateType, PricingRequestQuoteStatus, PricingRequestStatus, PricingRequestStatusDetails, QuoteFinalStatus, Roles, RTKQueryTags } from '../../common/Enums';
import { ActiveUserModel, APIResponseModel, PricingDocumentModel, PricingRequestModel, PricingResponseModel } from '../../models';
import { useAuth } from '../auth';
import { FinalizePricing } from './FinalizePricing';
import { PricingRequestEdit } from './PricingRequestEdit';
import TeX from '@matejmazur/react-katex'
import { useReactToPrint } from 'react-to-print';

const { confirm } = Modal;

const PricingRequest: React.FC = () => {
    const navigate = useNavigate();
    const { user_metadata } = useAuth();
    const { id: requestId } = useParams();
    const dispatch = useAppDispatch();
    const { modal } = App.useApp();
    const parentRef = useRef<null | HTMLDivElement>(null);
    const listRef = useRef<null | any>(null);
    const footerRef = useRef<null | any>(null);
    const finalResponseRef = useRef<any>();
    const allPricingInstructionSelectList = useGetPricingInstructionSelectListQuery();
    const allUserList = useGetUserSelectListQuery();
    const isPricingAdmin = IsPricingAdmin(user_metadata?.roles);
    const [triggerDeleteRequest] = useDeletePricingRequestMutation();
    const [triggerDeleteAttachment] = useDeletePricingAttachmentMutation();
    const [triggerDeleteResponse] = useDeletePricingResponseMutation();
    const [triggerSaveRequest, saveRequestResult] = useSavePricingRequestMutation();
    const [triggerGetPricingRequest, getPricingRequestResult] = useLazyGetPricingRequestQuery();
    const [triggerPricingGenerator, pricingGeneratorResult] = usePricingStreamMutation();
    const [triggerGetSuggestions] = useGetSuggestionsMutation();
    const [triggerSetRequestStatus, setRequestStatusResult] = useSetRequestStatusMutation();
    const [suggestions, setSuggestions] = useState<string[]>([]);
    const [query, setQuery] = useState("");
    const [showHistory, setShowHistory] = useState(false);
    const [finalResponse, setFinalResponse] = useState<PricingResponseModel | null>(null);
    const [pricingResponses, setPricingResponses] = useState<PricingResponseModel[]>([]);
    const [pricingDocuments, setPricingDocuments] = useState<PricingDocumentModel[]>([]);
    const [pricingDetailModalOpen, setPricingDetailModalOpen] = useState(false);
    const [allUserListDict, setAllUserListDict] = useState<Record<number, ActiveUserModel>>({});
    const { isCopied, copyToClipboard } = useCopyToClipboard();
    const { isExporting, exportToWord } = useWordExport();
    const [pricingDetail, setPricingDetail] = useState<PricingRequestModel>({
        pricingRequestId: 0,
        requestName: "New Quote",
        pricingInstructionId: 0,
        pricingInstructionName: "",
        price: 0,
        quantity: 0,
        statusId: PricingRequestStatus.Drafted,
        createdById: user_metadata?.userId || 0,
        team: [user_metadata?.userId || 0],
        approver: []
    });

    const onEditPricingDetailClick = () => {
        setPricingDetailModalOpen(true);
    }

    const onPricingDetailModalClose = async (_pricingRequest?: PricingRequestModel | null) => {

        if (_pricingRequest) {
            await savePricingDetail(_pricingRequest);
        }

        setPricingDetailModalOpen(false);
    }

    const savePricingDetail = async (_pricingRequest: PricingRequestModel) => {
        let response = await triggerSaveRequest(_pricingRequest);

        if ('data' in response && response.data.success) {
            const _request = cloneDeep(response.data?.data);

            if (_request) {
                if (_pricingRequest.customerId === 0)
                    dispatch(RTKBaseApi.util.invalidateTags([RTKQueryTags.CustomerSelectList]));

                if (_pricingRequest.defaultInstruction)
                    dispatch(RTKBaseApi.util.invalidateTags([RTKQueryTags.PricingInstructionSelectList]));

                if (_pricingRequest.pricingRequestId === 0)
                    navigate(`/quotes/${_request.pricingRequestId}`, { replace: true });

                setPricingDetail({ ..._request });
                setChatContainerHeight(100);

                return _request;
            }
        }

        return null;
    }

    const onFinalPricingModalClose = (_pricingDetail?: PricingRequestModel | null) => {

        if (_pricingDetail) {
            setPricingDetail({ ..._pricingDetail });

            let finalResponse = getFinalResponseModel(_pricingDetail);

            setPricingResponses([finalResponse, ...pricingResponses]);
            setChatContainerHeight(100);
        }

        setFinalResponse(null);
    }

    const onQueryChange = (e: any) => {
        setQuery(e.target.value)
    }

    const onQueryEnter = async () => {

        if (allPricingInstructionSelectList.isFetching ||
            (saveRequestResult.isLoading && saveRequestResult.originalArgs?.pricingRequestId === 0)) {
            return;
        }

        let value = _.trim(query);

        if (!value) {
            notification.info({
                message: "Validation!!",
                description: "Please enter text in the Query box at the bottom to proceed, then click Send.",
                placement: "topRight"
            })
            return;
        }

        let _pricingDetail: PricingRequestModel | null = pricingDetail;

        if (pricingDetail.pricingRequestId === 0) {
            pricingDetail.requestName = _.truncate(query, { length: 50 });
            _pricingDetail = await savePricingDetail(pricingDetail);

            if (!_pricingDetail) return;

            setPricingDetail({ ..._pricingDetail });
        }

        let newResponse: PricingResponseModel = {
            pricingResponseId: 0,
            pricingRequestId: _pricingDetail.pricingRequestId,
            pricingInstructionId: _pricingDetail.pricingInstructionId,
            query: query,
            response: "",
            createdById: user_metadata?.userId,
            createdBy: `${user_metadata?.firstName} ${user_metadata?.lastName}`
        }

        setSuggestions([]);
        setQuery("");

        generatePricing(newResponse, 2)

        scrollToChat(0);
    }

    const generatePricing = (responseModel: PricingResponseModel, maxRetry = 1) => {

        if (maxRetry <= 0) return
        else --maxRetry

        // Add in the list to show processing
        setPricingResponses([...pricingResponses, responseModel]);

        triggerPricingGenerator({
            data: {
                query: responseModel.query,
                pricingRequestId: responseModel.pricingRequestId,
                pricingInstructionId: responseModel.pricingInstructionId
            },
            _additionalMeta: { "ignoreErrorLog": maxRetry > 0 ? "1" : "0" },   // ignore rtk error logger
            onChunk: onPricingGenerateComplete,
            onEnd: onPricingStreamEnded
        }).then(response => {
            if ('error' in response) {
                // Remove the last item
                setPricingResponses((responses) => {
                    return responses.filter(x => x.pricingResponseId)
                })

                generatePricing(responseModel, maxRetry)
            }
        })
    }

    const onPricingGenerateComplete = (chunk: string, responseId: number) => {

        // Update the last item
        setPricingResponses((responses) => {
            let lastResponse = responses[responses.length - 1];
            lastResponse.response += chunk;
            return [...responses]
        });
    }

    const onPricingStreamEnded = (responseId: number) => {

        if (!responseId) return;

        setPricingResponses((responses) => {
            let lastResponse = responses[responses.length - 1];
            lastResponse.response = processLaTex(lastResponse.response ?? "")
            lastResponse.pricingResponseId = responseId;
            return [...responses]
        });

        let last2Responses = _.slice(_.cloneDeep(pricingResponses), -2);

        if (last2Responses.length > 0) {
            triggerGetSuggestions(last2Responses)
                .then(response => {

                    let suggestions: string[] = [];

                    if (response && 'data' in response && response.data.data?.length) {
                        suggestions = response.data.data;
                    }

                    setSuggestions(suggestions);
                })
        }
    }

    const onFileUploadComplete = (file: UploadFile<APIResponseModel<PricingDocumentModel>>) => {
        let _doc = file.response?.data;
        if (_doc) {
            if (pricingDetail.pricingRequestId === 0) {

                pricingDetail.pricingRequestId = _doc.pricingRequestId;
                pricingDetail.createdBy = _doc.createdBy;
                pricingDetail.createdDate = _doc.createdDate;
                navigate(`/quotes/${pricingDetail.pricingRequestId}`, { replace: true });
            }

            pricingDetail.modifiedBy = _doc.createdBy;
            pricingDetail.modifiedDate = _doc.createdDate;

            setPricingDetail(pricingDetail);

            pricingDocuments.push(_doc);
            setPricingDocuments([...pricingDocuments]);
        }
    }

    const onFileRemove = async (file: PricingDocumentModel) => {

        let removedFiles = _.remove(pricingDocuments, x => x.attachmentId === file.attachmentId);
        setPricingDocuments([...pricingDocuments]);

        let response = await triggerDeleteAttachment({
            attachmentId: file.attachmentId,
            pricingRequestId: file.pricingRequestId
        }).unwrap();

        if (!response || !response.success) {
            pricingDocuments.push(removedFiles[0]);
            setPricingDocuments([...pricingDocuments]);
        }
    }

    const onDeleteChat = async (pricingResponseId: number) => {
        setSuggestions([]);

        let response = await triggerDeleteResponse({
            pricingReponseId: pricingResponseId,
            pricingRequestId: pricingDetail.pricingRequestId
        }).unwrap();

        if (response && response.success) {
            let responses = _.filter(pricingResponses, x => x.pricingResponseId < pricingResponseId)
            setPricingResponses([...responses]);
        }
    }

    const onRequestChanges = () => {
        let comment = _.trim(pricingDetail.comment || '');

        confirm({
            title: 'Request Changes',
            icon: null,
            okText: 'Submit',
            okButtonProps: { type: "primary" },
            cancelText: 'Cancel',
            cancelButtonProps: { disabled: setRequestStatusResult.isLoading },
            width: 600,
            content: <>
                <div className='mb-2'>Suggest changes to implement:</div>
                <Input.TextArea rows={4}
                    defaultValue={comment}
                    className='w-100'
                    placeholder='Enter Comments (Optional)'
                    onChange={input => comment = input.target.value}
                />
            </>,
            async onOk() {
                comment = _.trimEnd(comment, '\n');
                await setRequestStatus(PricingRequestStatus.RequestChanges, comment);
            }
        })
    }

    const onApprovedClick = () => {
        if (isFinalizedMode) {
            setRequestStatus(PricingRequestStatus.Final);
        }
    }

    const setRequestStatus = async (status: PricingRequestStatus, comment = "", finalizedResponse = "") => {
        let response = await triggerSetRequestStatus({
            pricingRequestId: pricingDetail.pricingRequestId,
            statusId: status,
            comment,
            finalizedResponse
        });

        if ('data' in response && response.data.success && response.data.data) {
            setPricingDetail({ ...response.data.data });

            if (status === PricingRequestStatus.Drafted) {
                setShowHistory(false);
                setPricingResponses(_.filter(pricingResponses, x => !x.isFinalResponse))
            }

            setChatContainerHeight(100);
        }
    }

    const onEditClick = () => {
        if (isFinalizedMode) {
            setRequestStatus(PricingRequestStatus.Drafted);
        }
    }

    const onCreateQuoteClick = () => {
        setFinalResponse({
            customerName: pricingDetail.customerName,
            pricingResponseId: 0,
            pricingRequestId: pricingDetail.pricingRequestId,
            pricingInstructionId: pricingDetail.pricingInstructionId,
            query: "Generate Final Response",
            response: null
        });
    }

    const getFinalResponseModel = (_pricingRequest: PricingRequestModel) => {
        let finalResponse: PricingResponseModel = {
            pricingResponseId: -1,
            isFinalResponse: true,
            pricingRequestId: _pricingRequest.pricingRequestId,
            pricingInstructionId: _pricingRequest.pricingInstructionId,
            query: "Final Quote",
            response: _pricingRequest.finalizedResponse || null,
            createdBy: _pricingRequest.finalizedBy,
            createdDate: _pricingRequest.finalizedDate
        };

        return finalResponse;
    }

    const onFinalResponseInWordExport = async () => {
        exportToWord(ExportTemplateType.PricingFinalPrice, pricingDetail.requestName || "", {
            finalPrice: pricingDetail.finalizedResponse || ""
        });
    }

    const onFinalResponseInClipboardExport = async () => {
        if (listRef.current) {
            const responseElement: HTMLDivElement = listRef.current.querySelector('[id=chat_response_-1]');
            if (responseElement) {
                copyToClipboard({ copiedText: responseElement.innerText || "", copiedHTML: responseElement.innerHTML || "" });
            }
        }
    }

    const renderUserNames = useCallback((ids: number[] | null, defaultText: string) => {

        let names = _.transform(ids || [], (r: ReactNode[], v: number) => {
            let user = allUserListDict[v];
            if (user) {
                let msg = "", fullName: string | ReactNode = user.fullName;

                if (!user.isActive)
                    msg = "Inactive";
                else if (_.intersection(user.roles, [Roles.PricingAdmin, Roles.PricingMember]).length === 0)
                    msg = "Lacks required permissions";

                if (msg)
                    fullName = <>{fullName} <span className='text-danger'>({msg})</span></>;

                r.push(fullName);
            }
        })

        names = names.length ? names : [defaultText]

        return names.map((item, index) => (
            <span key={index} className="me-1">
                {item}
                {index < names.length - 1 && ','}
            </span>
        ))

    }, [allUserListDict]);

    const pricingStatusClassName = useMemo(() => {
        let colorClass = "text-gray-700";

        switch (pricingDetail.statusId) {
            case PricingRequestStatus.RequestChanges:
                colorClass = "text-warning";
                break;
            case PricingRequestStatus.Submitted:
                colorClass = "text-primary";
                break;
            case PricingRequestStatus.Final:
                colorClass = "text-success";
                break;
        }

        return colorClass;

    }, [pricingDetail]);

    const pricingActions = useMemo(() => {
        let menus = [{
            label: 'Edit Quote',
            key: 'edit-quote',
            icon: <EditOutlined />
        }];

        if (pricingDetail.pricingRequestId > 0 && pricingDetail.createdById === user_metadata?.userId) {
            menus.push({
                label: 'Delete Quote',
                key: 'delete-quote',
                icon: <DeleteOutlined />
            })
        }

        return menus;

    }, [pricingDetail]);

    const onPricingActionsClick: MenuProps['onClick'] = ({ key }) => {
        switch (key) {
            case 'edit-quote':
                onEditPricingDetailClick();
                break;
            case 'delete-quote':
                modal.confirm({
                    title: "Confirm deletion",
                    content: <>
                        <p>Are you sure you would like to delete this quote?</p>
                        <p className='text-danger fw-bold'>This action cannot be undone.</p>
                    </>,
                    okText: "Delete",
                    okButtonProps: { danger: true },
                    cancelText: "No",
                    onOk: onDeletePricingRequest
                });
                break;
        }
    }

    const onDeletePricingRequest = async () => {
        let response = await triggerDeleteRequest({ id: pricingDetail.pricingRequestId });

        if ('data' in response && response.data.success) {
            navigate('/quotes/list', { replace: true });
        }
    }

    const pricingHeader = useMemo(() => (
        <>
            <div className='d-flex'>

                <h2 className='m-0 flex-grow-1'>
                    {pricingDetail.requestName}
                    <Button type="link" icon={<EditOutlined />} onClick={onEditPricingDetailClick} />
                </h2>

                <Dropdown arrow placement="bottomRight"
                    menu={{
                        items: pricingActions,
                        onClick: onPricingActionsClick
                    }}>
                    <Button icon={<MoreOutlined />} />
                </Dropdown>

            </div>
            <div className='flex-list'>
                <div className='flex-item'>
                    <div className='fs-8 text-gray-600'>Price Book</div>
                    <div className='fs-6'>
                        {
                            allPricingInstructionSelectList.isFetching ?
                                <Skeleton.Button active block size='small' style={{ width: '120px' }} /> :
                                <Button type="link" className='p-0 h-auto'
                                    onClick={onEditPricingDetailClick}>
                                    {pricingDetail.pricingInstructionName}
                                </Button>
                        }
                    </div>
                </div>

                <div className='flex-item'>
                    <div className='fs-8 text-gray-600'>Customer</div>
                    <div className='fs-6'>
                        <Button type="link" className='p-0 h-auto'
                            onClick={onEditPricingDetailClick}>
                            {pricingDetail.customerName || "Add Customer"}
                        </Button>
                    </div>
                </div>

                <div className='flex-item'>
                    <div className='fs-8 text-gray-600'>Team</div>
                    <div className='fs-6'>
                        <Button type="link" className='p-0 h-auto'
                            onClick={onEditPricingDetailClick}>
                            {renderUserNames(pricingDetail.team, "Add Team")}
                        </Button>
                    </div>
                </div>

                <div className='flex-item'>
                    <div className='fs-8 text-gray-600'>Approver</div>
                    <div className='fs-6'>
                        <Button type="link" className='p-0 h-auto'
                            onClick={onEditPricingDetailClick}>
                            {renderUserNames(pricingDetail.approver, "Add Approver")}
                        </Button>
                    </div>
                </div>

                <div className='flex-item'>
                    <div className='fs-8 text-gray-600'>Status</div>
                    <div className={'fs-6'}>
                        <Space>
                            <span className={pricingStatusClassName}>
                                {PricingRequestStatusDetails[pricingDetail.statusId]?.name}
                            </span>
                            {
                                (pricingDetail.comment?.length ?? 0) > 0 &&
                                <Popover trigger="hover"
                                    placement='left'
                                    content={
                                        <>
                                            <div className='mb-3'>{pricingDetail.comment}</div>
                                            <div className='text-end fs-7 text-gray-700'>
                                                <span>By: </span>
                                                <span className="fw-bold">{pricingDetail.commentBy} </span>
                                                on {getFormattedDate(pricingDetail.commentDate || "", CONST_DATETIME_UI_FORMAT)}
                                            </div>
                                        </>
                                    }

                                    title={PricingRequestStatusDetails[pricingDetail.statusId]?.name}>
                                    <InfoCircleOutlined className='cursor-pointer' />
                                </Popover>
                            }
                        </Space>
                    </div>
                </div>
                {
                    (pricingDetail.statusId) == 4 &&
                    <div className='flex-item'>
                        <div className='fs-8 text-gray-600'>Final Status</div>
                        <div className={'fs-6'}>
                            <span className={`${pricingDetail?.quoteStatus === QuoteFinalStatus.Won
                                ? 'text-green'
                                    : pricingDetail?.quoteStatus === QuoteFinalStatus.Lost ? 'text-red'
                                    :""
                                    }`}>
                                    <Button type="link" className='p-0 h-auto'
                                        onClick={onEditPricingDetailClick}>
                                        {pricingDetail?.quoteStatus === QuoteFinalStatus.Won ? "Won" :
                                            pricingDetail?.quoteStatus === QuoteFinalStatus.Lost ? "Lost" : "Not Set"}
                                    </Button>
                                    
                            </span>
                        </div>
                    </div>
                }
            </div>

            {
                pricingDetail.pricingRequestId > 0 &&
                <div className='flex-list mt-2'>
                    {
                        pricingDetail.createdDate &&
                        <div className='flex-item'>
                            <div className='fs-8 text-gray-400'>
                                Created: {getFormattedDate(pricingDetail.createdDate, CONST_DATETIME_UI_FORMAT)}
                            </div>
                        </div>
                    }
                    {
                        pricingDetail.modifiedDate &&
                        <div className='flex-item'>
                            <div className='fs-8 text-gray-400'>
                                Last Modified: {getFormattedDate(pricingDetail.modifiedDate, CONST_DATETIME_UI_FORMAT)}
                            </div>
                        </div>
                    }
                </div>
            }
        </>
    ), [pricingDetail]);

    const isFinalizedMode = useMemo(() => {
        return pricingDetail.statusId !== PricingRequestStatus.Drafted
    }, [pricingDetail.statusId]);

    const setChatContainerHeight = (timeout: number = 0) => {
        setTimeout(() => {
            if (listRef.current && footerRef.current) {
                // Using Set syntax to get latest updated state of Price Detail
                // as updated state doesn't reflect in setTimeout method
                setPricingDetail(_pricingDetail => {
                    if (_pricingDetail.statusId === PricingRequestStatus.Drafted) {
                        let footerHeight = _.toNumber(footerRef.current.clientHeight) + 255;
                        listRef.current.style.height = `calc(100vh - ${footerHeight}px)`;
                    }
                    else
                        listRef.current.style.height = 'auto';
                    return _pricingDetail;
                })
            }
        }, timeout);
    }

    const scrollToChat = (chatId: number) => {
        if (listRef.current) {
            setTimeout(() => {
                let chatElement = listRef.current.querySelector(`[id=chat_${chatId}]`);
                chatElement && chatElement.scrollIntoView({ behavior: 'smooth' });
            }, 10);
        }
    }

    const initChatRefs = (chatId?: number) => {
        setTimeout(() => {
            footerRef.current = parentRef.current?.getElementsByClassName("ant-list-footer")[0] || null;
            listRef.current = parentRef.current?.getElementsByClassName("ant-spin-container")[0] || null;

            setChatContainerHeight(0);
            chatId && scrollToChat(chatId);
        }, 100);
    }

    useWindowResize(() => setChatContainerHeight(10));

    useEffect(setChatContainerHeight, [footerRef.current?.clientHeight]);

    useEffect(() => {
        let id = Number(requestId);

        if (!_.isNaN(id) && id > 0) {
            triggerGetPricingRequest({ id: id, expand: 1 }, false)
                .then(response => {

                    let chatId = 0;

                    if (response && 'data' in response) {
                        const _request = response.data?.data;

                        if (_request && _request.pricingRequest) {
                            let _pricingRequest = cloneDeep(_request.pricingRequest);
                            let _pricingResponses = cloneDeep(_request.pricingResponse || []);
                            let _pricingDocuments = cloneDeep(_request.pricingDocument || []);

                            _pricingResponses.forEach(x => {
                                x.response = processLaTex(x.response ?? "");
                            });

                            setPricingDetail(_pricingRequest);
                            setPricingDocuments(_pricingDocuments);

                            if (_pricingRequest.statusId !== PricingRequestStatus.Drafted) {
                                let finalResponse = getFinalResponseModel(_pricingRequest);
                                finalResponse.response = processLaTex(finalResponse.response ?? "");

                                _pricingResponses = [finalResponse, ..._pricingResponses];
                            }

                            setPricingResponses(_pricingResponses);

                            if (_pricingResponses.length) {
                                chatId = _pricingResponses[_pricingResponses.length - 1].pricingResponseId;
                            }
                        }
                        else {
                            notification.warning({
                                message: "Quote details not found",
                                description: 'Quote you are looking might be deleted or not accessible.',
                                placement: "topRight"
                            });

                            navigate('/quotes');
                        }
                    }

                    initChatRefs(chatId);
                });
        }
        else {
            initChatRefs();
        }
    }, []);

    useEffect(() => {
        let id = Number(requestId);

        if (_.isNaN(id) && pricingDetail.pricingRequestId > 0) {
            navigate('/quotes');
            setTimeout(() => {
                navigate('/quotes/new-quote', { replace: true });
            }, 10)
        }
    }, [requestId, pricingDetail]);

    useEffect(() => {
        if (pricingDetail.pricingRequestId === 0 && allPricingInstructionSelectList.data && allUserList.data) {

            let instruction = user_metadata?.defaultPriceBookId ?
                _.find(allPricingInstructionSelectList.data, x => x.id === user_metadata?.defaultPriceBookId) :
                _.first(allPricingInstructionSelectList.data);

            let approvers = user_metadata?.pricing_approver_ids ?? [],
                team = user_metadata?.pricing_member_ids ?? [];

            // Keep current user in team as default
            let defaultTeam: number[] = [user_metadata?.userId ?? 0],
                defaultApprovers: number[] = [];

            // Keep only active and eligible members in team and approvers default list
            if (approvers.length || team.length) {
                _.forEach(allUserList.data, u => {
                    if (u.isActive && HasAnyRole(u.roles, [Roles.PricingAdmin, Roles.PricingMember])) {
                        if (approvers.length && approvers.indexOf(u.userId) > -1)
                            defaultApprovers.push(u.userId);
                        if (team.length && team.indexOf(u.userId) > -1)
                            defaultTeam.push(u.userId);
                    }
                });
            }

            // Do this way so it can pick latest data from state
            setPricingDetail((detail) => ({
                ...detail,
                pricingInstructionId: instruction?.id ?? 0,
                pricingInstructionName: instruction?.instructionName ?? "",
                approver: defaultApprovers,
                team: defaultTeam
            }))
        }
    }, [allPricingInstructionSelectList, pricingDetail, allUserList]);

    useEffect(() => {
        if (!allUserList.isFetching) {
            setAllUserListDict(_.transform(allUserList.data || [], (r: Record<number, ActiveUserModel>, v: ActiveUserModel) => r[v.userId] = v));
        }
    }, [allUserList]);

    const handleKeyDown = (event: any) => {
        if ((event.ctrlKey || event.metaKey) && event.key === 'Enter') {
            onQueryEnter();
        }
    }

    const onTagSelect = (tag: string) => {
        setQuery(tag);
    }

    const handlePrint = useReactToPrint({
        contentRef: finalResponseRef,
        documentTitle: "Final Quote"
    })

    return (
        <>
            {
                getPricingRequestResult.isFetching ?
                    <Card className='shadow-sm'
                        styles={{ header: { paddingTop: '15px' } }}
                        title={<Skeleton active paragraph={{ rows: 1 }} />}>
                        <Skeleton active avatar paragraph={{ rows: 5 }} />
                    </Card> :
                    <div ref={parentRef}>
                        <List
                            className='pricing-view'
                            itemLayout="vertical"
                            size="large"
                            dataSource={pricingResponses}
                            header={pricingHeader}
                            renderItem={(item) => (
                                <List.Item key={item.pricingResponseId}
                                    id={`chat_${item.pricingResponseId}`}
                                    className={isFinalizedMode && !item.isFinalResponse ? "history-chat-item" : (item.isFinalResponse ? "final-chat-item" : "")}
                                    hidden={
                                        (showHistory === false && !item.isFinalResponse && isFinalizedMode)
                                    }>
                                    <List.Item.Meta
                                        avatar={
                                            <span className="text-avatar">{getShortDisplayName(item.createdBy || "User")}</span>
                                        }
                                        title={
                                            <div className='d-flex justify-content-between'>
                                                <div className='fs-7 text-gray-600 fw-normal'>{item.createdBy}</div>
                                                {
                                                    item.isFinalResponse && isFinalizedMode &&
                                                    <Space.Compact size='small'>
                                                        <Tooltip title="Copy to Clipboard">
                                                            <Button icon={<CopyOutlined />}
                                                                className={isCopied ? "btn-disabled" : ""}
                                                                onClick={onFinalResponseInClipboardExport}>
                                                                {isCopied ? "Copied" : "Copy"}
                                                            </Button>
                                                        </Tooltip>
                                                        <Tooltip title="Export to Word">
                                                            <Button icon={<FileWordOutlined />}
                                                                loading={isExporting}
                                                                onClick={onFinalResponseInWordExport}>
                                                                Word
                                                            </Button>
                                                        </Tooltip>
                                                        <Tooltip title="Print">
                                                            <Button icon={<PrinterOutlined />}
                                                                onClick={() => handlePrint()}>
                                                                Print
                                                            </Button>
                                                        </Tooltip>
                                                    </Space.Compact>
                                                }
                                                {
                                                    !item.isFinalResponse && !isFinalizedMode && item.pricingResponseId > 0 &&
                                                    <Popconfirm
                                                        title="Remove the conversation"
                                                        description="Are you sure to remove the conversation starting from this point?"
                                                        onConfirm={() => onDeleteChat(item.pricingResponseId)}
                                                        okText="Yes"
                                                        cancelText="No"
                                                        placement='left'
                                                    >
                                                        <Tooltip title="Delete conversation from this point">
                                                            <Button className='p-0 h-auto' danger type='link' icon={<DeleteOutlined />} />
                                                        </Tooltip>
                                                    </Popconfirm>
                                                }
                                            </div>
                                        }
                                        description={<h4>{item.query}</h4>}
                                    />
                                    <div className='ant-item-content'>
                                        <div className='rfpn-meta-avatar'>
                                            <span className="text-avatar rfpn-avatar" />
                                        </div>
                                        <div className='flex-grow-1'>
                                            {
                                                item.pricingResponseId === 0 && item.response === "" && pricingGeneratorResult.isLoading ?
                                                    <Skeleton active /> :
                                                    (
                                                        <>
                                                            <div className='fs-7 text-gray-600 fw-normal'>RFP Ninja</div>
                                                            {
                                                                item.response === "" || item.response === null ? "NO RESPONSE" :
                                                                    <div className='html-box' ref={item.isFinalResponse && isFinalizedMode ? finalResponseRef : null}>
                                                                        <Markdown
                                                                            id={`chat_response_${item.pricingResponseId}`}
                                                                            children={item.response}
                                                                            options={{
                                                                                renderRule(next, node, renderChildren, state) {
                                                                                    if (node.type === "5" || node.type === "3") {
                                                                                        return (
                                                                                            <TeX as="span" key={state.key} settings={{ output: 'mathml', displayMode: false }}>{String.raw`${node.text}`}</TeX>
                                                                                        )
                                                                                    }

                                                                                    return next()
                                                                                },
                                                                            }}
                                                                        />
                                                                    </div>
                                                            }
                                                        </>
                                                    )
                                            }
                                        </div>
                                    </div>
                                </List.Item>
                            )}
                            footer={
                                <>
                                    {
                                        suggestions.length > 0 &&
                                        <div className='mb-2 text-end'>
                                            <Tooltip title="Suggestions to response.">
                                                <BulbTwoTone className='text-primary me-2' />
                                            </Tooltip>
                                            {
                                                _.map(suggestions, (x, i) => (
                                                    <Tag key={i} color='blue'
                                                        className='cursor-pointer'
                                                        onClick={() => onTagSelect(x)}>
                                                        {x}
                                                    </Tag>
                                                ))
                                            }
                                        </div>
                                    }
                                    {
                                        !isFinalizedMode &&
                                        <Input.TextArea autoSize
                                            value={query}
                                            onChange={onQueryChange}
                                            placeholder='Enter quote request or follow-up questions. Press Ctrl/Command + Enter to submit the request.'
                                            onKeyDown={handleKeyDown}
                                        />
                                    }

                                    <div className='d-flex'>
                                        <div className='flex-grow-1'>
                                            {
                                                isFinalizedMode &&
                                                <Button ghost type='primary' size='large'
                                                    className='bg-white'
                                                    icon={<HistoryOutlined />}
                                                    onClick={() => setShowHistory(!showHistory)}>
                                                    {showHistory ? "Hide History" : "Show History"}
                                                </Button>
                                            }

                                            {/* {
                                                !isFinalizedMode &&
                                                <FileUpload size='large'
                                                    compact={true}
                                                    label='Attach Files'
                                                    maxCount={Number.MAX_SAFE_INTEGER}
                                                    keepUploadedFiles={false}
                                                    uploadUrl='pricing/save_attachment'
                                                    allowedFileTypes={[".pdf", ".jpg", ".jpeg", ".png"]}
                                                    disabled={pricingGeneratorResult.isLoading || allPricingInstructionSelectList.isFetching}
                                                    onUploadComplete={(file) => onFileUploadComplete(file)}
                                                    data={{
                                                        pricingRequestId: pricingDetail.pricingRequestId,
                                                        pricingInstructionId: pricingDetail.pricingInstructionId,
                                                        action: ActionType.Insert
                                                    }}
                                                />
                                            } */}

                                            {
                                                pricingDocuments.length > 0 &&
                                                _.map(pricingDocuments, x => (
                                                    <AttachmentTag key={x.attachmentId}
                                                        closable={!isFinalizedMode}
                                                        fileName={x.fileDisplayName}
                                                        fileGuid={x.fileName || ""}
                                                        onClose={() => onFileRemove(x)}
                                                    />
                                                ))
                                            }
                                        </div>
                                        <Space>
                                            {
                                                pricingResponses.length > 0 && !isFinalizedMode &&
                                                <Button ghost type='primary' size='large'
                                                    className='bg-white'
                                                    icon={<FileTextOutlined />}
                                                    onClick={onCreateQuoteClick}
                                                    disabled={pricingGeneratorResult.isLoading || allPricingInstructionSelectList.isFetching}>
                                                    Create Quote
                                                </Button>
                                            }

                                            {
                                                !isFinalizedMode &&
                                                <Button type='primary' size='large'
                                                    icon={<SendOutlined />}
                                                    onClick={onQueryEnter}
                                                    disabled={allPricingInstructionSelectList.isFetching ||
                                                        (saveRequestResult.isLoading && saveRequestResult.originalArgs?.pricingRequestId === 0)
                                                    }
                                                    loading={pricingGeneratorResult.isLoading}>
                                                    Send
                                                </Button>
                                            }

                                            {
                                                pricingDetail.statusId === PricingRequestStatus.Submitted &&
                                                pricingDetail.modifiedById !== user_metadata?.userId &&
                                                (isPricingAdmin || _.some(pricingDetail.approver, x => x === user_metadata?.userId)) &&
                                                <>
                                                    <Button type='default' size='large'
                                                        className='btn-warning-outline'
                                                        icon={<ExceptionOutlined />}
                                                        onClick={onRequestChanges}>
                                                        Request Changes
                                                    </Button>

                                                    <Button type='default' size='large'
                                                        className='btn-success-outline'
                                                        icon={<CheckOutlined />}
                                                        onClick={onApprovedClick}
                                                        loading={setRequestStatusResult.isLoading && setRequestStatusResult.originalArgs?.statusId === PricingRequestStatus.Final}>
                                                        Approve
                                                    </Button>
                                                </>
                                            }

                                            {
                                                isFinalizedMode &&
                                                <Button type='primary' size='large'
                                                    icon={<EditOutlined />}
                                                    onClick={onEditClick}
                                                    loading={setRequestStatusResult.isLoading && setRequestStatusResult.originalArgs?.statusId === PricingRequestStatus.Drafted}>
                                                    Edit
                                                </Button>
                                            }

                                        </Space>
                                    </div>
                                </>
                            }
                        />
                    </div>
            }

            {
                pricingDetailModalOpen && pricingDetail &&
                <PricingRequestEdit
                    onClose={onPricingDetailModalClose}
                    pricingRequest={pricingDetail}
                    enablePricingInstruction={pricingResponses.length === 0}
                />
            }

            {
                finalResponse &&
                <FinalizePricing
                    response={finalResponse}
                    onClose={onFinalPricingModalClose}
                    isFinalSubmit={
                        _.size(pricingDetail.approver) === 0 ||
                        _.some(pricingDetail.approver, x => x === user_metadata?.userId)
                    }
                />
            }
        </>
    )
}

export { PricingRequest };

