import { App, Button, InputNumber, Space, Spin, Switch, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import _, { cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";
import { useLazyUserRoleLimitQuery, useSaveUserRoleLimitMutation } from "../../../redux/rtkquery/CompanyApi";
import { IsSuperAdmin } from "../../common/CommonFunctions";
import { CompanyRoleLimitModal } from "../../models";
import { useAuth } from "../auth";

type ComponentProps = {
    companyId: number,
    onCancel: () => void
}

const UserRoleLimit: React.FC<ComponentProps> = (props) => {
    const { user_metadata } = useAuth();
    const { notification } = App.useApp();
    const [roles, setRoles] = useState<CompanyRoleLimitModal[]>([]);
    const [triggerUserRoleLimit, userRoleLimitResult] = useLazyUserRoleLimitQuery();
    const [triggerSaveUserRoleLimit, saveUserRoleLimitResult] = useSaveUserRoleLimitMutation();
    const isSuperAdmin = IsSuperAdmin(user_metadata?.roles);

    const columns: ColumnsType<CompanyRoleLimitModal> = [
        {
            title: 'Role',
            dataIndex: 'roleName',
            key: 'roleName',
            sorter: false
        },
        {
            title: 'Total Assigned',
            dataIndex: 'totalUsers',
            key: 'totalUsers',
            align: 'center',
            sorter: false
        },
        {
            title: 'User Limit',
            dataIndex: 'userLimit',
            key: 'userLimit',
            align: 'center',
            width: 200,
            sorter: false,
            render: (x, record) => isSuperAdmin ?
                <Space>
                    <InputNumber min={-1}
                        disabled={(record.userLimit ?? 0) < 0}
                        value={(record.userLimit ?? 0) >= 0 ? record.userLimit : null}
                        onBlur={(e) => onLimitChange(_.toNumber(e.target.value ?? 0), record.roleId)}
                    />
                    <Switch
                        checkedChildren="Limited"
                        unCheckedChildren="No Limit"
                        checked={(record.userLimit ?? 0) >= 0}
                        onChange={(checked) => onLimitCheckChange(checked, record.roleId)}
                    />
                </Space> :
                ((record.userLimit ?? 0) < 0 ? "No Limit" : record.userLimit)
        }
    ]

    const saveChanges = async () => {
        if (isSuperAdmin) {

            for (let role of roles) {
                if (_.isNil(role.userLimit)) {
                    notification.warning({
                        message: "Validation",
                        description: `Please enter user limit value for "${role.roleName}" Role.`,
                        placement: "topRight"
                    });
                    return;
                }

                // When total assigned active users are more than assigned user limit. 
                // UserLimit = -1 means NO LIMIT.
                if ((role.totalUsers ?? 0) > 0 && role.userLimit >= 0 && (role.totalUsers ?? 0) > role.userLimit) {
                    notification.warning({
                        message: "Validation",
                        description: `Please enter User Limit value >= ${role.totalUsers} for "${role.roleName}" Role.`,
                        placement: "topRight"
                    });
                    return;
                }
            }

            const response = await triggerSaveUserRoleLimit({ companyId: props.companyId, roles });

            if ('data' in response && response.data.success) {
                notification.success({
                    message: "Success",
                    description: "Changes saved successfully",
                    placement: "topRight"
                });
                props.onCancel();
            }
        }
    }

    const onLimitCheckChange = (checked: boolean, roleId: number) => {

        let role = roles.find(x => x.roleId === roleId);

        if (role) {
            role.userLimit = checked ? 0 : -1;
            setRoles([...roles])
        }
    }

    const onLimitChange = (limit: number, roleId: number) => {

        let role = roles.find(x => x.roleId === roleId);

        if (role) {
            role.userLimit = limit >= -1 ? limit : 0;
            setRoles([...roles])
        }
    }

    useEffect(() => {
        triggerUserRoleLimit({ companyId: props.companyId })
            .then(response => {
                if (response.data && response.data.success) {
                    let roles = cloneDeep(response.data.data ?? []);

                    _.forEach(roles, r => {
                        r.totalUsers = r.totalUsers ?? 0;
                        r.userLimit = r.userLimit ?? 0;
                    })

                    setRoles(roles)
                }
            })
    }, []);

    return (
        <Spin spinning={userRoleLimitResult.isFetching || saveUserRoleLimitResult.isLoading}>
            <Table rowKey={"userId"}
                className="mb-8"
                size="small"
                columns={columns}
                dataSource={roles}
                pagination={{ hideOnSinglePage: true }}
            />

            {
                isSuperAdmin &&
                <div className="text-end">
                    <Space>
                        <Button size="large" onClick={props.onCancel}>Cancel</Button>
                        <Button type="primary" size="large" onClick={saveChanges}>Save Changes</Button>
                    </Space>
                </div>
            }
        </Spin>
    )
};

export { UserRoleLimit };

