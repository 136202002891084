import { useIntl } from 'react-intl'
import { IsCollaborator, IsCompanyAdmin, IsPricingAdmin, IsPricingMember, IsProjectAdmin, IsProjectOwner, IsSuperAdmin } from '../../../../../app/common/CommonFunctions'
import { useAuth } from '../../../../../app/modules/auth'
import { SidebarMenuItem } from './SidebarMenuItem'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'

const SidebarMenuMain = () => {
    const intl = useIntl()
    const { user_metadata } = useAuth();
    return (
        <>
            <SidebarMenuItem
                to='/dashboard'
                title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
                icon='/media/icons/duotune/art/art002.svg'
            />

            {
                (IsProjectAdmin(user_metadata?.roles) || IsProjectOwner(user_metadata?.roles) || IsCollaborator(user_metadata?.roles)) &&
                <SidebarMenuItemWithSub
                    to='/projects'
                    title='Projects'
                    icon='/media/icons/duotune/general/gen005.svg'
                >
                    {
                        (IsProjectAdmin(user_metadata?.roles) || IsProjectOwner(user_metadata?.roles)) &&
                        <SidebarMenuItem to='/projects/add-project' title='Add Project' hasBullet={true} />
                    }
                    <SidebarMenuItem to='/projects/list' title='Projects' hasBullet={true} />
                </SidebarMenuItemWithSub>
            }

            {
                (IsPricingAdmin(user_metadata?.roles) || IsPricingMember(user_metadata?.roles)) &&
                <SidebarMenuItemWithSub
                    to='/quotes'
                    title='Quotes'
                    icon='/media/icons/duotune/finance/fin010.svg'
                >
                    {
                        <SidebarMenuItem to='/quotes/new-quote' title='New Quote' hasBullet={true} />
                    }
                    <SidebarMenuItem to='/quotes/list' title='Quotes' hasBullet={true} />
                </SidebarMenuItemWithSub>
            }

            {
                (IsPricingAdmin(user_metadata?.roles) || IsCompanyAdmin(user_metadata?.roles)) &&
                <SidebarMenuItemWithSub
                    to='/settings'
                    title='Settings'
                    icon='/media/icons/duotune/coding/cod001.svg'
                >
                    {
                        IsCompanyAdmin(user_metadata?.roles) &&
                        <>
                            <SidebarMenuItem to='/settings/setup/about' title='Company Setup' hasBullet={true} />
                            <SidebarMenuItem to='/settings/users' title='Users' hasBullet={true} />
                            <SidebarMenuItem to='/settings/library/list' title='Library' hasBullet={true} />
                            <SidebarMenuItem to='/settings/library/edit-requests' title='Library Edit Requests' hasBullet={true} />
                        </>
                    }
                    {
                        IsPricingAdmin(user_metadata?.roles) &&
                        <SidebarMenuItem to={`/settings/price-book/${user_metadata?.companyId}`} title='Price Books' hasBullet={true} />
                    }
                </SidebarMenuItemWithSub>
            }

            {
                IsSuperAdmin(user_metadata?.roles) &&
                <SidebarMenuItemWithSub
                    to='/admin'
                    title='Admin'
                    icon='/media/icons/duotune/general/gen049.svg'
                >
                    <SidebarMenuItem to='/admin/companies' title='Company List' hasBullet={true} />
                </SidebarMenuItemWithSub>
            }
        </>
    )
}

export { SidebarMenuMain }

