import { DeleteOutlined } from '@ant-design/icons';
import { GridApi, GridOptions, ICellRendererParams, IGetRowsParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { App, Button, Card, Input, Select, Space, Tooltip } from 'antd';
import { cloneDeep } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useGetDatasetSelectListQuery } from '../../../../redux/rtkquery/DatasetApi';
import { useDeleteResponseHistoryMutation, useGetResponseHistoryMutation } from '../../../../redux/rtkquery/QuestionApi';
import { filterOptions, getDatasetGroups, getFormattedDate } from "../../../common/CommonFunctions";
import useWindowResize from '../../../common/components/useWindowResize';
import { CONST_DATE_FORMAT } from '../../../common/Constants';
import { NinjaHistoryFilterModel, NinjaHistoryItemModel } from '../../../models';
import { NinjaGenerator } from './NinjaGenerator';

const NinjaGeneratorHistory: React.FC = () => {
    const { modal } = App.useApp();
    const allDatasetListState = useGetDatasetSelectListQuery();
    const [triggerGetResponseHistory, getHistoryResult] = useGetResponseHistoryMutation();
    const [triggerDeleteHistory, deleteHistoryResult] = useDeleteResponseHistoryMutation();
    const defaultFilters: NinjaHistoryFilterModel = {
        question: "",
        datasetId: null,
        responseFormatId: null,
        currentPage: 1,
        offset: 0,
        totalRecords: 0,
        pageSize: 10
    }

    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [selectedItem, setSelectedItem] = useState<NinjaHistoryItemModel | null>(null);
    const [filters, setFilters] = useState<NinjaHistoryFilterModel>(cloneDeep(defaultFilters));
    const [gridApi, setGridApi] = useState<GridApi<NinjaHistoryItemModel> | null>(null);
    const gridFilters = useRef<NinjaHistoryFilterModel>(cloneDeep(defaultFilters));

    const onQuestionChange = (e: any) => {
        setFilters(prev => ({
            ...prev,
            question: e.target.value
        }))
    }

    const onDatasetChange = (value: number | null) => {
        setFilters(prev => ({
            ...prev,
            datasetId: value
        }))
    }

    const onApplyFilters = (e: any, newFilters: NinjaHistoryFilterModel | null = null) => {
        e?.preventDefault();
        newFilters ??= filters;
        let _filters = gridFilters.current;
        _filters.question = newFilters.question;
        _filters.datasetId = newFilters.datasetId;
        _filters.totalRecords = 0;

        gridApi?.purgeInfiniteCache();
    }

    const onResetFilters = (e: any) => {
        let newFilters = cloneDeep(defaultFilters);
        setFilters(newFilters);
        onApplyFilters(null, newFilters);
    }

    const onDelete = async (params: any) => {
        if (params?.data) {

            params.api.updateGridOptions({ loading: true });
            let response = await triggerDeleteHistory({ id: params.data.id });

            if ('data' in response && response.data.success) {
                let _filters = gridFilters.current;
                _filters.totalRecords -= 1;
                params.api.refreshInfiniteCache();
            }
            else {
                params.api.updateGridOptions({ loading: false });
            }
        }
    }

    const onDeleteAll = async () => {

        modal.confirm({
            title: "Confirm deletion",
            content: <>
                <p>Are you sure would like to delete entire history?</p>
                <p className='text-danger fw-bold'>This action cannot be undone.</p>
            </>,
            okText: "Delete",
            okButtonProps: { danger: true },
            cancelText: "No",
            onOk: () => deleteHistory()
        });
    }

    const deleteHistory = async () => {
        gridApi?.updateGridOptions({ loading: true });

        let response = await triggerDeleteHistory({ id: null });

        if ('data' in response && response.data.success) {
            let _filters = gridFilters.current;
            _filters.totalRecords = 0;
            gridApi?.refreshInfiniteCache();
        }
        else {
            gridApi?.updateGridOptions({ loading: false });
        }
    }

    const datasetGroups = useMemo(() => {
        return getDatasetGroups(allDatasetListState.data || [])
    }, [allDatasetListState]);

    const filterOption = useCallback(filterOptions, [allDatasetListState]);

    const gridOptions: GridOptions<NinjaHistoryItemModel> = {
        defaultColDef: {
            sortable: false,
            filter: true,
            resizable: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            autoHeight: true,
            suppressMovable: true,
            suppressAutoSize: true,
            sortingOrder: ['asc', 'desc'],
            suppressHeaderFilterButton: true,
            onCellClicked: (params) => setSelectedItem(params.data ?? null)
        },
        columnDefs:
            [
                {
                    field: 'question',
                    headerName: 'Query',
                    flex: 1,
                    cellClass: "text-primary"
                },
                {
                    field: 'datasetName',
                    headerName: 'Dataset',
                    width: 105,
                    valueFormatter: (params) => params.value ?? "Default"
                },
                {
                    field: 'createdDate',
                    headerName: 'Created On',
                    width: 160,
                    valueFormatter: (params) => getFormattedDate(params.value, CONST_DATE_FORMAT)
                },
                {
                    headerName: '',
                    width: 60,
                    suppressSizeToFit: true,
                    suppressHeaderMenuButton: true,
                    resizable: false,
                    pinned: 'right',
                    onCellClicked: () => { }, // do nothing
                    cellRenderer: (params: ICellRendererParams<NinjaHistoryItemModel>) => (
                        <Tooltip title="Delete">
                            <Button size='small'
                                onClick={() => onDelete(params)}
                                icon={<DeleteOutlined />}
                            />
                        </Tooltip>
                    )
                }
            ],
        context: gridFilters,
        rowData: null,
        domLayout: 'autoHeight',
        pagination: true,
        paginationPageSize: 10,
        paginationPageSizeSelector: [10, 20, 30],
        cacheBlockSize: 10,
        infiniteInitialRowCount: 0,
        rowModelType: 'infinite',
        onGridReady: (params) => {
            params.api.sizeColumnsToFit();
            setGridApi(params.api);
        },
        onFirstDataRendered: () => {
            gridApi?.sizeColumnsToFit();
        }
    }

    useWindowResize(() => gridApi?.sizeColumnsToFit());

    useEffect(() => {
        if (gridApi) {
            const dataSource = {
                getRows: (params: IGetRowsParams) => {

                    let pageSize = gridApi.paginationGetPageSize();
                    const page = params.startRow > 0 ? Math.floor(params.endRow / pageSize) : 1,
                        _filters = gridFilters.current;

                    triggerGetResponseHistory({
                        question: _filters.question,
                        datasetId: _filters.datasetId,
                        responseFormatId: null,
                        pageSize: pageSize,
                        totalRecords: _filters.totalRecords,
                        offset: params.startRow,
                        currentPage: page
                    })
                        .then(response => {
                            if ('data' in response) {
                                let data = response.data.data,
                                    totalRecords = data?.totalRecords ?? 0;

                                gridFilters.current.totalRecords = totalRecords;
                                setTotalRecords(totalRecords);
                                params.successCallback(data?.historyList || [], totalRecords);
                            }
                            else
                                params.successCallback([], 0);
                        }).catch(err => {
                            params.successCallback([], 0);
                        })
                }
            }
            gridApi.updateGridOptions({ datasource: dataSource })
        }
    }, [gridApi]);

    return (
        <>
            <Card title={<h2 className='m-0'>Ninja Generator History</h2>}
                styles={{
                    body: { minHeight: 'calc(100vh - 200px)' }
                }}>
                <form autoComplete='off'
                    onSubmit={onApplyFilters}
                    onReset={onResetFilters}
                    className='px-5 py-3 mb-5 bg-gray-100 border border-2'>
                    <fieldset disabled={deleteHistoryResult.isLoading}>
                        <Row>
                            <Col sm={6} md={4}>
                                <Input
                                    id="question"
                                    placeholder="Enter Query"
                                    onChange={onQuestionChange}
                                    value={filters.question || ""}
                                />
                            </Col>

                            <Col sm={6} md={3}>
                                <Select
                                    showSearch
                                    className='w-100'
                                    options={datasetGroups}
                                    value={filters.datasetId}
                                    onChange={onDatasetChange}
                                    filterOption={filterOption}
                                    loading={allDatasetListState.isFetching}
                                    placeholder="Dataset"
                                />
                            </Col>

                            <Col sm={6} md={3}>
                                <Space>
                                    <Button htmlType='submit' type='primary'>Apply</Button>
                                    <Button htmlType='reset'>Clear</Button>
                                </Space>
                            </Col>

                            <Col sm={6} md={2} className='text-end'>
                                <Button type='default' danger
                                    onClick={onDeleteAll}
                                    disabled={totalRecords <= 0}>
                                    Delete All
                                </Button>
                            </Col>
                        </Row>
                    </fieldset>
                </form>

                <div className="ag-theme-alpine ag-theme-alpine-custom h-90 w-100">
                    <AgGridReact
                        gridOptions={gridOptions}
                        loading={getHistoryResult.isLoading}
                    />
                </div>
            </Card>

            {
                selectedItem &&
                <NinjaGenerator
                    open={true}
                    fromHistory={true}
                    datasetId={selectedItem.datasetId}
                    responseFormatId={selectedItem.responseFormatId}
                    response={selectedItem.answer}
                    question={selectedItem.question}
                    sources={selectedItem.sources_json}
                    onClose={() => setSelectedItem(null)}
                />
            }

        </>
    )
}

export { NinjaGeneratorHistory };

