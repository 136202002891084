import { Session, User } from '@supabase/supabase-js'
import jwtDecode from 'jwt-decode'
import {
    FC,
    createContext,
    useContext, useEffect, useState
} from 'react'
import { useNavigate } from 'react-router-dom'
import { WithChildren } from '../../../../_metronic/helpers'
import { LayoutSplashScreen } from '../../../../_metronic/layout/core'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { fetchMasterData, selectMasterData } from '../../../../redux/MasterDataSlice'
import { RTKBaseApi } from '../../../../redux/rtkquery/BaseApi'
import { useLazyGetSetupConfigQuery } from '../../../../redux/rtkquery/UserApi'
import { resetStateAction } from '../../../../redux/store'
import { CompanySetupConfig, UserMetaDataModel } from '../../../models'
import { getSession, navigate2FA, supabase } from './supabaseClient'

type AuthContextProps = {
    user: User | null | undefined,
    user_metadata: UserMetaDataModel | null | undefined,
    session: Session | null | undefined,
    company_config: CompanySetupConfig | null | undefined,
    saveAuth: (session: Session | null) => void,
    saveCompanyConfig: (config: CompanySetupConfig) => void,
    logout: () => Promise<void>
}

const initAuthContextPropsState = {
    user: null,
    user_metadata: null,
    session: null,
    company_config: null,
    saveCompanyConfig: () => { },
    saveAuth: () => { },
    logout: async () => { },
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
    return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({ children }) => {
    const [session, setSession] = useState<Session | null>();
    const [companyConfig, setCompanyConfig] = useState<CompanySetupConfig | null>();
    const dispatch = useAppDispatch();

    const saveAuth = (session: Session | null) => {
        if (session) {
            // Replace user_metadata form JWT Token as it has updated one
            session.user.user_metadata = jwtDecode<User>(session.access_token).user_metadata;
            setSession(session);
        } else {
            setSession(null);
        }
    }

    const saveCompanyConfig = (config: CompanySetupConfig) => {
        setCompanyConfig(config);
    }

    const logout = async () => {
        setSession(null);
        // Clear rtk query cache
        dispatch(RTKBaseApi.util.resetApiState());
        // Clear storage
        dispatch(resetStateAction());
        // Logout from supabase auth
        await supabase.auth.signOut();
    }

    return (
        <AuthContext.Provider value={{
            session, user: session?.user,
            user_metadata: session?.user?.user_metadata as UserMetaDataModel,
            company_config: companyConfig,
            saveAuth, logout, saveCompanyConfig
        }}>
            {children}
        </AuthContext.Provider>
    )
}

const AuthInit: FC<WithChildren> = ({ children }) => {
    const { user, logout, saveAuth, saveCompanyConfig } = useAuth();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [triggerSetupConfig, setupConfigResult] = useLazyGetSetupConfigQuery();
    const masterData = useAppSelector(state => selectMasterData(state));
    const [showSplashScreen, setShowSplashScreen] = useState(true);
    const [validUser, setValidUser] = useState(false);

    const redirectToLogin = async () => {
        await logout();
        navigate('/auth/login');
        setShowSplashScreen(false);
    }

    // const redirectToResetPassword = () => {
    //     // Generate random token (no use for now) and navigate
    //     navigate(`auth/reset-password?token=${Math.random().toString(36).substring(2) + (new Date()).getTime().toString(36)}&type=recovery`);
    //     setShowSplashScreen(false);
    // }

    useEffect(() => {
        // Listen for changes on auth state (logged in, signed out, etc.)
        const { data: listener } = supabase.auth.onAuthStateChange(async (_event, _session) => {
            if (_event === "SIGNED_OUT" && user) {
                saveAuth(null);
            }
            // if (_event === "PASSWORD_RECOVERY") {
            //     saveAuth(null);
            //     redirectToResetPassword();
            // }
        });

        let { hash: hashString, search: searchString } = window.location;
        const hashParams = hashString ? new URLSearchParams(hashString.substring(1)) : null,
            searchParams = searchString ? new URLSearchParams(searchString) : null;

        // Password reset request through Hash Token Case
        if (searchParams && searchParams.get("token") && searchParams.get("type")) {
            setShowSplashScreen(false);
            // Do nothing anything else here.
        }
        // Link Expire Case
        else if (hashParams && hashParams.get("error") && hashParams.get("error_description")) {
            setShowSplashScreen(false);
            navigate('/error/common', {
                state: {
                    error_description: hashParams.get("error_description"),
                    redirect_url: '/auth/login'
                }
            });
        }
        else {
            (async () => {

                const session = await getSession();

                if (session) {
                    const result2fa = await navigate2FA(session);

                    if (result2fa.path) {
                        navigate(result2fa.path);
                        setShowSplashScreen(false);
                    }
                    else {
                        triggerSetupConfig();
                    }
                }
                else
                    await redirectToLogin();
            })();
        }

        return () => {
            listener?.subscription.unsubscribe();
        }
    }, []);

    useEffect(() => {
        if (setupConfigResult.requestId && !setupConfigResult.isLoading) {
            const config = setupConfigResult.data;

            if (config) {
                if (config.isUserActive && config.isCompanyActive) {
                    if (!masterData)
                        dispatch(fetchMasterData());
                    
                    saveCompanyConfig(config);
                    setValidUser(true);
                    return;
                }
            }
            redirectToLogin();
        }
    }, [setupConfigResult]);

    useEffect(() => {
        (async () => {
            if (masterData && validUser) {
                let session = await getSession();
                if (session) {
                    saveAuth(session);
                    setShowSplashScreen(false);
                }
            }
        })();
    }, [masterData, validUser]);

    return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export { AuthInit, AuthProvider, useAuth }

