import { Card, Spin } from "antd";
import dayjs from 'dayjs';
import _, { cloneDeep } from "lodash";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useProjectCompletedStatsMutation } from "../../../redux/rtkquery/DashboardAPI";

const ProjectCompletedStats: React.FC<{ dateRange: dayjs.Dayjs[] }> = (props) => {
  const [options, setOptions] = useState<any>(null);
  const [series, setSeries] = useState<any>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [triggerCompletedStats, completedStatsResult] = useProjectCompletedStatsMutation();

  const setMonthCategories = (dateRange: dayjs.Dayjs[]) => {
    let _categories = [];

    if (dateRange && dateRange.length) {
      let dateRanges = _.cloneDeep(dateRange);

      while (dateRanges[0].endOf('M') <= dateRanges[1].endOf('M')) {
        _categories.push(dateRanges[0].format('MMM YY'));
        dateRanges[0] = dateRanges[0].add(1, "M");
      }
    }

    setCategories(_categories);
    return _categories;
  }

  const loadStats = (dateRange: dayjs.Dayjs[]) => {
    triggerCompletedStats({
      fromDate: dateRange?.[0].format("MM/DD/YYYY") || null,
      toDate: dateRange?.[1].format("MM/DD/YYYY") || null
    });
  }

  const initOptions = (chartSeries: number[], chartCategories: string[]) => {
    setSeries([
      {
        name: 'Projects Completed',
        data: chartSeries
      }
    ]);

    setOptions({
      chart: {
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ["#304758"]
        }
      },
      plotOptions: {
        bar: {
          columnWidth: categories.length <= 3 ? '25%' : '90%',
          dataLabels: {
            position: 'top'
          },
        }
      },
      xaxis: {
        categories: chartCategories
      },
      yaxis: {
        min: 0,
        max: (_.max(chartSeries) || 0) <= 3 ? 6 : undefined,
        labels: {
          formatter: function (val: number) {
            return val.toFixed(0);
          }
        }
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left'
      }
    })
  }

  useEffect(() => {
    if (props.dateRange) {
      setMonthCategories(props.dateRange);
    }
  }, [props.dateRange])

  useEffect(() => {
    if (categories.length)
      loadStats(props.dateRange);
  }, [categories])

  useEffect(() => {
    if (completedStatsResult.requestId && !completedStatsResult.isLoading &&
      completedStatsResult.data && completedStatsResult.data.success) {

      const chartSeries: number[] = [],
        categoriesDict: Record<string, number> = {};

      let statsData = cloneDeep(completedStatsResult.data.data)

      _.forEach(statsData, x => {
        // Set Completion Month display label
        let completionOn = dayjs(`${x.completionYear}-${x.completionMonth}-01`, 'YYYY-M-D').format("MMM YY");

        // Set data grouping based on submission month
        categoriesDict[completionOn] = x.totalProject;
      });

      categories.forEach(x => chartSeries.push(categoriesDict[x] || 0));

      initOptions(chartSeries, categories);
    }
  }, [completedStatsResult]);

  return (
    <Card className="h-100">
      <Spin spinning={completedStatsResult.isLoading}>
        <div className='d-flex align-items-center justify-content-between'>
          <span className='fs-3 fw-bold text-dark me-2 lh-1'>Projects Completed</span>
        </div>

        <div className='card-body pt-2 pb-4'>
          {
            options !== null &&
            <ReactApexChart options={options}
              series={series}
              type="bar" height={'300'} width={'100%'} />
          }
        </div>
      </Spin>
    </Card>
  )
}

export { ProjectCompletedStats };

