import React from 'react';
import "tinymce";
import 'tinymce/icons/default';
import 'tinymce/models/dom/model';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/table';
import 'tinymce/plugins/image';
import 'tinymce/plugins/code';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/themes/silver';
import { Editor } from '@tinymce/tinymce-react';

const HtmlEditor: React.FC<any> = (props) => {
    return (
        <Editor
            init={{
                license_key: 'gpl' as any,
                skin: false,
                content_css: false,
                min_height: 200,
                statusbar: false,
                menubar: false,
                promotion: false,
                branding: false,
                advcode_inline: true,
                external_plugins: {
                    mergefields: '/js/tinymce-mergefields.js'
                },
                content_style: "body { font-family: 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif; font-size: 14px; } " +
                    "table td, table td { padding: 5px } .mce-offscreen-selection{ display: none }",
                plugins: [
                    'link', 'table', 'lists', 'autoresize', 'image', 'code', 'mergefields'
                ],
                toolbar_mode: 'sliding',
                toolbar:
                    `h1 h2 | bold italic underline | bullist numlist outdent indent | \
                    link table image | fontsize removeformat | \
                    alignleft aligncenter alignright alignjustify ${props.showCode ? '| code ' : ''}${props.showMergeFields ? '| mergefields ' : ''}`,
                merge_fields: props.mergeFields ?? [],
                height: 500,
                placeholder: props.placeholder || '',
                noneditable_class: 'content-nonedit',
                editable_class: 'content-edit',
                protect: [
                    /{{#each [^>]+\}}/g,
                    /{{\/each}}/g
                ]
            }}
            value={props.value}
            onEditorChange={props.onChange}
            onInit={(evt, editor) => {
                props.setEditorRef?.(editor);
            }}
        />
    );
}

export default HtmlEditor;