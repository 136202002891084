import { Card, Spin } from "antd";
import dayjs from 'dayjs';
import _ from "lodash";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useAppSelector } from "../../../redux/hooks";
import { selectMasterDataDictByType } from "../../../redux/MasterDataSlice";
import { useAnswerAutomationStatsMutation } from "../../../redux/rtkquery/DashboardAPI";
import { AnswerSourceType } from "../../common/Enums";

const AnswerSourceTypeColors: Record<string, string> = {
  [AnswerSourceType.NinjaGenerated]: '#3d569b',
  [AnswerSourceType.LibraryGenerated]: '#4fc3f7',
  [AnswerSourceType.Composed]: '#b0bec5',
  [`${AnswerSourceType.NinjaGenerated}-1`]: '#757575',
  [`${AnswerSourceType.LibraryGenerated}-1`]: '#a1887f',
}

const AutomationEfficiencyStats: React.FC<{ dateRange: dayjs.Dayjs[] }> = (props) => {
  const [options, setOptions] = useState<any>(null);
  const [series, setSeries] = useState<number[]>([]);
  const [totalQuestions, setTotalQuestions] = useState<number>(0);
  const [triggerAutomationStats, automationStatsResult] = useAnswerAutomationStatsMutation();
  const answerSourceTypeDict = useAppSelector(state => selectMasterDataDictByType(state, "AnswerSourceType"));

  const loadStats = (dateRange: dayjs.Dayjs[]) => {
    triggerAutomationStats({
      fromDate: dateRange?.[0].format("MM/DD/YYYY") || null,
      toDate: dateRange?.[1].format("MM/DD/YYYY") || null
    });
  }

  const initOptions = (chartColors: string[], chartLabels: string[]) => {
    setOptions({
      colors: chartColors,
      labels: chartLabels,
      chart: {
        //width: '100%'
      },
      dataLabels: {
        enabled: false
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            show: false
          }
        }
      }],
      plotOptions: {
        pie: {
          offsetX: -50,
          customScale: 1,
          donut: {
            size: '75%',
            labels: {
              show: true,
              total: {
                show: true,
                showAlways: true,
                label: 'Automated',
                formatter: function (w: any) {
                  return w.globals.seriesTotals.reduce((total: number, current: number, index: number) => {
                    if (index === 2)
                      return total;
                    return total + current;
                  }, 0) + '%'
                }
              }
            }
          }
        }
      },
      legend: {
        position: 'right',
        horizontalAlign: 'center',
        fontSize: '13px',
        formatter: function (seriesName: any, opts: any) {
          return [seriesName, " : ", opts.w.globals.series[opts.seriesIndex] + "%"]
        },
        markers: {
          height: 3,
          width: 8,
          radius: 0,
          offsetY: -3,
          offsetX: -7
        }
      },

    });
  }

  useEffect(() => {
    if (props.dateRange) {
      loadStats(props.dateRange);
    }
  }, [props.dateRange])

  useEffect(() => {
    if (automationStatsResult.requestId && !automationStatsResult.isLoading &&
      automationStatsResult.data && automationStatsResult.data.success) {

      let chartLabels: string[] = [],
        chartSeries: number[] = [],
        chartColors: string[] = [],
        totalQuestions = 0,
        totalAnswers = 0;

      _.forEach(automationStatsResult.data.data, x => {
        chartLabels.push(`${answerSourceTypeDict[x.sourceTypeId].name}${x.isModified ? ' Edits' : ''}`);
        chartSeries.push(x.totalAnswer);
        chartColors.push(AnswerSourceTypeColors[`${x.sourceTypeId}${x.isModified ? '-1' : ''}`]);

        totalAnswers += x.totalAnswer;

        // Pick one only bcz it is repeated
        totalQuestions = x.totalQuestions
      });

      if (totalAnswers)
        chartSeries = chartSeries.map(x => Math.round((x / totalAnswers) * 100));

      setTotalQuestions(totalQuestions);
      setSeries(chartSeries);
      initOptions(chartColors, chartLabels);
    }
  }, [automationStatsResult]);

  return (
    <Card className="h-100">
      <Spin spinning={automationStatsResult.isLoading}>

        <div className="d-flex w-100">
          <div className="flex-grow-1">
            <div className="d-flex flex-column">
              <div className='d-flex align-items-center'>
                <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>{totalQuestions}</span>
              </div>
              <span className='text-gray-400 pt-1 fw-semibold fs-6'>Questions Answered</span>
            </div>
          </div>
        </div>

        {
          options !== null &&
          <ReactApexChart options={options}
            series={series}
            type="donut" width={'100%'} height={'155'} />
        }
      </Spin>
    </Card>
  )
}

export { AutomationEfficiencyStats };

