/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../../app/modules/auth'
import { NinjaGenerator } from '../../../../app/modules/widgets/components/NinjaGenerator'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { useLayout } from '../../core'
import { Header } from './Header'
import { Navbar } from './Navbar'

export function HeaderWrapper() {
    const { config, classes } = useLayout()
    const { company_config } = useAuth();

    if (!config.app?.header?.display) {
        return null
    }

    return (
        <div id='kt_app_header' className='app-header'>
            <div
                id='kt_app_header_container'
                className={clsx(
                    'app-container flex-grow-1',
                    classes.headerContainer.join(' '),
                    config.app?.header?.default?.containerClass
                )}
            >
                {config.app.sidebar?.display && (
                    <>
                        <div
                            className='d-flex align-items-center d-lg-none ms-n2 me-2'
                            title='Show sidebar menu'
                        >
                            <div
                                className='btn btn-icon btn-active-color-primary w-35px h-35px'
                                id='kt_app_sidebar_mobile_toggle'
                            >
                                <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className=' svg-icon-1' />
                            </div>
                            <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
                                <Link to='/dashboard' className='d-lg-none'>
                                    <img alt='Logo' src={toAbsoluteUrl('/media/logos/rfp-ninja-icon-transparent.png')} className='h-30px' />
                                </Link>
                            </div>
                        </div>
                    </>
                )}

                <div
                    id='kt_app_header_wrapper'
                    className='d-flex align-items-stretch justify-content-between flex-grow-1'
                >
                    <div className='flex-grow-1 align-self-center'>
                        {
                            company_config?.isProjectEnabled &&
                            <div className='d-flex justify-content-center'>
                                <div className='w-75'>
                                    <NinjaGenerator />
                                </div>
                            </div>
                        }

                        {config.app.header.default?.content === 'menu' &&
                            config.app.header.default.menu?.display && (
                                <div
                                    className='app-header-menu app-header-mobile-drawer'
                                    data-kt-drawer='true'
                                    data-kt-drawer-name='app-header-menu'
                                    data-kt-drawer-activate='{default: true, lg: false}'
                                    data-kt-drawer-overlay='true'
                                    data-kt-drawer-width='225px'
                                    data-kt-drawer-direction='end'
                                    data-kt-drawer-toggle='#kt_app_header_menu_toggle'
                                    data-kt-swapper='true'
                                    data-kt-swapper-mode="{default: 'append', lg: 'prepend'}"
                                    data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}"
                                >
                                    <Header />
                                </div>
                            )}
                    </div>
                    <Navbar />
                </div>
            </div>
        </div>
    )
}
