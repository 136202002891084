import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import DOMPurify from 'dompurify';
import { useEffect, useRef, useState } from 'react';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';

const HTMLContent: React.FC<{
  content?: string,
  lines?: number,
  className?: string,
  hideExpanded?: boolean,
  defaultExpanded? : boolean
}> = (props) => {
  const sanitizedHTML = DOMPurify.sanitize(props.content || '');
  const [expanded, setExpanded] = useState(props.hideExpanded || false);
  const [isClamped, setIsClamped] = useState(true);
  const htmlEllipsisRef = useRef<any>(null);

  useEffect(() => {
    if (isClamped && htmlEllipsisRef.current)
      setIsClamped(htmlEllipsisRef.current.isClamped());
  }, [])

  useEffect(() => {
    setExpanded(props.defaultExpanded || false)
  }, [props.defaultExpanded])

  return (
    <div className={"html-box " + (props.className || '')}>
      {expanded ?
        <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} /> :
        <HTMLEllipsis
          ref={htmlEllipsisRef}
          unsafeHTML={sanitizedHTML}
          maxLine={props.lines || 7}
          ellipsisHTML=''
        />
      }
      {
        (!isClamped || props.hideExpanded === true) ? null :
          <Button
            className='p-0 fw-bold mt-2'
            size='small'
            type='link'
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ?
              <>Read Less <UpOutlined /></> :
              <>Read More <DownOutlined /></>
            }
          </Button>
      }
    </div>
  )
}

export default HTMLContent