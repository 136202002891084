import { DeleteOutlined, EditOutlined, FilterOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Col, Divider, Input, List, Modal, Popconfirm, Popover, Row, Select, Space, Tag, Tooltip } from "antd";
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from "react";
import { useGetDatasetSelectListQuery } from '../../../redux/rtkquery/DatasetApi';
import { useDeleteQuestionMutation, useListMutation } from "../../../redux/rtkquery/LibraryApi";
import { filterOptions, getDatasetGroups, getFreshnessScoreColor, getFreshnessScoreValue } from '../../common/CommonFunctions';
import AttachmentTag from '../../common/components/AttachmentTag';
import HTMLContent from '../../common/components/HtmlContent';
import { LibraryBasicFilterModel, LibraryFilterModel, LibraryQuestionModel } from "../../models";
import { AddEditQuestion } from "./AddEditQuestion";
import { ImportQuestions } from './ImportQuestions';
import dayjs from 'dayjs';
import { FreshnessScoreType } from '../../common/Enums';

export const Library: React.FC = () => {
    const [triggerGetList, getListResult] = useListMutation();
    const [triggerDeleteQuestion, deleteQuestionResult] = useDeleteQuestionMutation();
    const allDatasetListState = useGetDatasetSelectListQuery();
    const [filterOpen, setFilterOpen] = useState(false);
    const [questionStartNo, setQuestionStartNo] = useState(1);
    const [selectedQuestion, setSelectedQuestion] = useState<LibraryQuestionModel | null>(null);
    const [questions, setQuestions] = useState<LibraryQuestionModel[]>([]);
    const [importModalOpen, setImportModalOpen] = useState<boolean>(false);
    const [hasImportFilter, setHasImportFilter] = useState<boolean>(false);
    const [filters, setFilters] = useState<LibraryFilterModel>({
        datasetIds: null,
        keyword: null,
        import_id: null,
        freshnessScore: null,
        offset: 0,
        currentPage: 1,
        pageSize: 10,
        totalRecords: 0
    });

    const getFiltersText = () => {
        let count = 0;

        filters.keyword && (count++);
        filters.import_id && (count++);
        filters.freshnessScore && (count++);
        _.size(filters.datasetIds) && (count++);

        return `Filters (${count})`;
    }

    useEffect(() => {
        loadQuestions(filters);
    }, []);

    const loadQuestions = (filters: LibraryFilterModel) => {
        triggerGetList(filters).then(response => {
            if ('data' in response) {
                let listingModel = response.data.data;

                setQuestions(listingModel?.libraryQuestionList || []);

                if (filters.totalRecords === 0) {
                    filters.totalRecords = listingModel?.totalRecords || 0;
                    setFilters({ ...filters });
                }
                setQuestionStartNo(filters.offset + 1);
            }
        })
    }

    const onDeleteQuestion = async (questionId: number) => {
        let response = await triggerDeleteQuestion({ libraryQuestionId: questionId });

        if ('data' in response && response.data.success) {
            filters.currentPage = questions.length > 1 ? filters.currentPage :
                (filters.currentPage > 1 ? --filters.currentPage : 1);
            filters.offset = (filters.currentPage - 1) * filters.pageSize;
            filters.totalRecords = 0;

            setFilters({ ...filters });
            loadQuestions(filters);
        }
    }

    const onQuestionModalClose = (question?: LibraryQuestionModel) => {

        if (question) {
            filters.totalRecords = 0;
            setFilters({ ...filters });
            loadQuestions(filters);
        }

        setSelectedQuestion(null);
    }

    const addQuestion = () => {
        setSelectedQuestion({
            libraryQuestionId: 0,
            question: '',
            answer: '',
            datasetId: null,
            alternativeQuestion: [],
            libraryDocument: []
        });
    }

    const onPagingChange = (page: number, size: number) => {
        let _filters = { ...filters };
        _filters.currentPage = filters.pageSize !== size ? 1 : page;
        _filters.pageSize = size;
        _filters.offset = (_filters.currentPage - 1) * size;
        loadQuestions(_filters);
        setFilters(_filters);
    }

    const handleFilterOpen = (newOpen: boolean) => {
        setFilterOpen(newOpen);
    }

    const onApplyFilters = (_filters: LibraryBasicFilterModel, closePopup: boolean) => {
        filters.keyword = _filters.keyword || null;
        filters.datasetIds = _filters.datasetIds || null;
        filters.import_id = _filters.import_id || null;
        filters.freshnessScore = _filters.freshnessScore || null;
        filters.currentPage = 1;
        filters.offset = 0;
        filters.totalRecords = 0;

        setFilters({ ...filters });
        loadQuestions(filters);
        setFilterOpen(!closePopup);
        setHasImportFilter(_filters.import_id ? true : false);
    }

    const onImportModalClose = (import_id?: number) => {
        if (import_id)
            onApplyFilters({ datasetIds: null, keyword: null, freshnessScore: null, import_id: import_id }, true);
        setImportModalOpen(false);
    }

    return (<>

        {
            hasImportFilter && questions.length > 0 &&
            <Alert className="mb-4 px-4 py-4"
                showIcon={true}
                message={<>
                    The following questions were imported from the file.
                    <Button type="link"
                        className='ps-1'
                        onClick={() => onApplyFilters({}, true)}
                    >
                        See all library questions
                    </Button>
                </>}
            />
        }

        <Card title={<h2>Response Library</h2>}
            extra={<Space>
                <Popover
                    content={
                        <LibraryFilter filters={filters} onApply={onApplyFilters} />
                    }
                    trigger="click"
                    open={filterOpen}
                    onOpenChange={handleFilterOpen}
                    placement='bottomRight'
                >
                    <Button type="default" icon={<FilterOutlined />}>
                        {
                            getFiltersText()
                        }
                    </Button>
                </Popover>
                <Button type="primary" onClick={addQuestion}>Add Question</Button>
                <Button type="primary" onClick={() => setImportModalOpen(true)}>Upload File</Button>
            </Space>}
        >
            <List
                loading={getListResult.isLoading || deleteQuestionResult.isLoading}
                pagination={{
                    align: "center",
                    showSizeChanger: true,
                    current: filters.currentPage,
                    pageSize: filters.pageSize,
                    onChange: onPagingChange,
                    total: filters.totalRecords,
                    hideOnSinglePage: filters.totalRecords > 10 ? false : true
                }}
                dataSource={questions}
                renderItem={(item, index) => (
                    <Row>
                        <Col xs={24} sm={4} md={2} lg={1}>
                            <div className='mb-2'>
                                <span className='d-sm-none fw-bold'>Q. No.</span> {(questionStartNo + index)}
                            </div>
                        </Col>
                        <Col xs={24} sm={20} md={22} lg={23}>
                            <Card className='mb-4 flex-grow-1'
                                styles={{header: { padding: '0' }}}
                                size='small'
                                bordered={true}
                                title={<div className='py-3 px-4 bg-light d-flex'>
                                    <div className='flex-grow-1 d-linebreak'>
                                        {item.question}
                                        {
                                            _.size(item.alternativeQuestion) > 0 ?
                                                <ul className='fw-normal fs-7 mt-2 border-start border-4 text-gray-700'>
                                                    {_.map(item.alternativeQuestion, x =>
                                                        <li key={x.libraryQuestionId}>{x.question}</li>
                                                    )}
                                                </ul>
                                                : null
                                        }
                                    </div>
                                    <Space className='align-self-start'>
                                        <Button size='small' type='text' icon={<EditOutlined />} onClick={() => setSelectedQuestion(item)} />
                                        <Popconfirm
                                            title="Delete"
                                            description="Are you sure to delete this question from library?"
                                            onConfirm={() => onDeleteQuestion(item.libraryQuestionId)}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button size='small' type='text' icon={<DeleteOutlined />} />
                                        </Popconfirm>

                                    </Space>
                                </div>
                                }
                            >
                                <div className="d-flex align-items-center flex-wrap d-grid gap-7">
                                    {
                                        !_.isNil(item.datasetId) &&
                                        <div className="d-flex align-items-center">
                                            <div className="m-0 lh-sm">
                                                <span className="text-gray-500 d-block fs-8">Dataset</span>
                                                <span className="fw-bold text-gray-800 text-hover-primary fs-7">
                                                    {allDatasetListState.data?.find(x => x.datasetId === item.datasetId)?.datasetName ?? "Unknown"}
                                                </span>
                                            </div>
                                        </div>
                                    }

                                    <Popover content={
                                        <ul className='fs-8 m-0' style={{ paddingLeft: '15px' }}>
                                            <li><span className='fw-bold text-success'>Fresh:</span> Updated in the last 6 months.</li>
                                            <li><span className='fw-bold text-warning'>Aged:</span> Not updated in 6 months, but used in 6 months.</li>
                                            <li><span className='fw-bold text-danger'>Stale:</span> Not updated in 6 months and not used in 6 months.</li>
                                        </ul>
                                    }>
                                        <div className="d-flex align-items-center">
                                            <div className="symbol symbol-30px symbol-circle me-3">
                                                <i className={`fa-brands fa-envira fs-2 text-${getFreshnessScoreColor(item.freshnessScore ?? -1)}`} />
                                            </div>
                                            <div className="m-0 lh-sm">
                                                <span className="text-gray-500 d-block fs-8">Freshness Score</span>
                                                <span className="fw-bold text-gray-800 text-hover-primary fs-7">
                                                    {getFreshnessScoreValue(item.freshnessScore ?? -1)}
                                                </span>
                                            </div>
                                        </div>
                                    </Popover>
                                    {
                                        (item.totalUsed ?? 0) > 0 &&
                                        <>
                                            <div className="d-flex align-items-center">
                                                <div className="m-0 lh-sm">
                                                    <span className="text-gray-500 d-block fs-8">Total Used</span>
                                                    <span className="fw-bold text-gray-800 text-hover-primary fs-7">{item.totalUsed ?? 0}</span>
                                                </div>
                                            </div>

                                            <div className="d-flex align-items-center">
                                                <div className="m-0 lh-sm">
                                                    <span className="text-gray-500 d-block fs-8">Used in last 6 months</span>
                                                    <span className="fw-bold text-gray-800 text-hover-primary fs-7">{item.last6MonthUsed ?? 0}</span>
                                                </div>
                                            </div>

                                            <div className="d-flex align-items-center">
                                                <div className="m-0 lh-sm">
                                                    <span className="text-gray-500 d-block fs-8">Last Used On</span>
                                                    <span className="fw-bold text-gray-800 text-hover-primary fs-7">
                                                        {item.lastUsedDate ? dayjs(item.lastUsedDate).format("MM/DD/YYYY") : "--"}
                                                    </span>
                                                </div>
                                            </div>
                                        </>
                                    }

                                    <div className="d-flex align-items-center">
                                        <div className="m-0 lh-sm">
                                            <span className="text-gray-500 d-block fs-8">Created On</span>
                                            <span className="fw-bold text-gray-800 text-hover-primary fs-7">
                                                {item.createdDate ? dayjs(item.createdDate).format("MM/DD/YYYY") : "--"}
                                            </span>
                                        </div>
                                    </div>

                                    {
                                        item.modifiedDate && item.createdDate !== item.modifiedDate &&
                                        <div className="d-flex align-items-center">
                                            <div className="m-0 lh-sm">
                                                <span className="text-gray-500 d-block fs-8">Modified On</span>
                                                <span className="fw-bold text-gray-800 text-hover-primary fs-7">
                                                    {item.modifiedDate ? dayjs(item.modifiedDate).format("MM/DD/YYYY") : "--"}
                                                </span>
                                            </div>
                                        </div>
                                    }

                                </div>

                                <HTMLContent className='mt-3' content={item.answer} lines={5} />

                                {
                                    _.size(item.libraryDocument) > 0 &&
                                    <>
                                        <Divider className='mb-3 mt-2' />
                                        {
                                            _.map(item.libraryDocument, x => (
                                                <AttachmentTag key={x.attachmentId}
                                                    style={{ maxWidth: '250px' }}
                                                    fileName={x.fileDisplayName}
                                                    fileGuid={x.fileName}
                                                />
                                            ))
                                        }
                                    </>
                                }
                            </Card>
                        </Col>
                    </Row>
                )}
            />
        </Card>
        {
            selectedQuestion &&
            <AddEditQuestion onClose={onQuestionModalClose} question={selectedQuestion} />
        }
        {
            <Modal open={importModalOpen}
                footer={null}
                destroyOnClose={true}
                width={800}
                onCancel={() => onImportModalClose()}>
                <ImportQuestions onClose={onImportModalClose} />
            </Modal>
        }
    </>)
}

const LibraryFilter: React.FC<{
    filters: LibraryBasicFilterModel,
    onApply: (filters: LibraryBasicFilterModel, closePopup: boolean) => void
}> = (props) => {
    const allDatasetListState = useGetDatasetSelectListQuery();
    const [filters, setFilters] = useState(_.cloneDeep(props.filters));
    const scoreOptions = [
        {
            label: 'Fresh',
            value: FreshnessScoreType.Fresh,
            iconClass: 'fa-brands fa-envira text-success'
        },
        {
            label: 'Aged',
            value: FreshnessScoreType.Aged,
            iconClass: 'fa-brands fa-envira text-warning'
        },
        {
            label: 'Stale',
            value: FreshnessScoreType.Stale,
            iconClass: 'fa-brands fa-envira text-danger'
        }
    ]

    const onKeywordChange = (keyword: string) => {
        setFilters(prev => ({ ...prev, keyword }));
    }

    const onDatasetsChange = (datasetIds: number[]) => {
        setFilters(prev => ({ ...prev, datasetIds }));
    }

    const onScoreChange = (score: number | null) => {
        setFilters(prev => ({ ...prev, freshnessScore: score }));
    }

    const onImportIdRemove = () => {
        setFilters(prev => ({ ...prev, import_id: null }));
    }

    const onApply = () => {
        let _filters = { ...filters };
        _filters.keyword = _.trim(filters.keyword || '');
        props.onApply(filters, true);
    }

    const onClear = () => {
        filters.keyword = null;
        filters.datasetIds = null;
        filters.import_id = null;
        filters.freshnessScore = null;
        setFilters({ ...filters });
        props.onApply(filters, false);
    }

    const datasetGroups = useMemo(() => {
        return getDatasetGroups(allDatasetListState.data || [])
    }, [allDatasetListState]);

    const filterOption = useCallback(filterOptions, [allDatasetListState]);

    return (<>
        <div className='mb-4'>
            <label>Keyword</label>
            <Input
                value={filters.keyword || ''}
                onChange={(e) => onKeywordChange(e.target.value)}
            />
        </div>
        <div className='mb-4'>
            <label>Dataset</label>
            <Select showSearch
                className='w-100'
                mode='multiple'
                maxTagCount={1}
                value={filters.datasetIds || []}
                options={datasetGroups}
                placeholder="Select Dataset"
                loading={allDatasetListState.isFetching}
                onChange={onDatasetsChange}
                filterOption={filterOption}
                listItemHeight={10}
            />
        </div>

        <div className='mb-4'>
            <label>Freshness Score</label>
            <Select showSearch
                className='w-100'
                value={filters.freshnessScore ?? null}
                options={scoreOptions}
                placeholder="Select Score"
                loading={allDatasetListState.isFetching}
                onChange={onScoreChange}
            />
        </div>

        {
            filters.import_id &&
            <Tag closeIcon onClose={onImportIdRemove}>Import ID : {filters.import_id}</Tag>
        }

        <Divider />
        <div className='text-end'>
            <Space>
                <Button onClick={onClear}>Clear</Button>
                <Button type='primary' onClick={onApply}>Apply</Button>
            </Space>
        </div>
    </>)
}