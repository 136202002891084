import { ReloadOutlined } from '@ant-design/icons';
import { Alert, Button, Card } from 'antd';
import React from "react";
import { WithChildren } from "../../../_metronic/helpers";
import { CompanySetupSteps, DataSetType } from "../../common/Enums";
import { RoutedTabs } from "../../common/components/RoutedTabs";
import { useAuth } from '../auth';
import { CompanyInfo } from "./CompanyInfo";
import { CompanyProfile } from "./CompanyProfile";
import { DataSets } from "./DataSets";
import { RebuildIndex } from "./RebuildIndex";

const Company: React.FC = () => {
    const { company_config } = useAuth();

    return (
        <>
            {
                company_config?.isIndexing === true &&
                <Alert className="mb-4 px-4 py-4"
                    showIcon={true} message="Building Index"
                    description="The Ninja Generator model is being built, and changes will be allowed after its completion."
                    action={
                        <Button size="small" type="default"
                            icon={<ReloadOutlined />}
                            onClick={() => window.location.reload()}
                        >
                            Refresh Status
                        </Button>
                    }
                />
            }

            <RoutedTabs
                defaultActiveKey="about"
                tabPosition="left"
                items={
                    [{
                        label: 'About',
                        key: 'about',
                        children: <TabBody index={0}>
                            <CompanyProfile source="PostSetup"
                                isIndexing={company_config?.isIndexing ?? false}
                            />
                        </TabBody>,
                    },
                    {
                        label: 'Company Info',
                        key: 'company-info',
                        children: <TabBody index={1}>
                            <CompanyInfo source="PostSetup"
                                isIndexing={company_config?.isIndexing ?? false}
                                isProjectEnabled={company_config?.isProjectEnabled ?? false}
                            />
                        </TabBody>,
                    },
                    {
                        label: 'Products',
                        key: 'products',
                        children: <TabBody index={2}>
                            <DataSets source="PostSetup"
                                dataSetType={DataSetType.Products}
                                isIndexing={company_config?.isIndexing || false}
                                isProjectEnabled={company_config?.isProjectEnabled ?? false}
                            />
                        </TabBody>,
                    },
                    {
                        label: 'Additional Datasets',
                        key: 'additional-datasets',
                        children: <TabBody index={3}>
                            <DataSets source="PostSetup"
                                dataSetType={DataSetType.Custom}
                                isIndexing={company_config?.isIndexing || false}
                                isProjectEnabled={company_config?.isProjectEnabled ?? false}
                            />
                        </TabBody>,
                    },
                    {
                        label: 'Rebuild Index',
                        key: 'rebuild-index',
                        children: <TabBody index={5}>
                            <RebuildIndex isIndexing={company_config?.isIndexing || false} />
                        </TabBody>,
                    }].filter(x => (x.key !== 'additional-datasets' && x.key !== 'rebuild-index') || company_config?.isProjectEnabled)
                }
            />

        </>
    )
}

interface TabBodyProps extends WithChildren {
    index: number
}

const TabBody: React.FC<TabBodyProps> = ({ children, index }) => {
    return (
        <Card
            title={<>
                <h3 className="fw-bolder text-dark">{CompanySetupSteps[index].title}</h3>
                <div className="text-gray-700 fs-6 fw-normal text-wrap">
                    {CompanySetupSteps[index].description}
                </div>
            </>}
            styles={{header: { paddingTop: '15px', paddingBottom: '15px' }}}>
            {children}
        </Card>
    )
}

export { Company };

