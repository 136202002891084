import { Card, Spin } from "antd";
import dayjs from 'dayjs';
import _, { cloneDeep } from "lodash";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Col, Row } from "react-bootstrap";
import { useProcessedProjectsStatsMutation } from "../../../redux/rtkquery/DashboardAPI";
import { localeCurrency, localeNumber } from "../../common/CommonFunctions";
import { ProjectStatus } from "../../common/Enums";
import { ProjectCompletedStatsModel } from "../../models";

type SeriesType = {
  group: string,
  name: string,
  data: number[],
  color?: string
}

type Props = {
  dateRange: dayjs.Dayjs[]
}

const ProjectsProcessedStats: React.FC<Props> = (props) => {
  const [options, setOptions] = useState<any>(null);
  const [priceChartOptions, setPriceChartOptions] = useState<any>(null);
  const [series, setSeries] = useState<any[]>([]);
  const [priceChartSeries, setPriceChartSeries] = useState<any[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [triggerStats, projectStatsResult] = useProcessedProjectsStatsMutation();

  const setMonthCategories = (dateRange: dayjs.Dayjs[]) => {
    let _categories = [], dateRanges = _.cloneDeep(dateRange);

    while (dateRanges[0].endOf('M') <= dateRanges[1].endOf('M')) {
      _categories.push(dateRanges[0].format('MMM YY'));
      dateRanges[0] = dateRanges[0].add(1, "M");
    };

    setCategories(_categories);
    return _categories;
  }

  const loadStats = (dateRange: dayjs.Dayjs[]) => {
    triggerStats({
      fromDate: dateRange?.[0].format("MM/DD/YYYY") || null,
      toDate: dateRange?.[1].format("MM/DD/YYYY") || null
    });
  }

  const initOptions = (chartSeries: SeriesType[], isPriceChart: boolean) => {

    let _options = {
      colors: chartSeries.map(x => x.color),
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        toolbar: {
          show: false
        },
        events: {
          mounted: (chartContext: any, config: any) => {
            setTimeout(() => {
              addAnnotations(chartContext, config, isPriceChart);
            });
          },
          updated: (chartContext: any, config: any) => {
            setTimeout(() => {
              addAnnotations(chartContext, config, isPriceChart);
            });
          }
        }
      },
      plotOptions: {
        bar: {
          //columnWidth: categories.length === 1 ? '25%' : (categories.length <= 3 ? '45%' : '90%'),
          horizontal: false,
          dataLabels: {
            maxItems: 2
          }
        }
      },
      stroke: {
        colors: ["transparent"],
        width: 2
      },
      dataLabels: {
        enabled: true,
        formatter: (val: number) => {
          return isPriceChart ? localeCurrency(val) : localeNumber(val);
        },
        style: {
          fontSize: '12px',
          fontWeight: "normal",
          colors: ["#000"]
        }
      },
      xaxis: {
        categories: categories,
        position: 'bottom',
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        tooltip: {
          enabled: true,
        },
        labels: {
          hideOverlappingLabels: false,
        }
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        labels: {
          hideOverlappingLabels: true,
          show: true,
          formatter: (val: number) => {
            return isPriceChart ? localeCurrency(val) : localeNumber(parseInt(val.toFixed(0)));
          }
        }
      },
      tooltip: {
        shared: true,
        intersect: false
      },
      fill: {
        opacity: 1
      },
      legend: {
        show: true,
        clusterGroupedSeries: false,
        clusterGroupedSeriesOrientation: "horizontal",
        horizontalAlign: 'center',
        showForSingleSeries: true,
        position: 'bottom'
      }
    }

    if (!isPriceChart) {
      setOptions(_options);
      setSeries(chartSeries)
    }
    else {
      setPriceChartOptions(_options)
      setPriceChartSeries(chartSeries)
    }
  }

  const addAnnotations = (chartContext: any, config: any, isPriceChart: boolean) => {
    const seriesTotals = config.globals.stackedSeriesTotalsByGroups;
    chartContext.clearAnnotations();

    try {

      var iCnt = categories.length;
      const gridWidth = config.globals.gridWidth;
      var iWidth = gridWidth / iCnt;

      categories.forEach((category, index) => {

        chartContext.addPointAnnotation(
          {
            y: seriesTotals[0][index],
            x: (index) * iWidth + (iWidth / 3),
            marker: {
              size: 0
            },
            label: {
              text: isPriceChart ? localeCurrency(seriesTotals[0][index]) : localeNumber(parseInt(seriesTotals[0][index].toFixed(0))),
              style: {
                fontSize: "13px",
                fontWeight: "bold",
                borderWidth: "0",
                borderColor: '#fff',
                color: '#000'
              },
            }
          },
          false
        );
        chartContext.addPointAnnotation(
          {
            y: seriesTotals[1][index],
            x: (index) * iWidth + (iWidth * 2 / 3),
            marker: {
              size: 0
            },
            label: {
              text: isPriceChart ? localeCurrency(seriesTotals[1][index]) : localeNumber(parseInt(seriesTotals[1][index].toFixed(0))),
              style: {
                fontSize: "13px",
                fontWeight: "bold",
                borderWidth: "0",
                borderColor: '#fff',
                color: '#000'
              },
            }
          },
          false
        );
      });
    } catch (error: any) {
      console.log(`Add point annotation error: ${error.message}`);
    }
  };

  const setChartData = (statsData: ProjectCompletedStatsModel[], isPriceChart: boolean) => {
    const openProjectsDict: Record<string, ProjectCompletedStatsModel> = {},
      wonProjectsDict: Record<string, ProjectCompletedStatsModel> = {},
      lostProjectsDict: Record<string, ProjectCompletedStatsModel> = {},
      notsetProjectsDict: Record<string, ProjectCompletedStatsModel> = {};

    if (isPriceChart)
      _.remove(statsData, x => (x.projectValue ?? 0) <= 0);

    let openProjectSeries: SeriesType = { name: 'Active Projects', group: "Active", data: [], color: "#81d4fa" },
      wonProjectSeries: SeriesType = { name: 'Won Projects', group: "Complete", data: [], color: "#4db6ac" },
      lostProjectSeries: SeriesType = { name: 'Lost Projects', group: "Complete", data: [], color: "#e57373" },
      notSetProjectSeries: SeriesType = { name: 'Complete - No Win Status', group: "Complete", data: [], color: "#cfd8dc" };

    _.forEach(statsData, x => {
      // Set Completion Month display label
      let completionOn = dayjs(`${x.completionYear}-${x.completionMonth}-01`, 'YYYY-M-D').format("MMM YY");

      switch (x.statusId) {
        case ProjectStatus.Open:
          openProjectsDict[completionOn] = x;
          break;
        case ProjectStatus.Won:
          wonProjectsDict[completionOn] = x;
          break;
        case ProjectStatus.Lost:
          lostProjectsDict[completionOn] = x;
          break;
        case ProjectStatus.Submitted:
          notsetProjectsDict[completionOn] = x;
          break;
      }
    });

    categories.forEach(x => {
      openProjectSeries.data.push((isPriceChart ? openProjectsDict[x]?.projectValue : openProjectsDict[x]?.totalProject) ?? 0);
      wonProjectSeries.data.push((isPriceChart ? wonProjectsDict[x]?.projectValue : wonProjectsDict[x]?.totalProject) ?? 0);
      lostProjectSeries.data.push((isPriceChart ? lostProjectsDict[x]?.projectValue : lostProjectsDict[x]?.totalProject) ?? 0);
      notSetProjectSeries.data.push((isPriceChart ? notsetProjectsDict[x]?.projectValue : notsetProjectsDict[x]?.totalProject) ?? 0);
    });

    if (notSetProjectSeries.data.some(x => x > 0))
      initOptions([wonProjectSeries, lostProjectSeries, notSetProjectSeries, openProjectSeries], isPriceChart);
    else
      initOptions([wonProjectSeries, lostProjectSeries, openProjectSeries], isPriceChart);
  }

  useEffect(() => {
    if (props.dateRange) {
      setMonthCategories(props.dateRange);
    }
  }, [props.dateRange])

  useEffect(() => {
    if (categories.length)
      loadStats(props.dateRange);
  }, [categories])

  useEffect(() => {
    if (projectStatsResult.requestId && !projectStatsResult.isLoading &&
      projectStatsResult.data && projectStatsResult.data.success) {
      setChartData(cloneDeep(projectStatsResult.data.data || []), false)
      setChartData(cloneDeep(projectStatsResult.data.data || []), true)
    }
  }, [projectStatsResult]);

  return (
    <Row>
      <Col sm={12} md={6} className="mb-5">
        <Card className="h-100">
          <Spin spinning={projectStatsResult.isLoading}>

            <div className='d-flex align-items-center justify-content-between mb-5'>
              <span className='fs-3 fw-bold text-dark me-2 lh-1 flex-grow-1'>
                Projects Processed
              </span>
            </div>

            {
              options !== null &&
              <ReactApexChart options={options}
                series={series}
                type="bar" height={'300'} width={'100%'} />
            }
          </Spin>
        </Card>
      </Col>
      <Col sm={12} md={6} className="mb-5">
        <Card className="h-100">
          <Spin spinning={projectStatsResult.isLoading}>

            <div className='d-flex align-items-center justify-content-between mb-5'>
              <span className='fs-3 fw-bold text-dark me-2 lh-1 flex-grow-1'>
                Projects Processed ($)
              </span>
            </div>

            {
              priceChartOptions !== null &&
              <ReactApexChart options={priceChartOptions}
                series={priceChartSeries}
                type="bar" height={'300'} width={'100%'} />
            }
          </Spin>
        </Card>
      </Col>
    </Row>
  )
}

export { ProjectsProcessedStats };

